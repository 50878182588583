import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styles from "./Graph/department.module.css";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Progress, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllDepartment } from '../../redux/rtk/features/department/departmentSlice';

const DepartmentsTable = () => {
    const dispatch = useDispatch();
    const departmentData = useSelector((state) => state.department.list);
    console.log("departmentData dashtboard:", departmentData);
    useEffect(() => {
        dispatch(loadAllDepartment());
    }, [dispatch]);

    const getColorStyle = (departmentName) => {
        switch (departmentName) {
            case "IT":
                return { borderColor: '#0091FF', backgroundColor: 'rgba(0, 145, 255, 0.1)', color:'#0091FF'};
            case "Finance":
                return { borderColor: '#AA83F9', backgroundColor: 'rgba(170, 131, 249, 0.1)',color:'#AA83F9' };
            case "Sales":
                return { borderColor: '#FB5D37', backgroundColor: 'rgba(251, 93, 55, 0.1)',color:'#FB5D37' };
            case "Customer Support":
                return { borderColor: '#00C49F', backgroundColor: 'rgba(0, 196, 159, 0.1)' ,color:'#00C49F'};
            case "Operations":
                return { borderColor: '#EB131C', backgroundColor: 'rgba(235, 19, 28, 0.1)',color:'#EB131C' };
            case "Marketing":
                return { borderColor: '#AA83F9', backgroundColor: 'rgba(170, 131, 249, 0.1)',color:'#AA83F9' };
            case "HR":
                return { borderColor: '#FFCC01', backgroundColor: 'rgba(255, 204, 1, 0.1)',color:'#FFCC01' };
            default:
                return { borderColor: '#FFCC01', backgroundColor: 'rgba(255, 204, 1, 0.1)',color:'#FFCC01' };
        }
    };

    return (
        <div className={styles.tableContainer}>

            <Table className={styles.customTable} hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Headcount</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {departmentData?.map((row) => (
                        <tr key={row.id} className={styles.tableRow}>
                            <td>{row.id}</td>
                            <td>{row.name}</td>
                            <td>
                                <Progress
                                    percent={row.user.length}
                                    strokeColor={getColorStyle(row.name).borderColor}
                                    showInfo={false}

                                />
                            </td>
                            <td>
                                <Tag
                                    className='px-5'
                                    style={getColorStyle(row.name)}
                                >

                                    {row.user.length}
                                </Tag>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DepartmentsTable;
