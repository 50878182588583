import { Col, Layout, Row } from "antd";
import styles from "./Footer.module.css";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const year = new Date().getFullYear();

  return (
    <AntFooter className={styles.footer}>
      <Row>
        <Col xs={24} md={24} lg={24} className={styles.copyrightCol}>
          <p className={styles.copyrightText}>
            <span
              className="font-weight-bold"
              target="_blank"
              rel="noreferrer"
            >
            Copyright © 2023 WISE1HRM
            </span>
          </p>
        </Col>

        {/* <Col xs={24} md={24} lg={12}>
          <div className={styles.footerMenu}>
            <ul className={styles.footerList}>
              <li className="nav-item">
                <span
                  className="nav-link text-muted"
                >
                  Wise1ne
                </span>
              </li>
              <li className="nav-item">
                <span  className="nav-link text-muted">
                  About Us
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link text-muted"
                >
                  Blog
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link pe-0 text-muted"
                >
                  License
                </span>
              </li>
            </ul>
          </div>
        </Col> */}

      </Row>
    </AntFooter>
  );
}

export default Footer;
