import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  Roomslist: [],
  singleRoom: [],
  error: "",
  loading: false,
  loadRoomsid:[],
};

// ADD_attendance
export const addRooms = createAsyncThunk("Rooms", async (values) => {
  try {
    const response = await axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `rooms`,
      data: {
        ...values,
      },
    });
    if (response.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error("something went wrong!");
    }
    return {
      data: response.data,
      message: "success",
    };
  } catch (error) {
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error("something went wrong!");
    }
  }
});

export const getCurrentUserClockInStatus = createAsyncThunk(
  "clockIn/loadSingleClockIn",
  async (id) => {
    try {
      const data = await axios.get(`attendance/${id}/last`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// add Manual Attendance
export const addManualAttendance = createAsyncThunk(
  "attendance/addManualAttendance",
  async (values) => {
    try {
      console.log(values, "dataaa");
      const data = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `/attendance/create`,
        data: {
          ...values,
        },
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          if (err?.response?.status == 400) {
            toast.error(err?.response?.data?.message);
          }
        });
      return {
        data,
        message: "success",
      };
    } catch (error) {
      toast.error("Error in adding Attendance try again SLICE");
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);

// get all attendance list paginated
export const loadAllAttendancePaginated = createAsyncThunk(
  "attendance/loadAllAttendancePaginated",
  async ({ page, startdate, enddate, limit }) => {
    try {
      const { data } = await axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `attendance?page=${page}&count=${limit}&startdate=${startdate}&enddate=${enddate}`,
      });
      return data;
    } catch (error) {
      toast.error("Error in getting Attendance list try again");
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);

// get all attendance
export const loadAllRooms = createAsyncThunk(
  "Rooms/rooms/?query=all",
  async () => {
    try {
      const { data } = await axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `/rooms/?query=all`,
      });

      return data;
    } catch (error) {
      toast.error("Error in getting Rooms list try again");
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);

// get single attendance
export const loadSingleRoom = createAsyncThunk("Rooms/rooms/", async (id) => {
  try {
    const { data } = await axios({
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `/rooms/${id}`,
    });
    return {
      data,
      message: "success",
    };
  } catch (error) {
    console.log(error);
    return {
      message: "error",
    };
  }
});

// loadRoomsByLocationId
export const loadRoomsByLocationId = createAsyncThunk(
  "Rooms/loadRoomsByLocationId",
  async (id) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `rooms/location/${id}?status=true`,
      });
      return {
        data: response.data,
      };

    } catch (error) {
    console.log(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
// UPDATE Attendance

export const updateRoom = createAsyncThunk(
  "Rooms/rooms/update/",
  async ({ id, checked }) => {
    console.log(checked, "checked....");
    try {
      const response = await axios({
        method: "put",
        url: `/rooms/update/${id}`,
        data: {
          ...checked,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
export const deleteRoom = createAsyncThunk("Rooms/rooms/delete", async (id) => {
  try {
    const response = await axios({
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `/rooms/delete/${id}`,
    });
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return {
      data: response.data,
      message: "success",
    };
  } catch (error) {
    if (error.response.status === 400) {
      toast.error(error.response.data.message);
    } else {
      toast.error("something went wrong!");
    }
  }
});

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    clearRooms: (state) => {
      state.Roomslist = null;
    },
  },
  extraReducers: (builder) => {
    // 3) ====== builders for update attendance ======

    builder.addCase(updateRoom.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateRoom.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateRoom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(deleteRoom.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteRoom.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteRoom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(addRooms.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addRooms.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for getCurrentUserClockInStatus ======

    builder.addCase(getCurrentUserClockInStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getCurrentUserClockInStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.clockIn = action.payload.data;
    });

    builder.addCase(getCurrentUserClockInStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for loadAllAttendancePaginated ======

    builder.addCase(loadAllAttendancePaginated.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllAttendancePaginated.fulfilled, (state, action) => {
      state.loading = false;

      state.list = action.payload;
    });

    builder.addCase(loadAllAttendancePaginated.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for loadAllAttendance ======

    builder.addCase(loadAllRooms.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllRooms.fulfilled, (state, action) => {
      state.loading = false;
      state.Roomslist = action.payload;
    });

    builder.addCase(loadAllRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for addManualAttendance ======

    builder.addCase(addManualAttendance.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addManualAttendance.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addManualAttendance.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for loadSingleAttendance ======

    builder.addCase(loadSingleRoom.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSingleRoom.fulfilled, (state, action) => {
      state.loading = false;
      state.singleRoom = action.payload;
    });

    builder.addCase(loadSingleRoom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for loadRoomsByLocationId ======

    builder.addCase(loadRoomsByLocationId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadRoomsByLocationId.fulfilled, (state, action) => {
      state.loading = false;
      state.loadRoomsid = action.payload;
    });

    builder.addCase(loadRoomsByLocationId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default roomSlice.reducer;
export const { clearRooms } = roomSlice.actions;
