import { Button, Card, Col, Form, Input, Row, Table, Typography, Pagination, Modal } from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { addDepartment, getDepartments } from "./departmentApis";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDepartments } from "../../redux/rtk/features/department/departmentSlice";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";
import getPermissions from "../../utils/getPermissions";

function CustomTable({ list, loading }) {
  const [row, setRow] = useState([]);
  const dispatch = useDispatch();
  const [newList, setNewList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const[deptmodal,setdeptmodal] = useState(false)
  const[deptid,setdeptid] = useState()
  const pageSize = 10;
  const permissions = getPermissions();

  const hasPermission = (item) => {
    return permissions?.includes(item ? item : "");
  };

  useEffect(() => {
    if (list) {
      setNewList(list);
      makeRow();
    }
  }, [list]);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Created at",
      dataIndex: "createdAt",
      key: "addrcreatedAtess",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
          <>
            {hasPermission("delete-department") && (
                //
                <div className="flex items-center">
                  <ViewBtn path={`/admin/department/${id}/`} />
                  <button
                      onClick={() => DeleteDepartment(id)}
                      className="text-lg text-[#FF0000] font-bold px-2 mr-2"
                  >
                    <MdDelete className="text-[25px] text-[#FF0000]" />
                  </button>
                </div>
            )}
          </>
      ),
    },

  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));
  // Calculate the current data to display based on pagination
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = newList
    ? addKeys(newList).slice(startIndex, endIndex)
    : [];

  // Handle page change
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const { Title } = Typography;

  const onFinish = async (values) => {
    setLoader(true);
    const resp = await addDepartment(values);
    if (resp.message === "success") {
      setLoader(false);
      setAddModal(false);
      dispatch(GetAllDepartments())
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
  };

  const DeleteDepartment = (id) => {
    setdeptid(id)
    setdeptmodal(true)
  };

  const makeRow = () => {
    const filterdata = list;
    const data =
      Array.isArray(filterdata) && filterdata.length > 0
        ? filterdata.map((data, index) => ({
          id: data?.id,
          name: data?.name == null ? "-" : data?.name,
          status: data?.status,
        }))
        : filterdata;
    setRow(data);
  };

  return (
    <>
      <h3 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        Department
      </h3>

      <div className="text-center my-2 flex justify-between items-center">
        {list && (
          <div>
            <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
              <CSVLink
                data={row}
                className="btn btn-dark btn-sm mb-1 hover:!text-white text-[15px] font-[400]"
                filename="departmentsList"
              >
                CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}

        <div className="text-center mr-3 mb-1 flex justify-end">
          <Button type="button" className="text-[15px] font-[400] bg-[#E42976] text-white border-0" onClick={(e) => setAddModal(true)}>
            Add Department
          </Button>
        </div>
      </div>
      <Card className="ant-shadow rounded-[20px] mr-3" bordered={false}>
        {/* {list && (
				<div style={{ marginBottom: "30px" }}>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)} */}
        <Table
          loading={loading}
          scroll={{ x: true }}
          columns={columnsToShow}
          dataSource={currentData}
          pagination={false} // Disable the default pagination
        />

        {/* Add Pagination component */}
        <Pagination
          className="mt-3 flex justify-end"
          current={currentPage}
          pageSize={pageSize}
          total={newList ? addKeys(newList).length : 0}
          onChange={handleChangePage}
          showSizeChanger={false} // Hide the "Show X items per page" dropdown
        />
      </Card>

      <Modal
        visible={addModal}
        onOk={(e) => setAddModal(false)}
        onCancel={(e) => setAddModal(false)}
        footer={null}
        width={800}
        centered
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add New department
          </div>
        }
        className="global-modal-custom"
      >
        <UserPrivateComponent permission={"create-department"}>
          <Row justify="center">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              lg={{ span: 17, offset: 1 }}
              xl={{ span: 17, offset: 1 }}
              md={{ span: 17, offset: 1 }}
            >
              <Form
                style={{ marginBottom: "40px" }}
                eventKey="department-form"
                name="basic"
                // labelCol={{
                // 	span: 8,
                // }}
                // wrapperCol={{
                // 	span: 16,
                // }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "30px" }}
                    label="Department Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="Enter Department Name"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { span: 24, offset: 0 },
                      sm: { span: 24, offset: 0 },
                      md: { span: 12, offset: 6 }, // Remove offset for md screen
                      lg: { span: 12, offset: 6 },
                      xl: { span: 12, offset: 6 },
                    }}
                  >
                    <Button
                      onClick={() => setLoader(true)}
                      type="submit"
                      className="bg-[#E42976] text-white border-0"
                      size="medium"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Add New department
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
      <DelModal
        open={deptmodal}
        onClose={setdeptmodal}
        deptId={deptid}
        content={"Are you sure you want to delete?"}
        type={"deptdel"}
      />
    </>
  );
}

const AddDepartment = ({ drawer }) => {
  const dispatch = useDispatch();
  const AllDepartments = useSelector((state) => state.department.AllDepartments);
  const loading = useSelector((state) => state.department.loading);

  useEffect(() => {
    dispatch(GetAllDepartments())
  }, [])

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-department"}>
        <UserPrivateComponent />
        <UserPrivateComponent permission={"read-department"} />
        {drawer || <CustomTable list={AllDepartments} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddDepartment;
