import { Button, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BtnEditSvg from "../Button/btnEditSvg";
import { useDispatch } from "react-redux";
import {
	loadSingleWeeklyHoliday,
	updateWeeklyHoliday,
} from "../../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
import { BiEdit } from "react-icons/bi";

const WeeklyHolidayEdit = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { id } = useParams("id");
	const { Title } = Typography;
	const [loader, setLoader] = useState(false);

	const dispatch = useDispatch();

	const onFinish = async (values) => {
		setLoader(true);
		const resp = await dispatch(updateWeeklyHoliday({ id, values }));

		if (resp.payload.message === "success") {
			setLoader(false);
			dispatch(loadSingleWeeklyHoliday(id));
		} else {
			setLoader(false);
		}
	};

	const initialValues = {
		name: data?.name,
		startDay: data?.startDay,
		endDay: data?.endDay,
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding department");
		setLoader(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setLoader(false);
	};
	return (
		<>
			<button onClick={showModal}>
				{/* <BtnEditSvg size={30} /> */}
				<BiEdit size={30} style={{ color: "#0723A1" }} />
			</button>
			<Modal
				// title='Weekly Holiday Edit'
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				className="bank-holiday-modal"
			>
				<Row gutter={16}>
					<Col
						xs={{ span: 12, offset: 6 }}
						sm={{ span: 12, offset: 6 }}
						lg={{ span: 12, offset: 6 }}
						xl={{ span: 12, offset: 6 }}
						md={{ span: 12, offset: 6 }}
						>
						<Title level={4} className='m-2 mt-5 mb-5 text-center'>
							Weekly Holiday Edit
						</Title>
						<Form
							style={{ marginBottom: "50px" }}
							eventKey='department-form'
							initialValues={{ ...initialValues }}
							name='basic'
							layout="vertical"
							// labelCol={{
							// 	offset: 6,
							// 	span: 12,
							// }}
							// wrapperCol={{
							// 	span: 12,
							// 	offset: 6,
							// }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete='off'>
							<div>
								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Name'
									name='name'
									rules={[
										{
											required: true,
											message: "Please input name!",
										},
									]}>
									<Input className="border-none ant-shadow" />
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									label='Start Day'
									name='startDay'
									rules={[
										{
											required: true,
											message: "Please input start day!",
										},
									]}>
									<Select
									className="border-none ant-shadow"
										placeholder='Select Start Day'
										defaultValue={initialValues.startDay}>
										<Select.Option value='Saturday'>Saturday</Select.Option>
										<Select.Option value='Sunday'>Sunday</Select.Option>
										<Select.Option value='Monday'>Monday</Select.Option>
										<Select.Option value='Tuesday'>Tuesday</Select.Option>
										<Select.Option value='Wednesday'>Wednesday</Select.Option>
										<Select.Option value='Thursday'>Thursday</Select.Option>
										<Select.Option value='Friday'>Friday</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "15px" }}
									label='End Day'
									name='endDay'
									rules={[
										{
											required: true,
											message: "Please input end day!",
										},
									]}>
									<Select
									className="border-none ant-shadow"
										placeholder='Select Start Day'
										defaultValue={initialValues.endDay}>
										<Select.Option value='Saturday'>Saturday</Select.Option>
										<Select.Option value='Sunday'>Sunday</Select.Option>
										<Select.Option value='Monday'>Monday</Select.Option>
										<Select.Option value='Tuesday'>Tuesday</Select.Option>
										<Select.Option value='Wednesday'>Wednesday</Select.Option>
										<Select.Option value='Thursday'>Thursday</Select.Option>
										<Select.Option value='Friday'>Friday</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item
									style={{ marginBottom: "10px" }}
									wrapperCol={{
											xs: { span: 24, offset: 0 },
											sm: { span: 24, offset: 0 },
											md: { span: 22, offset: 2 }, 
											lg: { span: 22, offset: 2 },
											xl: { span: 22, offset: 2 },
										}}
									>
									<Button
										onClick={() => setLoader(true)}
										type='primary'
										size='medium'
										htmlType='submit'
										block
										loading={loader}>
										Update Weekly Holiday
									</Button>
								</Form.Item>
							</div>
						</Form>
					</Col>
				</Row>

			</Modal>
		</>
	);
};
export default WeeklyHolidayEdit;
