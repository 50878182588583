import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    employee: null,
    department: [],
    error: "",
    loading: false,
};

// ADD_DESIGNATION
export const addRole = createAsyncThunk(
    "role/addRole",
    async (formValues) => {
        try {
            console.log("form values at slice:",formValues);

            const { data } = await axios({
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `/role/`,
                data: {
                    ...formValues,
                },
            });
            toast.success("Role Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding role try again (slice)");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

/* // DELETE_ROLE
export const deleteRole = createAsyncThunk(
    "role/deleteRole",
    async (id) => {
        console.log(id, "slic Role id");
       // console.log(message, "slice message");

        try {
            const resp = await axios({
                method: "patch",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `role/${id}`,
                data: {
                    status: false,
                },
            });
            toast.success("Role Deleted (slice)");
            return resp.data.id;
        } catch (error) {
            console.log(error.message);
        }
    }
); */
export const deleteRole = createAsyncThunk(
    "role/deleteRole",
    async (id, { rejectWithValue }) => {
        try {
            const resp = await axios({
                method: "patch",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `role/${id}`,
                data: {
                    status: false,
                },
            });
            toast.success("Role Deleted Successfully!");
            return resp.data.id;
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle the case where the role is assigned to a user
                return rejectWithValue(error.response.data.message);
            } else {
                throw error;
            }
        }
    }
);


/* // DESIGNATION_DETAILS
export const loadSingleDesignation = createAsyncThunk(
    "designation/loadSingleDesignation",
    async (id) => {
        try {
            const data = await axios.get(`designation/employee/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
); */

/* // DESIGNATIONS
export const loadAllDesignation = createAsyncThunk(
    "designation/loadAllDesignation",
    async () => {
        try {
            const { data } = await axios.get(`designation?status=true`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
); */

/* export const loadAllDesignationByEmployee = createAsyncThunk(
    "designation/loadAllDesignationByEmployee",
    async () => {
        try {
            const { data } = await axios.get(`designation/employee`);

            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
); */

const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        clearRole: (state) => {
            state.role = null;
        },
    },
    extraReducers: (builder) => {
        // 1) ====== builders for loadAllDesignation ======

        /*     builder.addCase(loadAllDesignation.pending, (state) => {
                state.loading = true;
            });
    
            builder.addCase(loadAllDesignation.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            });
    
            builder.addCase(loadAllDesignation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            }); */

        // 2) ====== builders for loadAllDesignationByEmployee ======
        /* 
                builder.addCase(loadAllDesignationByEmployee.pending, (state) => {
                    state.loading = true;
                });
        
                builder.addCase(loadAllDesignationByEmployee.fulfilled, (state, action) => {
                    state.loading = false;
                    state.list = action.payload;
                });
        
                builder.addCase(loadAllDesignationByEmployee.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload.message;
                }); */

        // 2) ====== builders for addRole ======

        builder.addCase(addRole.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addRole.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 3) ====== builders for loadSingleDesignation ======

        /*     builder.addCase(loadSingleDesignation.pending, (state) => {
                state.loading = true;
            });
    
            builder.addCase(loadSingleDesignation.fulfilled, (state, action) => {
                state.loading = false;
                state.designation = action.payload.data;
            });
    
            builder.addCase(loadSingleDesignation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            }); */

        // 4) ====== builders for deleteDesignation ======

        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.loading = false;
            const filterCategory = state.list.filter(
                (category) => category.id !== parseInt(action.payload) && category
            );
            state.list = filterCategory;

            const filterDesignation = state.list.filter(
                (role) => role.id !== parseInt(action.payload) && role
            );

            state.list = filterDesignation;

        });

        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload; // This payload will contain the error message
        });

    },
});

export default roleSlice.reducer;
export const { clearRole } = roleSlice.actions;
