import React from "react";
import { Modal, Typography, Button } from "antd";
import { IoWarningOutline } from "react-icons/io5";
import { deleteStaff, loadAllStaff, loadSingleStaff, DeleteUserdoc } from "../../../redux/rtk/features/user/userSlice";
import { useDispatch } from "react-redux";
import { deleteShifts, loadAllNewShifts } from "../../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import { deleteAttendance, loadAllAttendance } from "../../../redux/rtk/features/attendance/attendanceSlice";
import { deleteDesignation } from "../../../redux/rtk/features/designation/designationSlice";
import { deleteDepartment, GetAllDepartments } from "../../../redux/rtk/features/department/departmentSlice";
import { deleteLocation } from "../../../redux/rtk/features/location/locationSlice";
import { deleteEmploymentStatus } from "../../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import { loadAllRooms, deleteRoom } from "../../../redux/rtk/features/rooms/roomSlice";
import { useNavigate } from "react-router-dom";
import {
  deleteLeavePolicy,
  loadAllLeavePolicy,
} from "../../../redux/rtk/features/leavePolicy/leavePolicySlice";
import { deletePublicHoliday } from "../../../redux/rtk/features/publicHoliday/publicHolidaySlice";
import {
  loadAllTraining,
  deleteTraining,
} from "../../../redux/rtk/features/training/trainingSlice";
import {
  deleteAnnouncement,
  loadAllAnnouncement,
} from "../../../redux/rtk/features/announcement/announcementSlice";
import {
  deleteProjectTasks,
  loadAllProjectTasks,
} from "../../../redux/rtk/features/projectManagement/project/projectTask/projectTask";
import {
  loadAllSwapRequest,
  DeleteSwapRequest,
} from "../../../redux/rtk/features/ShiftManagement/shiftManagementSlice";

const { Title } = Typography;

function DeleteWarningModal({
  open,
  onClose,
  userId,
  content,
  type,
  shiftId,
  attendanceId,
  itemsPerPage,
  currentPage,
  designId,
  deptId,
  locId,
  EmpStatusId,
  RoomId,
  LeavePolicyId,
  publicHolidayId,
  TrainingId,
  AnnouncementId,
  TaskId,
  staffId,
  reqId,
  docuserId,
  Ddata,
  ProfileModal,
  profileOpen
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCancel = () => {
    onClose(!open);
  };

  const handleDelete = async () => {
    try {
      if (type === "userdel") {
        const resp = await dispatch(deleteStaff(userId));
        if (resp.payload.message === "success") {
          onClose(!open);
          dispatch(loadAllStaff());
        } else {
          onClose(!open);
          console.log("something went wrong user not delete!!!");
        }
      }
      if (type === "shiftdel") {
        const resp = await dispatch(deleteShifts(shiftId));
        if (resp.payload.message === "success") {
          onClose(!open);
          dispatch(loadAllNewShifts(`shifts`));
        } else {
          onClose(!open);
        }
      }
      if (type === "attendancedel") {
        const resp = await dispatch(deleteAttendance(attendanceId));
        if (resp.payload.message === "success") {
          onClose(!open);
          dispatch(
            loadAllAttendance(
              `query=all&page=${currentPage}&count=${itemsPerPage}`
            )
          );
        } else {
          onClose(!open);
        }
      }
      if (type === "Designdel") {
        const resp = await dispatch(deleteDesignation(designId));
        if (resp.payload.message === "success") {
          navigate("/admin/designation/");
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "deptdel") {
        const resp = await dispatch(deleteDepartment(deptId));
        if (resp.payload.message === "success") {
          dispatch(GetAllDepartments());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "loctiondel") {
        const resp = await dispatch(deleteLocation(locId));
        if (resp.payload.message === "success") {
          navigate("/admin/location");
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "EmpStatus") {
        const resp = await dispatch(deleteEmploymentStatus(EmpStatusId));
        if (resp.payload.message === "success") {
          navigate("/admin/employment-status/");
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "Roomdel") {
        const resp = await dispatch(deleteRoom(RoomId));
        if (resp.payload.message === "success") {
          dispatch(loadAllRooms());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "LeavePolicy" || type === "DelPolicytable") {
        const resp = await dispatch(deleteLeavePolicy(LeavePolicyId));
        if (resp.payload.message === "success") {
          if (type === "LeavePolicy") {
            navigate(-1);
          } else {
            dispatch(loadAllLeavePolicy());
          }
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "publicHoliday") {
        const resp = await dispatch(deletePublicHoliday(publicHolidayId));
        if (resp.payload.message === "success") {
          navigate(-1);
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "Training") {
        const resp = await dispatch(deleteTraining(TrainingId));
        if (resp.payload.message === "success") {
          dispatch(loadAllTraining());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "Announcement") {
        const resp = await dispatch(deleteAnnouncement(AnnouncementId));
        if (resp.payload.message === "success") {
          dispatch(loadAllAnnouncement());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "TaskDel") {
        const resp = await dispatch(deleteProjectTasks(TaskId));
        if (resp.payload.message === "success") {
          dispatch(loadAllProjectTasks());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "staffdel") {
        const resp = await dispatch(deleteStaff(staffId));
        if (resp.payload.message === "success") {
          dispatch(loadAllStaff());
          navigate(-1);
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "SwapReq") {
        const resp = await dispatch(DeleteSwapRequest(reqId));
        if (resp.payload.message === "success") {
          dispatch(loadAllSwapRequest());
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
      if (type === "docdel") {
        const resp = await dispatch(DeleteUserdoc({ id: docuserId, FormData: Ddata }));
        if (resp.payload.message === "success") {
          dispatch(loadSingleStaff(docuserId));
          ProfileModal(!profileOpen)
          onClose(!open);
        } else {
          onClose(!open);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Modal
        centered
        open={open}
        onOk={() => onClose(!open)}
        onCancel={() => onClose(!open)}
        footer={null}
        className="global-modal-custom"
      >
        <div>
          <Title level={4} className="flex items-center gap-4 mb-10">
            <IoWarningOutline />
            {content}
          </Title>
          <div className="flex items-center justify-end gap-4">
            <Button
              onClick={handleDelete}
              className="btn text-[16px] font-[500] hover:bg-red-500 hover:text-white hover:outline-none hover:border-transparent"
            >
              Yes
            </Button>
            <Button
              onClick={handleCancel}
              className="btn text-[16px] font-[500]"
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteWarningModal;
