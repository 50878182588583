import {
  Card,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllTraining,
  deleteTraining,
} from "../../redux/rtk/features/training/trainingSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";


function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const dispatch = useDispatch();
  const [delModal, setDelModal] = useState(false)
  const [delId, setDelId] = useState()
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Day Name",
      dataIndex: "day",
      key: "day",
    },

    {
      id: 3,
      title: "Training From",
      dataIndex: "leaveFrom",
      key: "leaveFrom",
      render: (leaveFrom) => dayjs(leaveFrom).format("DD-MM-YYYY"),
    },

    {
      id: 3,
      title: "Training To",
      dataIndex: "leaveTo",
      key: "leaveTo",
      render: (leaveTo) => dayjs(leaveTo).format("DD-MM-YYYY"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <div className="flex  items-center">
          <UserPrivateComponent permission={"delete-training"}>
            <button
              type="primary"
              onClick={() => onDelete(id)}
              className="mr-3 ml-2"
            >
              <MdDelete size={25} color="#FF0000" />
            </button>
          </UserPrivateComponent>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

   function onDelete(id) {
    setDelId(id)
    setDelModal(true)
  }


  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((i) => ({
        id: i?.id,
        DayName: i?.day == null ? "-" : i?.day,
        TrainingFrom:
          i?.leaveFrom == null ? "-" : dayjs(i?.leaveFrom).format("DD-MM-YYYY"),
        TrainingTo:
          i?.leaveTo == null ? "-" : dayjs(i?.leaveTo).format("DD-MM-YYYY"),
      }))
      : "";

  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-4">
        Training List
      </h5>

      <div className="text-center my-2 flex justify-between items-center flex-wrap">
        <div className="flex items-center">
          {list && (
            <div>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
          )}
          {list && (
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                <CSVLink
                  data={CSVlist}
                  className="btn btn-dark btn-sm text-white text-[15px] font-[400]"
                  filename="Tranings-List"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          )}
        </div>
        <div>
          <Link
            to="/admin/training"
            className=" bg-[#E42976] text-[15px] font-[400] text-white border-0 h-[32px] py-0 px-[25px] flex items-center text-center rounded-[5px]"
          >
            Add Training Day
          </Link>
        </div>
      </div>




      <Card className="ant-shadow rounded-[15px]" bordered={false}>
        <Table
          scroll={{ x: true }}
          loading={!list || loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <DelModal
        open={delModal}
        onClose={setDelModal}
        TrainingId={delId}
        content={"Are you sure to delete this Training?"}
        type={"Training"}
      />

    </>
  );
}

const AllTraining = ({ drawer }) => {
  const { loading } = useSelector((state) => state.training);
  const alltraining = useSelector((state) => state.training.alltraining);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllTraining());
  }, []);

  return (
    <Fragment bordered={false}>
      {drawer || <CustomTable list={alltraining} loading={loading} />}
    </Fragment>
  );
};

export default AllTraining;
