import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetpassword } from '../../redux/rtk/features/user/userSlice'; // Import your resetPassword action
import { toast } from "react-toastify";
import { Form, Button, Input, Row, Col, Card } from 'antd';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import BtnLoader from '../loader/BtnLoader';
import restimg from "../../assets/images/resetp.png";
import logo from "../../assets/images/logo.png";
import styles from "./Login.module.css";
import { FaEyeSlash, FaLock } from "react-icons/fa";

const initialValues = {
  password: "",
  confirmpassword: "",
  submit: null,
}

const validationSchema = Yup.object().shape({
  password: Yup.string().max(255).required("Password is required"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .max(255)
    .required("Confirm Password is required"),
});

const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { email: email } = useParams(); // Extract email parameter from the URL

  const handleSubmit = async () => {

    if (values.password === values.confirmpassword) {
      try {
        const resultAction = await dispatch(resetpassword({
          email: email,
          password: values.password,

        }));
        if (resetpassword.fulfilled.match(resultAction)) {
          // Password reset successful
          toast.success('Password reset successfully');

          // Redirect to login page or any other desired page
          navigate('/admin/auth/login');

        }
        else {
          // Password reset failed
          toast.error('Password reset failed frontend');
          return;
        }
      } catch (error) {
        // Handle any errors that occur during the process
        console.error('Error resetting password:', error);
        toast.error('An error occurred while resetting the password');
      }
    } else {
      toast.error('Passwords do not match');
    }
  };

  const { values, errors, touched, handleBlur, handleChange } = useFormik(
    {
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: handleSubmit, // Use the custom handleSubmit function

    });
  return (
    <Row gutter={16} className='card-row'>
        <Col xs={0}  sm={12} className={styles.AlignmentImage}>
        <div className={styles.imageContainer}>
            <img src={restimg} alt="Company Logo" width={550} />
          </div>
        </Col>
      <Col xs={24} sm={12} className={styles.AlignmentCard}>
        <Card bordered={false} className={`${styles.card} mt-1 mb-3`}>
          <div className={styles.logoImg}>
            <img src={logo} alt="Company Logo" width={100} />
          </div>
          <h3 className='m-3 mb-4 text-center'> Reset Password</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className='p-4' >
              <Form.Item className="form-group mb-2 p-4">
                <label htmlFor="password"> New Password:</label>
                <div className={styles.Inputset}>
                  <Input className="form-control"
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur} />
                  <FaLock className={styles.iconEye} 
                  />
                </div>

                {errors.password && touched.password ?
                  (<p className="mt-1" style={{ color: "red", fontSize: "13px" }}>{errors.password}</p>)
                  : null}
              </Form.Item>
              <Form.Item className="form-group mb-2 p-4">
                <label htmlFor="confirmpassword"> Confirm Password:</label>
                <div className={styles.Inputset}>
                  <Input className="form-control"
                    name='confirmpassword'
                    placeholder="Confirm Password"
                    type="password"
                    value={values.confirmpassword}
                    onChange={handleChange} s
                    onBlur={handleBlur}
                  />
                  <FaLock className={styles.iconEye} />
                </div>

                {errors.confirmpassword && touched.confirmpassword ?
                  (<p className="mt-1" style={{ color: "red", fontSize: "13px" }}>{errors.confirmpassword}</p>)
                  : null}
              </Form.Item>
              <Form.Item className={styles.submitBtnContainer}>

                {!loader ? (
                  <Button
                    type='submit'
                    className='bg-[#E42976] text-white border-0'
                    htmlType='submit'
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                ) : (
                  <BtnLoader />
                )}
              </Form.Item>
            </Form>

          </Formik>
        </Card>
      </Col>
    </Row>

  );
}

export default PasswordReset;
