import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Divider
} from "antd";
import styles from "./Login.module.css";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllDesignation } from "../../redux/rtk/features/designation/designationSlice";
import BigDrawer from "../Drawer/BigDrawer";
import AddRole from "../role/AddRole";
import { getRoles } from "../role/roleApis";
import EmployeeEducationForm from "./EmployeeEducationForm";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getDepartments } from "../department/departmentApis";
import { loadAllEmploymentStatus } from "../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import { loadAllShift } from "../../redux/rtk/features/shift/shiftSlice";
import { getLocations } from "../../redux/rtk/features/location/locationSlice";
import { loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import { addStaff } from "../../redux/rtk/features/user/userSlice";
import { UploadUserImage } from "../../redux/rtk/features/user/userSlice";
import { loadAllWeeklyHoliday } from "../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
import { loadAllLeavePolicy } from "../../redux/rtk/features/leavePolicy/leavePolicySlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import Demo from "../../assets/images/demo.jpg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Title } = Typography;
  const { Option } = Select;
  const [list, setList] = useState(null);
  const [department, setDepartment] = useState(null);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState();
  const [firstAidFile, setFirstAidFile] = useState();
  const [dbsCheckFile, setDbsCheckFile] = useState();
  const [saveGuardFile, setSaveGuardFile] = useState();
  const [loader, setLoader] = useState(false);
  const [secRef, setSecRef] = useState(false);
  const [education, setEducation] = useState([
    {
      degree: "",
      institution: "",
      fieldOfStudy: "",
      result: "",
      studyStartDate: "",
      studyEndDate: "",
    },
  ]);

  const designation = useSelector((state) => state.designations?.list);
  const employmentStatus = useSelector((state) => state.employmentStatus?.list);
  const shift = useSelector((state) => state.shift?.list);
  //const location = useSelector((state) => state.location?.list);

  const weeklyHoliday = useSelector((state) => state.weeklyHoliday?.list);
  const leavePolicy = useSelector((state) => state.leavePolicy?.list);

  useEffect(() => {
    getRoles()
      .then((d) => setList(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getDepartments()
      .then((d) => setDepartment(d))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    getLocations()
      .then((d) => setLocation(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(loadAllDesignation());
    dispatch(loadAllEmploymentStatus());
    dispatch(loadAllShift());
    dispatch(loadAllLocation());
    dispatch(loadAllWeeklyHoliday());
    dispatch(loadAllLeavePolicy());
  }, []);

  const [form] = Form.useForm();

  function handleFileChange(e) {
    setFile({
      imagePreview: URL?.createObjectURL(e.target.files[0]),
      imageAsFile: e.target.files[0],
    });
  }

  var imgData = new FormData();

  const onFinish = async (values) => {
    const FormData = {
      ...values,
      educations: values.educations || education,
    };
    try {
      if (file?.imagePreview || firstAidFile || dbsCheckFile || saveGuardFile) {
        if (file?.imageAsFile) {
          imgData.append("image", file?.imageAsFile);
        }
        if (firstAidFile?.imageAsFile) {
          imgData.append("firstaid", firstAidFile?.imageAsFile);
        }
        if (dbsCheckFile?.imageAsFile) {
          imgData.append("dbscheck", dbsCheckFile?.imageAsFile);
        }
        if (saveGuardFile?.imageAsFile) {
          imgData.append("safeguard", saveGuardFile?.imageAsFile);
        }
        dispatch(
          UploadUserImage({
            imgData: imgData,
            FormData: FormData,
            value: "Add",
            navigate: navigate
          })
        );
        setLoader(false);

        // console.log("imgresp",imgresp)
        // setLoader(true);
        // if (imgresp.payload.message === "success") {
        // 	setLoader(false);
        // } else if (imgresp.payload.message === "error") {
        // 	if (imgresp.payload.error) {
        // 		toast.error(imgresp.payload.error);
        // 	} else {
        // 		toast.error("An error occurred while adding staff. Please try again.");
        // 	}
        // 	setLoader(false);
        // } else {
        // 	setLoader(false);
        // }
      } else {
        const resp = await dispatch(addStaff({ FormData: FormData, navigate: navigate }));
        setLoader(true);
        if (resp.payload.message === "success") {
          form.resetFields();
          setSecRef(false);
          setLoader(false);
        } else if (resp.payload.message === "error") {
          if (resp.payload.error) {
            toast.error(resp.payload.error);
          } else {
            toast.error(
              "An error occurred while adding staff. Please try again."
            );
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoader(false);
    console.log("Failed:", errorInfo);
  };
  const status = ["Foreigner", "National"];
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-user"}>
        <div className="mr-top mt-5 p-5 ant-card " style={{ maxWidth: "100%" }}>
          <Form
            size="small"
            form={form}
            name="basic"
            layout="vertical"
            labelCol={{
              span: 22,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className={`flex justify-center items-center mb-4`}>
              <img
                name="file"
                src={file?.imagePreview ? file?.imagePreview : Demo}
                style={{ height: "130px" }}
                className="w-[130px] block object-cover rounded-[50%]"
                alt="img"
              />
            </div>
            <div className="flex justify-center items-center mb-8">
              <input
                style={{ width: "20%" }}
                type="file"
                className="edit-custom-file-input"
                id="file"
                name="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
              />
              {file && (
                <Button className="border-0" onClick={(e) => setFile("")}>
                  <MdDelete size={18} className="text-[#FF0000]" />
                </Button>
              )}
            </div>

            <h2 className="text-center text-xl mt-3 mb-6 txt-color">
              User Information
            </h2>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row form-color"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input First Name!",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name!",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item> */}
                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="User Name"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please input User Name!",
                    },
                  ]}
                >
                  <Input placeholder="john_doe" />
                </Form.Item> */}
                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password !",
                    },
                  ]}
                >
                  <Input.Password placeholder="Strong Password" />
                </Form.Item> */}
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                  ]}
                >
                  <Input type="email" placeholder="johndoe2@example.com" />
                </Form.Item>
                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input phone!",
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter only numbers.",
                    },
                  ]}
                >
                  <Input placeholder="123456789" style={{ width: "100%" }} />
                </Form.Item> */}
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Emergency Contact:"
                  name="emergencycontact"
                  rules={[
                    {
                      required: true,
                      message: "Please input Emergency Contact!",
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter only numbers.",
                    },
                  ]}
                >
                  {/* <InputNumber placeholder='123456789' style={{width:"100%"}} /> */}
                  <Input placeholder="123456789" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Visa Status"
                  name="visaStatus"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Visa Status!",
                //   },
                // ]}
                >
                  <Input placeholder="Status" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Add DBS Number:"
                >
                  <label class="input-group">
                    <div class="input-group-area">
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        name="dbschecktext"
                      >
                        <Input placeholder="Add DBS Number" />
                      </Form.Item>
                    </div>
                    <div class="input-group-icon">
                      <input
                        type="file"
                        style={{ width: "100%" }}
                        className="multi-input-custom"
                        id="file"
                        name="file"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        multiple
                        onChange={(e) =>
                          setDbsCheckFile({ imageAsFile: e.target.files[0] })
                        }
                      />
                    </div>
                  </label>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row form-color"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name!",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password !",
                    },
                  ]}
                >
                  <Input.Password placeholder="Strong Password" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="NI No:"
                  name="nicno"
                  rules={[
                    {
                      required: true,
                      message: "Please input NI No!",
                    },
                  ]}
                >
                  {/* <InputNumber placeholder='123456789' style={{width:"100%"}} /> */}
                  <Input
                    placeholder="TL 12 34 56 C"
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="DOB"
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "Please input Date of Birth!",
                    },
                  ]}
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px" }} label="First Aid:">
                  <label class="input-group">
                    {/* <div class="input-group-area">
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        name="firstaidtext"
                      >
                        <Input placeholder="add first aid link.." />
                      </Form.Item>
                    </div> */}
                    <div class="input-group-icon">
                      <input
                        type="file"
                        style={{ width: "100%" }}
                        className="multi-input-custom"
                        id="file"
                        name="file"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        multiple
                        onChange={(e) =>
                          setFirstAidFile({ imageAsFile: e.target.files[0] })
                        }
                      />
                    </div>
                  </label>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row form-color"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="User Name"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please input User Name!",
                    },
                  ]}
                >
                  <Input placeholder="john_doe" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input phone!",
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter only numbers.",
                    },
                  ]}
                >
                  <Input placeholder="123456789" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Visa Expiry"
                  name="visaExpiry"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input expiry date!",
                //   },
                // ]}
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>

                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Identity Status:"
                  name="identitystatus"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Identity Status!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Identity Status!"
                    allowClear
                    mode="single"
                    size="middle"
                    style={{
                      width: "100%",
                    }}
                  >
                    {status.map((status) => (
                      <Option key={status} value={status}>
                        {status}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Safeguarding:"
                >
                  <label class="input-group">
                    {/* <div class="input-group-area">
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        name="safeguardingtext"
                      >
                        <Input placeholder="add Safeguarding link" />
                      </Form.Item>
                    </div> */}
                    <div class="input-group-icon">
                      <input
                        type="file"
                        style={{ width: "100%" }}
                        className="multi-input-custom"
                        id="file"
                        name="file"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        multiple
                        onChange={(e) =>
                          setSaveGuardFile({ imageAsFile: e.target.files[0] })
                        }
                      />
                    </div>
                  </label>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <h2 className="text-center text-xl mt-6 mb-3 txt-color">
              {" "}
              Employee Information{" "}
            </h2>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Joining Date"
                  name="joinDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input joining date!",
                    },
                  ]}
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>

                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Blood Group"
                  name="bloodGroup"
                  rules={[
                    {
                      required: true,
                      message: "Please input Blood Group!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Blood Group"
                    allowClear
                    mode="single"
                    size="middle"
                    style={{
                      width: "100%",
                    }}
                  >
                    {bloodGroups.map((bloodGroup) => (
                      <Option key={bloodGroup} value={bloodGroup}>
                        {bloodGroup}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}
                {/* TODO: Add a Upload Seciton for Image */}
                <Form.Item
                  name={"employmentStatusId"}
                  style={{ marginBottom: "10px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input Employment Status!",
                    },
                  ]}
                  label="Employee Status"
                >
                  <Select
                    placeholder="Select Status"
                    allowClear
                    size={"middle"}
                  >
                    {employmentStatus &&
                      employmentStatus.map((employmentStatus) => (
                        <Option
                          key={employmentStatus.id}
                          value={employmentStatus.id}
                        >
                          {employmentStatus.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  rules={[
                    { required: true, message: "Please input Department!" },
                  ]}
                  label="Shift"
                  name={"shiftId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!shift}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                  >
                    {shift &&
                      shift.map((shift) => (
                        <Option key={shift.id} value={shift.id}>
                          {shift.name}
                        </Option>
                      ))}
                  </Select>
                  <BigDrawer
										title={"new Role"}
										btnTitle={"Role"}
										children={<AddRole drawer={true} />}
											/>
                </Form.Item> */}

                <Form.Item
                  // rules={[
                  //   { required: true, message: "Please input Location!" },
                  // ]}
                  label="Location"
                  name={"locationId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!location}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                  >
                    {location &&
                      location.map((location) => (
                        <Option key={location.id} value={location.id}>
                          {location.locationName}
                        </Option>
                      ))}
                  </Select>
                  {/*<BigDrawer
										title={"new Role"}
										btnTitle={"Role"}
										children={<AddRole drawer={true} />}
											/> */}
                </Form.Item>

                {/* <Form.Item
                  rules={[
                    { required: true, message: "Please input Department!" },
                  ]}
                  label="Weekly Holiday"
                  name={"weeklyHolidayId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!weeklyHoliday}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                  >
                    {weeklyHoliday &&
                      weeklyHoliday.map((weeklyHoliday) => (
                        <Option key={weeklyHoliday.id} value={weeklyHoliday.id}>
                          {weeklyHoliday.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item> */}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row form-color"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Leave Date"
                  name="leaveDate"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input Department!" },
                  ]}
                  label="Role"
                  name={"roleId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!list}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                  >
                    {list &&
                      list.map((role) => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
                  </Select>
                  {/*<BigDrawer
										title={"new Role"}
										btnTitle={"Role"}
										children={<AddRole drawer={true} />}
											/> */}
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input Department!" },
                  ]}
                  label="Leave Policy"
                  name={"leavePolicyId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!leavePolicy}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                  >
                    {leavePolicy &&
                      leavePolicy.map((leavePolicy) => (
                        <Option key={leavePolicy.id} value={leavePolicy.id}>
                          {leavePolicy.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row form-color"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Employee ID"
                  name="employeeId"
                  rules={[
                    {
                      required: true,
                      message: "Please input Employee ID!",
                    },
                  ]}
                >
                  <Input placeholder="OE-012" />
                </Form.Item>
                <Form.Item
                  name={"departmentId"}
                  style={{ marginBottom: "10px" }}
                  label="Department"
                  rules={[
                    { required: true, message: "Please input Department!" },
                  ]}
                >
                  <Select
                    loading={!department}
                    placeholder="Select Department"
                    allowClear
                    size={"middle"}
                  >
                    {department &&
                      department.map((department) => (
                        <Option key={department.id} value={department.id}>
                          {department.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Annual Leave"
                  name="manualleave"
                  rules={[
                    {
                      required: false,
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      required: false,
                      type: 'number',
                      min: 1,
                      message: "Annual Leave must be greater than zero",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="add Annual Leaves (Optional)"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <h2 className="text-center text-xl mt-6 mb-6 txt-color">
              Address Information
            </h2>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Street"
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: "Please input street!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Carisbrook Street"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Post Code"
                  name="zipCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input Post Code!",
                      // type: "regexp",
                      // pattern: new RegExp(/\d+/g),
                    },
                  ]}
                >
                  {/* <InputNumber placeholder='90211' style={{width:"100%"}} /> */}
                  <Input placeholder="M9 5UX" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "Please input city!" }]}
                >
                  <Input placeholder="Manchester" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: "Please input Country!" }]}
                >
                  <Input placeholder="United Kingdom" />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="County"
                  name="state"
                  rules={[{ required: true, message: "Please input state!" }]}
                >
                  <Input placeholder="Greater Manchester" />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <h2 className="text-center text-xl mt-3 mb-6 txt-color">
              Designation & Salary Information
            </h2>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Please input Designation!" },
                  ]}
                  label="Designation"
                  name={"designationId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!shift}
                    size="middle"
                    mode="single"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select Designation"
                  >
                    {designation &&
                      designation.map((designation) => (
                        <Option key={designation.id} value={designation.id}>
                          {designation.name}
                        </Option>
                      ))}
                  </Select>
                  {/*<BigDrawer
									title={"new Role"}
									btnTitle={"Role"}
									children={<AddRole drawer={true} />}
										/> */}
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Salary"
                  name="salary"
                  rules={[
                    {
                      required: true,
                      message: "Please input salary",
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      required: true,
                      type: 'number',
                      min: 1,
                      message: "Salary must be greater than zero",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Salary Comment"
                  name="salaryComment"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label={
                    <label
                      style={{
                        margin: "8px 0",
                        whiteSpace: "pre-line",
                        lineHeight: "14px",
                      }}
                    >
                      Designation Start Date
                    </label>
                  }
                  rules={[{ required: true, message: "Please input date!" }]}
                  name="designationStartDate"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
                <Form.Item
                  label="Salary Start Date"
                  name="salaryStartDate"
                  style={{ marginBottom: "10px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input date!",
                    },
                  ]}
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label={
                    <label
                      style={{
                        margin: "8px 0",
                        whiteSpace: "pre-line",
                        lineHeight: "14px",
                      }}
                    >
                      Designation End Date
                    </label>
                  }
                  name="designationEndDate"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Salary End Date"
                  name="salaryEndDate"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <h2 className="text-center text-xl mt-3 mb-6 txt-color">
              Previous Employer (if Any)
            </h2>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Company Name"
                  name="companyname"
                >
                  <Input placeholder="Company Name" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Tel No"
                  name="emp_telno"
                  rules={[
                    {
                      required: false,
                      type: "regexp",
                      pattern: new RegExp(/\d+/g),
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter only numbers.",
                    },
                  ]}
                >
                  <Input placeholder="123456789" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="End Date"
                  name="end_date"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Employer Name"
                  name="emp_name"
                >
                  <Input
                    placeholder="Employer Name"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Job Title"
                  name="designation"
                >
                  <Input placeholder="Enter Job Title!" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Address"
                  name="address"
                >
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                span={8}
                className="gutter-row"
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Employer Email"
                  name="emp_email"
                >
                  <Input type="email" placeholder="example@gmail.com" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Joining Date"
                  name="joining_date"
                >
                  <DatePicker className="date-picker hr-staffs-date-picker" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                md: { span: 12, offset: 6 },
                lg: { span: 12, offset: 6 },
                xl: { span: 12, offset: 6 },
              }}
              style={{ marginBottom: "2rem", marginTop: "10px" }}
            >
              <Button
                className="mt-5 bg-[#E42976] text-white border-0 hover:border-[transparent] w-full flex justify-center items-center gap-2"
                size="large"
                fullWidth
                shape="round"
                onClick={() => setSecRef(!secRef)}
              >
                Add Another Employer{" "}
                {secRef == false ? (
                  <FiPlusCircle size={20} />
                ) : (
                  <FiMinusCircle size={20} />
                )}
              </Button>
            </Form.Item>

            <div
              className="mb-4"
              style={
                secRef == true ? { display: "block" } : { display: "none" }
              }
            >
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  span={8}
                  className="gutter-row"
                >
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Company Name"
                    name="companyname1"
                  >
                    <Input
                      placeholder="Company Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Tel No"
                    style={{ marginBottom: "10px" }}
                    name="emp_telno1"
                    rules={[
                      {
                        required: false,
                        type: "regexp",
                        pattern: new RegExp(/\d+/g),
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Tel No" style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    style={{ marginBottom: "10px" }}
                    name="end_date1"
                  >
                    <DatePicker
                      placeholder="End Date"
                      className="date-picker hr-staffs-date-picker"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  span={8}
                  className="gutter-row"
                >
                  <Form.Item
                    label="Employer Name"
                    style={{ marginBottom: "10px" }}
                    name="emp_name1"
                  >
                    <Input
                      placeholder="Employer Name"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Job Title"
                    style={{ marginBottom: "10px" }}
                    name="designation1"
                  >
                    <Input placeholder="Enter Job Title!" />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    style={{ marginBottom: "10px" }}
                    name="address1"
                  >
                    <Input placeholder="Enter Address" />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  span={8}
                  className="gutter-row"
                >
                  <Form.Item
                    label="Employer Email"
                    style={{ marginBottom: "10px" }}
                    name="emp_email1"
                  >
                    <Input type="email" placeholder="example@gmail.com" />
                  </Form.Item>
                  <Form.Item
                    label="Joining Date"
                    style={{ marginBottom: "10px" }}
                    name="joining_date1"
                  >
                    <DatePicker
                      placeholder="Joining Date"
                      className="date-picker hr-staffs-date-picker"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider />
            <h2 className="text-center text-xl mt-8 mb-5 txt-color">
              Education Information
            </h2>

            <div className="text-center">
              <p className="text-red-500 text-base mb-4">
                Please add education information using the button below
              </p>
            </div>

            <Form.List name="educations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <EmployeeEducationForm
                      key={key}
                      name={name}
                      remove={remove}
                      restField={restField}
                    />
                  ))}
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    wrapperCol={{
                      xs: { offset: 0, span: 16 },
                      sm: { offset: 0, span: 16 },
                      md: { offset: 4, span: 16 },
                      lg: { offset: 4, span: 16 },
                      xl: { offset: 4, span: 16 },
                    }}
                  >
                    <Button
                      type="dashed"
                      size="middle"
                      style={{ color: "#fff", backgroundColor: "#2c3e50" }}
                      className="hover:border-[#0622a1]"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Education Information
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              style={{ marginBottom: "10px", marginTop: "10px" }}
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                md: { span: 12, offset: 6 },
                lg: { span: 12, offset: 6 },
                xl: { span: 12, offset: 6 },
              }}
            >
              <Button
                className="mt-5 !bg-[#E42976] text-white border-0 hover:border-[transparent]"
                size="large"
                // onClick={() => setLoader(true)}
                loading={loader}
                block
                type="submit"
                htmlType="submit"
                shape="round"
              >
                Add New Staff
              </Button>
            </Form.Item>
          </Form>
        </div>
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddUser;
