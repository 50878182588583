import { Button, DatePicker, Drawer, Form, Input, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSingleSwap,
  updateSwapRequest,
} from "../../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import getUserFromToken from "../../../utils/getUserFromToken";
import moment from "moment";
import styles from "../../leave/leave.module.css";
import TextArea from "antd/lib/input/TextArea";
import { DevicesOtherOutlined } from "@mui/icons-material";

const SwapRejectionReason = (pageId) => {
  const { id } = useParams("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const data = useSelector((state) => state.ShiftManagement.SingleSwap);
  const [reasons, setReasons] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [open, setOpen] = useState(false);
  const { Option } = Select;

  const rejectionList = [
    "Duplicate request",
    "Business need cannot accommodate request.",
    "At least one or two other staff already booked",
    "Limited number of Coaches within WMB group (for Mgt)",
    "Leave accumulated not enough to cover leave requested.",
    "Leave not allowed during core registration periods",
    "Other reasons....",
  ];

  const onFinish = async (values) => {
    const FormData = {
      reason: reasons == "Other reasons...." ? otherReason : reasons,
      userId: data?.userId,
      requststatus: "REJECTED",
    };
    const resp = await dispatch(
      updateSwapRequest({ id: id, values: FormData })
    );
    if (resp.payload.message === "success") {
      form.resetFields();
      dispatch(loadSingleSwap(id));
      setOpen(false);
    } else {
      setLoader(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={`${styles.Button} text-center  mr-2 p-2`}>
        <Button
          onClick={showDrawer}
          style={{ backgroundColor: "red", color: "white" }}
          className="border-0 hover:border-0"
          key="submit"
          htmlType="submit"
        >
          Rejected
        </Button>
      </div>
      {data && (
        <Modal
          width={720}
          title={
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5rem",
                margin: "5px 25px",
                padding: "10px",
                backgroundColor: "#EEF1F8",
              }}
            >
              Reject Application
            </div>
          }
          className="global-modal-custom "
          placement="right"
          visible={open} // Use 'visible' prop to control the visibility of the modal
          onCancel={onClose} // Specify the onCancel callback to close the modal when clicking outside or the close button
          footer={false}
          bodyStyle={{ padding: 0 }}
        >
          <Form
            className="rounded px-5 py-2"
            form={form}
            layout="vertical"
            name="basic"
            // labelCol={{
            // 	span: 7,
            // }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label={<label>Select Rejection Reason (Optional)</label>}
              >
                <Select
                  placeholder="Select Reason"
                  allowClear
                  size={"middle"}
                  onChange={(value) => setReasons(value)}
                >
                  {rejectionList &&
                    rejectionList.map((reason) => (
                      <Option key={reason} value={reason}>
                        {reason}
                      </Option>
                    ))}
                </Select>
                {reasons == "Other reasons...." ? (
                  <TextArea
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder="Enter Other reason...."
                    rows={5}
                    className={`${styles.CommentBox} mt-2`}
                  />
                ) : (
                  ""
                )}
              </Form.Item>
              <div className="flex items-center justify-center">
                <Form.Item
                  className={` ${styles.Button} flex justify-center text-white text-center px-5 mt-4 ml-4 `}
                >
                  <Button
                    className="text-white mt-2 border-0 hover:border-0"
                    // onClick={() => setLoader(true)}
                    style={{ backgroundColor: "red", color: "white" }}
                    htmlType="submit"
                    block
                    loading={loader}
                  >
                    Reject
                  </Button>
                </Form.Item>
                <Form.Item
                  className={` ${styles.Button} flex justify-center text-white text-center px-5 mt-4 `}
                >
                  <Button
                    className="text-white mt-2 border-0 hover:border-0 bg-gray-400"
                    onClick={() => setOpen(false)}
                    block
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};
export default SwapRejectionReason;
