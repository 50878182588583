import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styles from "./department.module.css";
import { Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllLocation } from "../../../redux/rtk/features/location/locationSlice";
const LocationTable = () => {

  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.location.list);
  console.log("locationData  Dashboard:", locationData)

  useEffect(() => {
    // Load location data when the component mounts
    dispatch(loadAllLocation());
  }, [dispatch]);





  return (
    <div className={styles.tableContainer}>
      <Table className={`${styles.customTable}`} hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Total Emp</th>
          </tr>
        </thead>
        <tbody>
          {/* {data.map((row) => (
                    <tr key={row.id} className={styles.tableRow}>
                        <td>{row.id}</td>
                        <td>{row.name}</td>
                        <td>
                            <Tag
                                className='px-5'
                                color={
                                    row.emp <= 60
                                        ? 'yellow'
                                        : row.emp >= 70
                                            ? 'green'
                                            : row.emp >= 30
                                                ? 'red'
                                                : 'red'
                                }
                            >
                                {row.emp}
                            </Tag></td>
                    </tr>
                ))} */}
          {Array.isArray(locationData) && locationData.length > 0 ?
            locationData.map((row, index) => (
              <tr key={row.id} className={styles.tableRow}>
                <td>{index + 1}</td>
                <td>{row.locationName}</td>
                <td>
                  <Tag
                    className='px-5'
                    color={
                      row.user.length >= 60
                        ? 'yellow'
                        : row.user.length >= 70
                          ? 'green'
                          : row.user.length >= 30
                            ? 'purple'
                            : 'purple'
                    }
                  >
                    {row.user.length}
                  </Tag>
                </td>
              </tr>
            ))
            :
          
             ' Data not found'
         
          }
        </tbody>
      </Table>
    </div>
  );
};

export default LocationTable;
