import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Button, Radio, Select, TimePicker } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styles from "./leave.module.css";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AiOutlineDown } from "react-icons/ai";
import { MarkLeaveApplication } from "../../redux/rtk/features/leave/leaveSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { disable } from 'workbox-navigation-preload';

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  console.log(time, timeString);
};
const ManualLeave = () => {
  const dispatch = useDispatch();
  const AllEmp = useSelector((state) => state.users.list);
  // Define state variables for form fields
  const [employeeId, setEmployeeId] = useState('');
  // const [leaveType, setLeaveType] = useState('PAID');
  const [leaveDuration, setLeaveDuration] = useState("FULL");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [leaveCategory, setLeaveCategory] = useState(
    "SicknessLeave(non-deductible)"
  );
  const [form] = Form.useForm();
  // const { Option } = Select;
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  // Define an event handler for form submission
  const handleSubmit = (e) => {
    if (employeeId == "") {
      return toast.error("employeeId is required");
    }
    if (state[0]?.startDate == null || state[0]?.endDate == null) {
      return toast.error("Please Select Dates!");
    }
    if (leaveDuration == "HALF") {
      if (startTime == "") {
        return toast.error("Start Time is required");
      }
      if (endTime == "") {
        return toast.error("End Time is required");
      }
    }
    const values = {
      employeeId: employeeId,
      leaveType: leaveCategory,
      leavecategory: null,
      daytype: leaveDuration,
      fromtime: leaveDuration == "FULL" ? "" : startTime,
      totime: leaveDuration == "FULL" ? "" : endTime,
      leaveFrom: dayjs(state[0]?.startDate).format("YYYY-MM-DD"),
      leaveTo: dayjs(state[0]?.endDate).format("YYYY-MM-DD"),
      reason: "",
    };
    dispatch(MarkLeaveApplication(values));
    setEmployeeId("");
    setStartTime("");
    setEndTime("");
    setState([
      {
        startDate: new Date(),
        endDate: null,
        key: "selection",
      },
    ]);
  };


  function onChange(value) {
    setEmployeeId(value)
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <div className={styles.container}>
      <Card className={`rounded-[20px] ${styles.card}`}>
        <div className={`mb-3 ${styles.title} text-center `}>
          <h2 className="">Mark Leave</h2>
        </div>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mt-2">
            <DateRange
              className={styles.cardBody}
              ShowInputs={false}
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              rangeColors={["#0622A1", "#0622A1", "#0622A1"]}
            />
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mt-2 p-2">
            <Form
              className={` p-3`}
              layout="horizontal"
              size="large"
              name="basic"
              form={form}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12, offset: 2 }}
              labelAlign="left"
            >
              <div className="flex items-center mb-[10px] justify-between">
                <div className="text-left">
                  <label>Employee Name :</label>
                </div>
                <div className="w-[68%]">
                  <Select
                    showSearch
                    allowClear
                    style={{
                      width: "100%",
                      boxShadow: "0px 0px 5px gray",
                      borderRadius: "5px",
                      textAlign:"start"
                    }}
                    placeholder="Select a Name"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {AllEmp &&
                      AllEmp.map((emp) => (
                        <Select.Option key={emp.id} value={emp?.employeeId}>
                          {emp?.firstName + " " + emp.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* <Input
                    size="small"
                    placeholder="Enter Employee ID"
                    value={employeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                    style={{
                      border: "none",
                      boxShadow: "0px 0px 5px  #d1cccc",
                    }}
                  /> */}
                </div>
              </div>
              {/* </Form.Item> */}

              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Day:"
                name="leaveDuration"
                wrapperCol={{ offset: 3, span: 14 }}
              >
                <div className={styles.RadioStyle}>
                  <Radio.Group
                    className="flex justify-between"
                    value={leaveDuration}
                    onChange={(e) => setLeaveDuration(e.target.value)}
                  >
                    <Radio value="FULL">Full Day</Radio>
                    <Radio value="HALF">Half Day</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>

              {/* <div className=' flex justify-between text-right'>
                                <label>Category:</label>

                                <div className={styles.SelectWrapper}>
                                    <select
                                        value={leaveType}
                                        onChange={(e) => setLeaveType(e.target.value)}
                                        name='Type'
                                        placeholder="Select Leave Category"
                                        className={`${styles.customTimePicker} px-5 py-1`}
                                    >
                                        <option value="PAID">Paid</option>
                                        <option value="UNPAID">Unpaid</option>
                                    </select>
                                    <span className={styles.DropdownIcon}> <AiOutlineDown /></span>
                                </div>
                            </div> */}

              <div className={` flex justify-between mb-[20px] items-center`}>
                <div className="text-left">
                  <label>Time:</label>
                </div>

                <div
                  className={`flex justify-between text-right items-center w-[70%]`}
                >
                  <input
                    name="startTime"
                    type="time"
                    placeholder="Start Time"
                    className={`${styles.customTimePicker} px-3 py-1 mx-2 w-[100%]`}
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    disabled={leaveDuration == "FULL" ? "disabled" : ""}
                  />
                  <input
                    name="endTime"
                    placeholder="End Time"
                    type="time"
                    className={`${styles.customTimePicker} px-3 py-1 w-[100%]`}
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    disabled={leaveDuration == "FULL" ? "disabled" : ""}
                  />
                </div>
              </div>

              <div className=" flex justify-between text-right items-center mb-8">
                <label>Type:</label>

                <div className={`${styles.SelectWrapper} w-[68%]`}>
                  <select
                    name="Type"
                    placeholder="Select Leave Time"
                    className={`${styles.customTimePicker} px-4 py-1 w-[100%]`}
                    value={leaveCategory}
                    onChange={(e) => setLeaveCategory(e.target.value)}
                  >
                    <option value="SicknessLeave(non-deductible)">
                      Sickness Leave (non-deductible) &nbsp;
                    </option>
                    <option value="CompassionateLeave(deductible)">
                      Compassionate Leave (deductible) &nbsp;&nbsp;
                    </option>
                    <option value="UnpaidLeave(non-deductible)">
                      Unpaid Leave (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="BereavementLeave(deductible)">
                      Bereavement Leave (deductible) &nbsp;&nbsp;
                    </option>
                    <option value="ParentalLeave(deductible)">
                      Parental Leave (deductible) &nbsp;&nbsp;
                    </option>
                    <option value="AdoptiveLeave(non-Deductible)">
                      Adoptive Leave (non-Deductible) &nbsp;&nbsp;
                    </option>
                    <option value="CarerLeave(non-deductible)">
                      Carer's Leave (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="JuryService(non-deductible)">
                      Jury Service (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="StudyLeave(non-deductible">
                      Study Leave (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="HospitalAppointmentLeave(non-deductible)">
                      Hospital Appointment Leave (Non deductible) &nbsp;&nbsp;
                    </option>
                    <option value="DentalAppointmentLeave(non-deductible)">
                      Dental Appointment Leave (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="MaternityLeave(non-deductible)">
                      Maternity Leave (non-deductible) &nbsp;&nbsp;
                    </option>
                    <option value="AnnualLeave(deductible)">
                      Annual Leave (deductible) &nbsp;&nbsp;
                    </option>
                  </select>
                  <span className={styles.DropdownIcon}>
                    {" "}
                    <AiOutlineDown />
                  </span>
                </div>
              </div>

              <div className={`${styles.Button} text-center pt-12`}>
                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Mark Leave
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManualLeave;
