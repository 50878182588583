import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Form, Input, Select, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateOwnProfile, loadSingleStaff, clearUser } from '../../redux/rtk/features/user/userSlice';
import { useParams } from 'react-router-dom';

const UserProfile = ({ }) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users?.user);
    const { id } = useParams();


    useEffect(() => {
        dispatch(loadSingleStaff(id));
    }, [id]);


    const handleSubmit = async (values) => {
        setLoader(true);
        console.log(values, 'admin profile')
        try {
            const resp = await dispatch(
                updateOwnProfile({
                    id: id,
                    values: {
                        ...values,
                    },
                })
            );

            if (resp.payload.message === 'success') {
                dispatch(clearUser());
                dispatch(loadSingleStaff(id));
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoader(false);
        }
    };
    const [form] = Form.useForm();

    return (
        <>
            <h2 style={{ fontSize: '22px', fontWeight: 'bold', color: '#000' }} className="p-3">
                <span>  Edit Profile</span>
            </h2>
            <Card className='ant-shadow rounded-[15px] xl:w-[50%] lg:w-[50%] md:w-full sm:w-full xsm:w-full xxs:w-full xxxs:w-full mx-auto' bordered={false}>
                <Row justify={"center"}>
                    <Col span={24} sm={24}>
                        {
                            user == null
                                ?
                                <h4 className="text-center">Loading.....</h4>
                                :
                                <Form
                                    layout="vertical"
                                    size="large"
                                    name="basic"
                                    form={form}
                                    // labelCol={{
                                    //     span: 15,
                                    // }}
                                    // wrapperCol={{
                                    //     span: 34,
                                    // }}
                                    initialValues={{
                                        firstName: user?.firstName ? user?.firstName : "-",
                                        lastName: user?.lastName ? user?.lastName : "-",
                                        email: user?.email ? user?.email : "-",
                                        state: user?.state ? user?.state : "-",
                                        city: user?.city ? user?.city : "-",
                                        country: user?.country ? user?.country : "-",
                                    }}

                                    onFinish={handleSubmit}
                                    autoComplete='off'
                                >
                                    <Row gutter={[30,0]}>
                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label='First Name:'
                                                name='firstName'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input First Name!",
                                                    },
                                                ]}>
                                                <Input size="large" placeholder='John' />
                                            </Form.Item>

                                        </Col>
                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label='Last Name:'
                                                name='lastName'
                                                initialValue={user?.lastName || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input Last Name!",
                                                    },
                                                ]}>
                                                <Input size="large" placeholder='deo' />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label=' Email:'
                                                name='email'
                                                initialValue={user?.email || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input email!",
                                                    },
                                                ]}>
                                                <Input placeholder='Email' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label='City:'
                                                name='city'
                                                initialValue={user?.city || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input city!",
                                                    },
                                                ]}>
                                                <Input placeholder='City' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label='County:'
                                                name='state'
                                                initialValue={user?.state || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input state",
                                                    },
                                                ]}>
                                                <Input placeholder='State' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} sm={12}>
                                            <Form.Item
                                                style={{ marginBottom: "10px" }}
                                                label=' Country:'
                                                name='country'
                                                initialValue={user?.country || ''}

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input country!",
                                                    },
                                                ]}>
                                                <Input placeholder='Country' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} sm={24}>
                                            <Form.Item className='text-center' style={{ marginBottom: '10px', marginTop: '10px' }}>
                                                <Button
                                                    className="mt-5 bg-[#E42976] text-white border-0"
                                                    onClick={() => setLoader(true)}
                                                    type='submit'
                                                    shape='round'
                                                    htmlType='submit'
                                                    size='large'
                                                    loading={loader}
                                                >
                                                    Update Profile
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default UserProfile;
