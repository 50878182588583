import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Upload,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addSingleProjectTasks,
  UploadUserImage,
} from "../../../redux/rtk/features/projectManagement/project/projectTask/projectTask";
import { useSelector } from "react-redux";
import {
  loadAllProject,
  loadSingleProject,
} from "../../../redux/rtk/features/projectManagement/project/project/project";
import { loadAllTaskPriority } from "../../../redux/rtk/features/projectManagement/project/taskPriority/taskPriority";
import {
  loadAllTaskStatus,
  loadAllTaskStatusByProjectId,
} from "../../../redux/rtk/features/projectManagement/project/taskStatus/taskStatus";
import BigDrawer from "../../Drawer/BigDrawer";
import AddTaskPriority from "../../project/taskPriority/AddtaskPriority";
import AddMilestone from "../../project/milestone/AddMilestone";
import {
  loadAllMilestone,
  loadAllMilestoneByProjectId,
} from "../../../redux/rtk/features/projectManagement/project/milestone/milestone";
import AddProjectTeam from "../../project/team/AddProjectTeam";
import {
  loadAllProjectTeamByProjectId,
  loadSingleTaskTeam,
} from "../../../redux/rtk/features/projectManagement/project/projectTeam/projectTeam";
import PageTitle from "../../page-header/PageHeader";
import { UploadOutlined } from "@ant-design/icons";
// import { loadSingleStaff } from "../../../redux/rtk/features/user/userSlice";
// import { addEducation } from "../../education/educationApis";
import {
  getDepartments,
  loadSingleDepartment,
} from "../../department/departmentApis";

const AddTask = ({ projectId, taskStatusId }) => {
  const list = useSelector((state) => state.users.list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const { project } = useSelector((state) => state.project);
  const taskStatus = useSelector((state) => state.taskStatus.list);
  const taskPriority = useSelector((state) => state.taskPriority.list);
  const taskTeamList = useSelector((state) => state.projectTeam.taskTeam);
  const milestoneList = useSelector((state) => state.milestone.list);
  const [teamUserList, setTeamUserList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [departmentStaff, setDepartmentStaff] = useState([]);
  const [allUser, setAllUser] = useState();
  const [selectedItems, setSelectedItems] = useState([]);

  const { id } = useParams("id");
  const user_id = localStorage.getItem("id");
  const dispatch = useDispatch();
  const [Attachment, setAttachment] = useState([]);
  // useEffect(() => {
  // 	if (project) {
  // 		setProjectTeamList(project?.projectTeam);
  // 		console.log(project?.projectTeam);
  // 		setMilestoneList(project?.milestone);
  // 	}
  // }, [project]);

  useEffect(() => {
    // dispatch(loadSingleProject(projectId));
    // dispatch(loadAllMilestoneByProjectId(projectId));
    // dispatch(loadAllProjectTeamByProjectId(projectId));
    dispatch(loadAllTaskPriority());
    dispatch(loadSingleTaskTeam(user_id));
  }, []);

  useEffect(() => {
    getDepartments().then((res) => {
      setDepartment(res.concat([{ id: 0, name: "All Departments" }]));
    });
  }, []);

  const AttachmentData = new FormData();

  const onFinish = async (values) => {
    if (startDate > endDate) {
      toast.error("Your end date is less then start date!!!");
      setLoader(false);
      return;
    }
    const infodata = {
      ...values,
      assignedBy: parseInt(user_id),
      completionTime: parseFloat(values.completionTime),
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      userId: selectedItems,
    };

    if (Attachment[0]?.originFileObj) {
      AttachmentData.append("adminattachment", Attachment[0]?.originFileObj);
      setLoader(true);
      const resp = await dispatch(
        UploadUserImage({
          imgData: AttachmentData,
          infodata: infodata,
          value: "AddTask",
        })
      );
      if (resp.payload.message === "success") {
        setLoader(false);
        setAttachment([]);
        form.resetFields();
      } else {
        setLoader(false);
      }
    } else {
      setLoader(true);
      const resp = await dispatch(addSingleProjectTasks(infodata));
      if (resp.payload.message === "success") {
        setLoader(false);
        form.resetFields();
      } else {
        setLoader(false);
      }
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding task");
    setLoader(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setstartDate(dayjs());

    setendDate(dayjs());
    setIsModalOpen(false);
    setLoader(false);

    form.resetFields();
  };

  const handleDepartmentChange = (value) => {
    if (value === 0) {
      setDepartmentStaff(list);
    } else {
      loadSingleDepartment(value).then((res) => {
        setDepartmentStaff(res?.data?.user);
      });
    }
  };

  const handleSelectAll = () => {
    const allItemValues = departmentStaff.map((option) => option.id);
    setSelectedItems(allItemValues);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <PageTitle title="Back" />

      <div className="card border-0 ant-shadow mt-4">
        <div className="ant-card-body relative">
          <div className="p-[5px] bg-[#EFF1F9] text-black rounded-[10px] text-center mb-4">
            <h4 className="text-[24px] font-[600]">Add Task</h4>
          </div>
          <Form
            form={form}
            style={{ marginBottom: "20px" }}
            eventKey="department-form"
            name="basic"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div>
              <div className="flex justify-center">
                {/* <div className="flex justify-around ml-5 mr-10">
                <span className="font-semibold mr-2 mt-1">Milestone :</span>
                <Form.Item style={{ marginBottom: "10px" }} name="milestoneId">
                  <Select
                    className="mr-2"
                    loading={milestoneList?.length === 0}
                    placeholder="Select Milestone"
                    mode="single"
                    style={{ width: "160px" }}
                  >
                    {milestoneList.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <BigDrawer
                  children={
                    <AddMilestone isFixed={true} projectId={projectId} />
                  }
                  title={"Add New Milestone"}
                />
              </div> */}

                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  name="taskStatusId"
                  tooltip="Select Task Status"
                  rules={[
                    {
                      required: true,
                      message: "Please input your taskStatus!",
                    },
                  ]}
                >
                  <Select
                    className="mr-2"
                    placeholder="Select Task Status"
                    mode="single"
                    style={{ width: "150px" }}
                  >
                    {taskStatus?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
              </div>

              {/* <Form.Item
              style={{ marginBottom: "10px" }}
              label="Team Select"
              name="teamSelect"
            >
              <div className="flex">
                <Select
                  className="mr-2"
                  placeholder="Select Team"
                  loading={projectTeamList?.length === 0}
                  onChange={(value) => {
                    projectTeamList.map((item) => {
                      if (item.id === value) {
                        // make object for setTeamUserList
                        const projectTeamMember = item.projectTeamMember.map(
                          (item) => {
                            return {
                              userId: item.userId,
                              user: item.user,
                            };
                          }
                        );
                        setTeamUserList(projectTeamMember);
                      }
                    });
                  }}
                  mode="single"
                >
                  {projectTeamList.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.projectTeamName}
                    </Select.Option>
                  ))}
                </Select>
                <BigDrawer
                  children={<AddProjectTeam projectId={projectId} />}
                  projectId={projectId}
                />
              </div>
            </Form.Item> */}
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Task Name"
                rules={[
                  {
                    required: true,
                    message: "Please input task name!",
                  },
                ]}
                name="name"
              >
                <Input placeholder="Task Name" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Select Department:"
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: "Department Required!",
                  },
                ]}
              >
                <Select
                  onChange={handleDepartmentChange}
                  placeholder="Select Department"
                  style={{
                    border: "none",
                  }}
                >
                  {department &&
                    department
                      .slice()
                      .reverse()
                      ?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Select Team Member"
                rules={[
                  {
                    required: true,
                    message: "Please Select Team!",
                  },
                ]}
                // name="userId"
              >
                <div>
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked ? handleSelectAll() : handleDeselectAll()
                    }
                  >
                    Select All Team
                  </Checkbox>
                  <Select
                    allowClear
                    loading={list === 0}
                    mode="multiple"
                    placeholder="Select Team!"
                    maxTagCount="responsive"
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                    value={selectedItems}
                    onChange={(values) => setSelectedItems(values)}
                  >
                    {departmentStaff &&
                      departmentStaff?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option?.firstName + " " + option.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Start Date"
                name="startDate"
                valuePropName="startDate"
                rules={[
                  {
                    required: true,
                    message: "Please input your start date!",
                  },
                ]}
              >
                <DatePicker onChange={(date) => setstartDate(date)} />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: "10px" }}
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Please input your end date!",
                  },
                ]}
                name="endDate"
                valuePropName="endDate"
              >
                <DatePicker onChange={(date) => setendDate(date)} />
              </Form.Item>

              <Form.Item
                  style={{ marginBottom: "10px" }}
                  label="Completion Times"
                  name="completionTime"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please input your Completion Time!",
                    },
                    {
                      validator: (_, value) =>
                          value > 0 ? Promise.resolve() : Promise.reject(new Error("Completion Time must be greater than zero!")),
                    },
                    {
                      validator: (_, value) => {
                        if (!value || /^(\d+)?(\.\d+)?$/.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please enter a valid number (e.g., 20.00)"));
                      },
                    },
                  ]}
              >
                <Input
                    placeholder="20.00 in Hours"
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (!/^(\d+)?(\.\d+)?$/.test(value)) {
                        e.target.value = ""; // Clear the input if it's invalid
                      }
                    }}
                />
              </Form.Item>


              <Form.Item
                style={{ marginBottom: "10px" }}
                name="priorityId"
                label={"Priority"}
                // tooltip="Select Priority"
                rules={[
                  {
                    required: true,
                    message: "Please input your priority!",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  placeholder="Select Priority"
                  loading={taskPriority?.length === 0}
                  mode="single"
                  // style={{ width: "540px" }}
                >
                  {taskPriority?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <BigDrawer
                  children={<AddTaskPriority />}
                  title={"Add New Task Priority"}
                /> */}
              <Form.Item
                style={{ marginBottom: "10px" }}
                label="Description"
                name="description"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input your description!",
                //   },
                // ]}
              >
                <Input.TextArea placeholder="Description (optional)" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "20px" }}
                label="Attachment (optional)"
                name="adminattachment"
              >
                <Upload
                  fileList={Attachment}
                  onChange={(e) => setAttachment(e.fileList)}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "20px" }}
                wrapperCol={{
                  xs: { offset: 0, span: 16 },
                  sm: { offset: 0, span: 16 },
                  md: { offset: 6, span: 16 },
                  lg: { offset: 6, span: 16 },
                  xl: { offset: 6, span: 16 },
                }}
              >
                <Button
                  block
                  onClick={() => setLoader(true)}
                  type="submit"
                  className="bg-[#E42976] text-white border-0"
                  size="middle"
                  htmlType="submit"
                  loading={loader}
                >
                  Add Now
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddTask;
