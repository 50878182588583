import { Card, Col, Row, Typography, Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import styles from "./meeting.module.css";
import { Calendar } from "react-date-range";
import MeetingTeamDetails from "./MeetingTeamDetails";
import AllMeetingList from "./AllMeetingList";
import { loadAllMeeting } from "../../redux/rtk/features/Meeting/MeetingSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineSearchOff } from "react-icons/md";

const AllMeetings = () => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const [selectedDate, setSelectedDate] = useState()
  function handleSelect(date) {
    setSelectedDate(dayjs(date).format("DD-MM-YYYY"))
    if (date) {
      const data = `meeting/?date=${dayjs(date).format("YYYY-MM-DD")}`
      dispatch(loadAllMeeting(data));
    }
  }
  useEffect(() => {
    const data = `meeting/`
    dispatch(loadAllMeeting(data));
  }, []);

  const ClearFilter = () => {
    const data = `meeting/`
    dispatch(loadAllMeeting(data));
    setSelectedDate('')
  }
  return (
    <>
      <Row gutter={16}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
        >
          <Card
            className={`${styles.Card} rounded-[20px] ant-shadow`}
            bordered={false}
          >
            <Title level={4} className={`${styles.Title} m-2 text-center py-2`}>
              Choose Date
            </Title>
            <Row gutter={16} className="mb-8">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
              >
                {/* <MeetingTeamDetails open={userModal} onclose={setUserModal} /> */}
                <div className={`block text-center`}>
                  <Calendar
                    onChange={(e) => handleSelect(e)}
                    date={new Date()}
                    className={`rounded-[20px] ant-shadow border-0 text-center mt-3 p-3 mb-4 mr-0`}
                  />
                  <div className="flex justify-center mb-4">
                    <Button
                      style={{ width: "30%" }}
                      className="bg-[#FF0000] text-[16px] font-500 capitalize gap-2 rounded flex justify-center items-center text-white hover:text-white border-0 hover:border-0"
                      size="large"
                      shape="round"
                      onClick={ClearFilter}>
                      <MdOutlineSearchOff size={22} />
                      clear
                    </Button>
                  </div>
                  <h2 className="text-base font-500 capitalize txt-color-2">
                   {selectedDate && "Selected Date" + " : " + selectedDate}
                  </h2>
                </div>
              </Col>
            </Row>

            <Title level={4} className={`${styles.Title} m-2 text-center py-2`}>
              All Meetings
            </Title>
            <AllMeetingList />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllMeetings;
