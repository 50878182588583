import { Button, Card, Table, Pagination, Input, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { deleteRolePermission } from "./roleApis";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const CustomTable = ({ role }) => {
  const [keys, setKeys] = useState([]);
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;
  const { Search } = Input;
  const [searchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState(role);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "permission",
      key: "permission",
      render: ({ name } = {}) => name,
    },
    {
      id: 3,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      id: 4,
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => dayjs(updatedAt).format("DD/MM/YYYY"),
    },
  ];


  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = role.filter((record) =>
      record && record?.permission?.name && record?.permission?.name.toLowerCase().includes(value.toLowerCase())
    );
    setDataSource(filteredData);
  };

  const handleReset = () => {
    setSearchText('');
    setDataSource(role);
  };

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setKeys(selectedRowKeys);
    },
  };
  const [loader, setLoader] = useState(false);

  const onDelete = async () => {
    setLoader(true);
    try {
      const data = await deleteRolePermission(keys);
      if (data.message === "success") {
        window.location.reload();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };
  // Pagination configuration
  const paginationConfig = {
    current: currentPage, // Use your current page state
    pageSize: 10, // Number of items per page
    total: role.length, // Total number of items
    showSizeChanger: false, // Hide the "Show X items per page" dropdown
    showQuickJumper: false, // Hide the quick jump input
    onChange: (page) => {
      // Handle page change
      setCurrentPage(page);
    },
  };

  return (
    <>
      {/* <Card className='card-body mb-3 '>
			
		</Card> */}
      <div className="table-responsive">
        <div className={"flex justify-between items-center"}>
          {/* <div className="flex justify-start">
						{columns.length > 0 && (
							<div style={{ marginBottom: "30px" }}>
								<ColVisibilityDropdown
									options={columns}
									columns={columns}
									columnsToShowHandler={columnsToShowHandler}
								/>
							</div>
						)}
					</div>*/}
          <h4 className="text-center mb-2 text-2xl"> Permissions</h4>
          <div className="flex justify-end items-center gap-2" style={{ marginBottom: 16 }}>
            <Search
              className="role-Permissions-search"
              placeholder="Search permissions name"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 300 }}
            />
            {/* <Button type="primary" onClick={() => handleSearch(searchText)}>
          Search
        </Button> */}
            <Button type="button" className="bg-[#E42976] text-white border-0" onClick={handleReset}>Reset</Button>
          </div>
          <div className={keys && keys.length > 0 ? "flex justify-end" : "hidden"}>
            {keys && keys.length > 0 && (
              <div className="text-start mb-1 ">
                <Button
                  className="mx-3"
                  type="danger"
                  onClick={onDelete}
                  loading={loader}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full overflow-auto">
          <Table
            rowSelection={columnsToShow.length > 0 && rowSelection}
            columns={columnsToShow}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            pagination={paginationConfig} // Pass the pagination configuration here
          />
        </div>
        {/* 	<Pagination
					className="mt-3 flex justify-end"
					current={currentPage}
					pageSize={pageSize}
					total={role ? role.length : 0} // Update the total with your data
					onChange={(page) => setCurrentPage(page)}
					showSizeChanger={false}
				/> */}
        {/* 	<Table
					rowSelection={columnsToShow.length > 0 && rowSelection}
					columns={columnsToShow}
					dataSource={role}
					pagination={{
						pageSize: 20,
						showSizeChanger: true,
						pageSizeOptions: ["10", "20", "50"],
					}}
					rowKey={(record) => record.id}
				/> */}
        {/* <table className='table '>
					<thead className='thead-dark'>
						<tr>
							<th scope='col'>#ID</th>
							<th scope='col'>Permission Name</th>
							<th scope='col'>Created AT</th>
							<th scope='col'>Updated AT</th>
						</tr>
					</thead>
					<tbody>
						{role &&
							role.map((i) => (
								<tr>
									<th scope='row'>{i.id}</th>
									<td>{i.permission.name}</td>
									<td>{dayjs(i.createdAt).format("YYYY-MM-DD")}</td>
									<td>{dayjs(i.updatedAt).format("YYYY-MM-DD")}</td>
								</tr>
							))}
					</tbody>
				</table> */}
      </div>
    </>
  );
};

export default CustomTable;
