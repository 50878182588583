import { Card, Col, List, Row } from "antd";
import Loader from "../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	clearLeaveApplication,
	loadSingelLeaveApplication,
	LeaveApplicationStatus, leaveCountHistory
} from "../../redux/rtk/features/leave/leaveSlice";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import styles from './leave.module.css';
import React, { useState } from 'react';
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { loadSingleStaff } from "../../redux/rtk/features/user/userSlice";
import tw from "tailwind-styled-components";

const MyLeaveDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams("id");
  const leave = useSelector((state) => state.leave.leave);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leaveCountHistory());
  }, []);

  useEffect(() => {
    dispatch(loadSingelLeaveApplication(id));
    return () => {
      dispatch(clearLeaveApplication());
    };
  }, []);

  return (
    <div>
      <PageTitle title="Back" />

      <UserPrivateComponent permission={"readSingle-leaveApplication"}>
        <div className={styles.container}>
          <Card className={styles.cardStyle}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className={`${styles.title} text-center mb-4`}>
                  <h2>My Application Details</h2>
                </div>
                {leave ? (
                  <>
                    <List className="list-inside list-none border-none px-5  ">
                      <div className="flex justify-between bg-[#F3F4F6] mb-1 ">
                        <ListItem>
                          Emp ID :{" "}
                          <TextInside>{leave?.user.employeeId}</TextInside>
                        </ListItem>
                        <ListItem>
                          Emp Name :{" "}
                          <TextInside>
                            {(
                              leave?.user.firstName +
                              " " +
                              leave?.user.lastName
                            ).toUpperCase()}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave Type :{" "}
                          <TextInside>{leave.leaveType}</TextInside>
                        </ListItem>
                        {/* <ListItem>
													Leave Category : <TextInside>{leave.leaveType}</TextInside>
												</ListItem> */}
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave From :{" "}
                          <TextInside>
                            {dayjs(leave.leaveFrom).utc().format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          Leave To :{" "}
                          <TextInside>
                            {dayjs(leave.leaveTo).utc().format("DD-MM-YYYY")}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Duration :{" "}
                          <TextInside>
                            {leave?.leaveDuration == 0.5
                              ? "Half Day"
                              : leave?.leaveDuration > 1
                              ? leave?.leaveDuration + " Days"
                              : leave?.leaveDuration + " Day"}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Attachments :{" "}
                          <TextInside>
                            {leave.attachment == null ||
                            leave.attachment == "null" ? (
                              "No Attachment"
                            ) : (
                              <>
                                <a
                                  href={leave.attachment}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-500"
                                >
                                  Download
                                </a>
                                &nbsp; <FaDownload className={styles.color} />
                              </>
                            )}
                          </TextInside>
                        </ListItem>
                      </div>

                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave Status :{" "}
                          <TextInside>
                            {leave.status === "pending" ? (
                              <span className="text-yellow-500">
                                {leave.status.toUpperCase()}
                              </span>
                            ) : leave.status === "accepted" ? (
                              <span className="text-green-500">
                                {leave.status.toUpperCase()}
                              </span>
                            ) : (
                              <span className="text-red-500">
                                {leave.status.toUpperCase()}
                              </span>
                            )}
                          </TextInside>
                        </ListItem>

                        <ListItem>
                          Leave Accepted By :{" "}
                          <TextInside>
                            {(leave.acceptLeaveBy?.firstName || "ON") +
                              " " +
                              (leave.acceptLeaveBy?.lastName || "REVIEW")}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className="flex justify-between bg-[#F3F4F6] mb-1">
                        <ListItem>
                          Leave Accepted From :{" "}
                          <TextInside>
                            {leave.acceptLeaveFrom
                              ? dayjs(leave.acceptLeaveFrom).format(
                                  "DD-MM-YYYY"
                                )
                              : "ON REVIEW"}
                          </TextInside>
                        </ListItem>
                        <ListItem>
                          Leave Accepted To :{" "}
                          <TextInside>
                            {leave.acceptLeaveTo
                              ? dayjs(leave.acceptLeaveTo).format("DD-MM-YYYY")
                              : "ON REVIEW"}
                          </TextInside>
                        </ListItem>
                      </div>
                      <div className=" bg-[#F3F4F6] text-left p-3">
                        <strong style={{ color: "#47515E" }}>
                          Leave Note:
                        </strong>
                        <p>{leave.reason ? leave.reason : "No Comments"}</p>
                      </div>
                    </List>
                  </>
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </UserPrivateComponent>
    </div>
  );
};
const ListItem = tw.li`
  text-sm
  text-gray-600
  font-semibold
  py-2
  px-2
  bg-gray-100
  mb-1.5
  rounded
  w-96
  flex
  justify-start
  text-left

`;

const TextInside = tw.p`
  ml-2
  text-sm
  text-gray-900
`;
export default MyLeaveDetails;

