import React from "react";
import { Modal } from "antd";
import MarkAttendance from "../../attendance/MarkAttenddance";
import Attendance from "../../attendance/AddAttendance";
/* const modalContainerStyles = {
  backgroundColor: 'gray', // Change this to your desired background color
  boxShadow: '0px 0px 6px #C3C3C3',
  borderRadius: "50px",
 
}; */

const MarkAttendanceModal = ({ visible, onClose, onSearch }) => {
  return (
      <Modal
        visible={visible}
        footer={null}
        onCancel={onClose}
        destroyOnClose
        centered
        className="markAttendance"
      >
      <MarkAttendance onSearch={onSearch}   onCancel={onClose}/>
      </Modal>
  );
};

export default MarkAttendanceModal;
