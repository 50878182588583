import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteEmploymentStatus } from "../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

const EmploymentStatusDelete = ({ id }) => {
	const[EmpStatus, setEmpStatus] = useState(false)
	// const dispatch = useDispatch();
	// const navigate = useNavigate();

	// const onDelete = () => {
	// 	try {
	// 		dispatch(deleteEmploymentStatus(id));
	// 		navigate("/admin/employment-status/");
	// 	} catch (error) {
	// 		console.log(error.message);
	// 	}
	// };

	return (
		<>
		<button type='primary' onClick={()=>setEmpStatus(true)} className='mr-3 ml-2'>
			<MdDelete size={25} color="#FF0000" />
		</button>
		<DelModal
        open={EmpStatus}
        onClose={setEmpStatus}
        EmpStatusId={id}
        content={"Are you sure to delete this user?"}
        type={"EmpStatus"}
      />
		</>
	);
};
export default EmploymentStatusDelete;
