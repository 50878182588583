import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  Switch,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSingleRoom,
  updateRoom,
} from "../../redux/rtk/features/rooms/roomSlice";
import { toast } from "react-toastify";
import { loadAllLocation } from "../../redux/rtk/features/location/locationSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";
import { Handler } from "leaflet";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function UpdateRoomModal() {
  const { id } = useParams();
  const location = useSelector((state) => state.location.list);
  const singleList = useSelector((state) => state.Rooms.singleRoom.data);
  const user_id = localStorage.getItem("id");
  const [loader, setLoader] = useState(false);
  const { Title } = Typography;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [RoomLoc, setroomLoc] = useState(singleList?.location);
  const [roomName, setroomName] = useState(singleList?.roomName);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(loadSingleRoom(id));
    dispatch(loadAllLocation({ status: "true" }));
  }, [id]);

  useEffect(() => {
    setroomLoc(singleList?.location);
    setroomName(singleList?.roomName);
  }, [singleList]);

  const onFinish = async (values) => {
    if (roomName == "") {
      toast.error("Room Name is required!");
      setLoader(false);
      return;
    }
    const value = {
      roomName: roomName,
      locationId: RoomLoc?.id,
      userId: user_id,
    };
    setLoader(true);
    const resp = await dispatch(updateRoom({ id: id, checked: value }));

    if (resp.payload.message === "success") {
      setLoader(false);
      navigation("/all/rooms");
    } else {
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
    setLoader(false);
  };

  const HandleRoomChnage = (value, option) => {
    setroomLoc(option.data);
  };

  return (
    <>
      <PageTitle title="Back" />
      <div className="card ant-shadow rounded-[15px] mr-0 mt-4">
        <div className="ant-card-body">
          <div
            style={{
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
              borderRadius: "10px",
              marginBottom: "2rem",
            }}
          >
            <h2 className="text-center font-[700] text-[1.5rem]">
              Update Room
            </h2>
          </div>

          <Form
            style={{ marginBottom: "40px" }}
            eventKey="room-form"
            name="basic"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="mx-[25px]">
              <Select
                className="border-none ant-shadow mb-2"
                placeholder="Select Location"
                loading={location?.length === 0}
                value={RoomLoc?.locationName}
                onChange={HandleRoomChnage}
              >
                {location &&
                  location.map((item) => (
                    <Select.Option key={item.id} value={item.id} data={item}>
                      {item.locationName}
                    </Select.Option>
                  ))}
              </Select>

              <Input
                value={roomName}
                onChange={(e) => setroomName(e.target.value)}
                className="border-none ant-shadow mb-6"
                placeholder="Enter Room Name!"
              />

              <Form.Item
                labelCol={{
                  xs: { span: 6, offset: 0 },
                  sm: { span: 6, offset: 0 },
                  xl: { span: 6, offset: 9 },
                  lg: { span: 6, offset: 9 },
                  md: { span: 6, offset: 9 },
                }}
                wrapperCol={{
                  xs: { span: 6, offset: 0 },
                  sm: { span: 6, offset: 0 },
                  xl: { span: 6, offset: 9 },
                  lg: { span: 6, offset: 9 },
                  md: { span: 6, offset: 9 },
                }}
                style={{ marginBottom: "10px" }}
              >
                <UserPrivateComponent permission={"update-room"}>
                  <Button
                    onClick={() => setLoader(true)}
                    type="submit"
                    className=" bg-[#E42976] text-white border-0"
                    size="large"
                    htmlType="submit"
                    block
                    loading={loader}
                  >
                    Update
                  </Button>
                </UserPrivateComponent>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default UpdateRoomModal;
