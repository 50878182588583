import { useState } from "react";
import styles from "../../user/Login.module.css";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import moment from 'moment'; // Import moment
import dayjs from "dayjs";

import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Modal,
  Typography,
  Upload,
  InputNumber,
  Divider,
} from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getRoles,
  getSupervisionRoles,
  getReferenceRoles,
} from "../../role/roleApis";
import { LoaderIcon } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import {
  clearUser,
  loadSingleStaff,
  updateUser,
  UploadUserImage,
  DeleteUserdoc,
} from "../../../redux/rtk/features/user/userSlice";
import { toast } from "react-toastify";
import { getDepartments } from "../../department/departmentApis";
import { loadAllShift } from "../../../redux/rtk/features/shift/shiftSlice";
import { loadAllLocation } from "../../../redux/rtk/features/location/locationSlice";
import { UploadOutlined } from "@ant-design/icons";
import { loadAllLeavePolicy } from "../../../redux/rtk/features/leavePolicy/leavePolicySlice";
import { loadAllWeeklyHoliday } from "../../../redux/rtk/features/weeklyHoliday/weeklyHolidaySlice";
import { useParams } from "react-router-dom";
import companyLogo from "../../../assets/images/comlogo.png";
import { loadAllEmploymentStatus } from "../../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import DelModal from "../../UI/PopUp/DeleteWarningModal";

const ProfileEditPopup = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const employmentStatus = useSelector((state) => state.employmentStatus?.list);
  const leavePolicy = useSelector((state) => state.leavePolicy?.list);
  const weeklyHoliday = useSelector((state) => state.weeklyHoliday?.list);
  const shift = useSelector((state) => state.shift?.list);
  const location = useSelector((state) => state.location?.list);
  const user = useSelector((state) => state.users?.user);
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const { Title } = Typography;
  const { Option } = Select;
  const [list, setList] = useState(null);
  const [department, setDepartment] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [roleId, setRoleId] = useState("");
  const [refRoleId, setRefRoleId] = useState("");
  const [refTowId, setRefTwoId] = useState();
  const [supervisorRoleId, setSupervisorRoleId] = useState("");
  const [supervisorId, setSupervisorId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [leavePolicyId, setLeavePolicyId] = useState("");
  const [weeklyHolidayId, setWeeklyHolidayId] = useState("");
  const [ShowFile, setShowFile] = useState(false);
  const [secRef, setSecRef] = useState(false);
  const [visaStatus, setVisaStatus] = useState("");
  const [visaExpiry, setVisaExpiry] = useState("");
  const [dob, setDob] = useState("");
  const [emergencycontact, setEmergencyContact] = useState("");
  const [nicno, setNicno] = useState("");

  //const [file, setFile] = useState({});
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState({
    imagePreview: null,
    imageAsFile: null,
  });
  const [contractAttachment, setContractAttachment] = useState([]);
  const [firstaidAttachment, setFirstAidAttachment] = useState([]);
  const [dbsAttachment, setDBSAttachment] = useState([]);
  const [safeguardAttachment, setSafeguardAttachment] = useState([]);
  const [supervisorlist, setsupervisorlist] = useState("");
  const [employmentStatuslist, setEmploymentStatuslist] = useState("");
  const [delModal, setDelModal] = useState(false);
  const [isNc, setIsNc] = useState(false);
  const [docData, setDocData] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState("");


  useEffect(() => {

    getRoles()
      .then((d) => setList(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(loadAllShift());
    dispatch(loadAllLocation());
    dispatch(loadAllLeavePolicy());
    dispatch(loadAllWeeklyHoliday());
    dispatch(loadAllEmploymentStatus());
  }, []);

  useEffect(() => {
    dispatch(loadSingleStaff(id));
    setApplicationStatus(user.applicationStatus);
    setTimeout(() => {
      console.log(applicationStatus, "applicationStatus")
    }, 2000)
    setInitialValues({
      manualleave: user.annualallowedleave ? user.annualallowedleave : "",
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      userName: user.userName ? user.userName : "",
      email: user.email ? user.email : "",
      phone: user.phone ? user.phone : "",
      street: user.street ? user.street : "",
      // nicno: user.nicno ? user.nicno : "",
      city: user.city ? user.city : "",
      state: user.state ? user.state : "",
      zipCode: user.zipCode ? user.zipCode : "",
      country: user.country ? user.country : "",
      companyname: user.companyname ? user.companyname : "",
      emp_name: user.emp_name ? user.emp_name : "",
      emp_email: user.emp_email ? user.emp_email : "",
      emp_telno: user.emp_telno ? user.emp_telno : "",
      designation: user.designation ? user.designation : "",
      address: user.address ? user.address : "",
      visaStatus: user?.visaStatus ? user?.visaStatus : "",
      // visaExpiry: user?.visaExpiry ? dayjs(user?.visaExpiry) : "",
      emergencycontact: user?.emergencycontact ? user?.emergencycontact : "",
      nicno: user?.nicno ? user?.nicno : "",
      dob: user?.dob ? dayjs(user?.dob) : "",

      joining_date: user.joining_date ? moment(user.joining_date) : null,
      end_date: user.end_date ? moment(user.end_date) : null,
      companyname1: user.companyname1 ? user.companyname1 : "",
      designation1: user.designation1 ? user.designation1 : "",
      joining_date1: user.joining_date1 ? moment(user.joining_date1) : null,
      end_date1: user.end_date1 ? moment(user.end_date1) : null,
      address1: user.address1 ? user.address1 : "",
      emp_name1: user.emp_name1 ? user.emp_name1 : "",
      emp_email1: user.emp_email ? user.emp_email : "",
      emp_telno1: user.emp_telno1 ? user.emp_telno1 : "",
      // visaStatus: user.visaStatus ? user.visaStatus : "",
      visaExpiry: user.visaExpiry ? moment(user.visaExpiry) : "",
      applicationStatus: user.applicationStatus ? user.applicationStatus : "",
      //: dayjs(user.joinDate),
      leaveDate: user.leaveDate ? moment(user.leaveDate) : null,
      employeeId: user.employeeId ? user.employeeId : "",
      //	bloodGroup: user.bloodGroup ? user.bloodGroup : "",
      image: user.image ? user.image : "",
      roleId: user.roleId ? user.roleId : "",
      second_reference:
        user.superviser2 == null
          ? "-"
          : user.superviser2.firstName + " " + user.superviser2.lastName,
      superviser: user.superviser ? user.superviser : "",
      departmentId: user.departmentId ? user.departmentId : "",
      employmentStatusId: user?.employmentStatus?.name
        ? user?.employmentStatus?.name
        : "",
      shiftId: user.shiftId ? user.shiftId : "",
      locationId: user.locationId ? user.locationId : "",
      leavePolicyId: user.leavePolicyId ? user.leavePolicyId : "",
      weeklyHolidayId: user.weeklyHolidayId ? user.weeklyHolidayId : "",
      dbschecktext: user.dbschecktext ? user.dbschecktext : "",
    });
    setsupervisorlist(
      user?.superviser == null
        ? "-"
        : (user?.superviser?.firstName == null && undefined
          ? "-"
          : user?.superviser?.firstName) +
        " " +
        (user?.superviser?.lastName == null && undefined
          ? "-"
          : user?.superviser?.lastName)
    );
  }, [id]);

  useEffect(() => {
    getDepartments()
      .then((d) => setDepartment(d))
      .catch((error) => console.log(error));
  }, []);

  const [form] = Form.useForm();

  /* function handleFileChange(e) {
      setLoader(true); // Show the loader when a file is selected

    setFile({
      imagePreview: URL.createObjectURL(e.target.files[0]),
      imageAsFile: e.target.files[0],
    });
    setShowFile(true);
    setLoader(false); // Hide the loader when the image preview is ready

  } */

  function handleFileChange(e) {
    setLoader(true); // Show the loader when a file is selected

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Create an image preview
      const reader = new FileReader();
      reader.onload = (event) => {
        setFile({
          imagePreview: event.target.result,
          imageAsFile: selectedFile,
        });

        setLoader(false); // Hide the loader when the image preview is ready
        setShowFile(true); // Show the image when the preview is ready
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  const imgData = new FormData();

  const onFinish = async (values) => {
    try {
      if (values.roleId === 5 && (!values.second_reference || values.second_reference === '-')) {
        toast.error("Second Reference is required for this role.");
        return;
      }
      if (user) {
        if (
          file?.imagePreview ||
          contractAttachment[0]?.originFileObj ||
          firstaidAttachment[0]?.originFileObj ||
          dbsAttachment[0]?.originFileObj ||
          safeguardAttachment[0]?.originFileObj
        ) {
          imgData.append("image", file.imageAsFile);
          imgData.append(
            "contractAttachment",
            contractAttachment[0]?.originFileObj
          );
          imgData.append("firstaid", firstaidAttachment[0]?.originFileObj);
          imgData.append("dbscheck", dbsAttachment[0]?.originFileObj);
          imgData.append("safeguard", safeguardAttachment[0]?.originFileObj);
          setLoader(true);
          const resp = await dispatch(
            UploadUserImage({
              imgData: imgData,
              id: id,
              FormData: {
                ...values,
                employmentStatusId: employmentStatuslist
                  ? employmentStatuslist
                  : null,
                roleId: roleId ? roleId : data.roleId,
                reference_id: supervisorId ? supervisorId : null,
                referenceid_two: refTowId ? refTowId : null,
                departmentId: departmentId ? departmentId : data.departmentId,
                shiftId: shiftId ? shiftId : data.shiftId,
                locationId: locationId ? locationId : data.locationId,
                leavePolicyId: leavePolicyId
                  ? leavePolicyId
                  : data.leavePolicyId,
                weeklyHolidayId: weeklyHolidayId
                  ? weeklyHolidayId
                  : data.weeklyHolidayId,
              },
              value: "update",
            })
          );
          setLoader(true);
          if (resp.payload.message === "success") {
            setIsModalOpen(false);
            setLoader(false);
            dispatch(clearUser());
            dispatch(loadSingleStaff(id));
          } else {
            setLoader(false);
          }
        } else {
          const resp = await dispatch(
            updateUser({
              id: id,
              FormData: {
                ...values,
                employmentStatusId: employmentStatuslist
                  ? employmentStatuslist
                  : null,
                roleId: roleId ? roleId : data.roleId,
                reference_id: supervisorId ? supervisorId : null,
                referenceid_two: refTowId ? refTowId : null,
                departmentId: departmentId ? departmentId : data.departmentId,
                shiftId: shiftId ? shiftId : data.shiftId,
                locationId: locationId ? locationId : data.locationId,
                leavePolicyId: leavePolicyId
                  ? leavePolicyId
                  : data.leavePolicyId,
                weeklyHolidayId: weeklyHolidayId
                  ? weeklyHolidayId
                  : data.weeklyHolidayId,
              },
            })
          );
          setLoader(true);
          if (resp.payload.message === "success") {
            setIsModalOpen(false);
            setLoader(false);
            dispatch(clearUser());
            dispatch(loadSingleStaff(id));
          } else {
            setLoader(false);
          }
        }
      }
      // form.resetFields();
    } catch (error) {
      console.log(error.message);
      setLoader(false);
      toast.error("Something went wrong");
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoader(false);
    console.log("Failed:", errorInfo);
  };

  const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]; // blood groups

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setApplicationStatus(value)
  };

  const handleRoleChange = (value) => {
    setRoleId(value);
    console.log(value, "role")
    if(value == 5){
      setIsNc(true)
    }else{
      setIsNc(false)
    }
    console.log(isNc)
    getSupervisionRoles(value)
      .then((d) => {
        setSupervisorRoleId(d);
      })
      .catch((error) => console.log(error));
  };

  const firstNameChange = (va) => {
    console.log(va)
    console.log(applicationStatus, "applicationStatus")
  }
  const handleReferenceRoleChange = (value) => {
    setRefRoleId(value);
    getReferenceRoles(value)
      .then((d) => {
        setRefTwoId(d.singleRole[0]?.id);
      })
      .catch((error) => console.log(error));
  };

  const handlesupervisorId = (value) => {
    setSupervisorId(value);
  };

  var t = list?.filter(function (obj) {
    return obj.id == 4;
  })[0];

  const handleDeleteDoc = async (doc) => {
    if (doc) {
      setDocData(doc);
      setDelModal(true);
    }
    return;
    try {
      const resp = await dispatch(
        DeleteUserdoc({
          id: id,
          FormData: doc,
        })
      );
      setLoader(true);
      if (resp.payload.message === "success") {
        setIsModalOpen(false);
        setLoader(false);
        dispatch(clearUser());
        dispatch(loadSingleStaff(id));
      } else {
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button onClick={showModal}>
        <AiOutlineEdit size={30} className=" text-[#23B9F8]" />
      </button>
      <Modal
        footer={null}
        width={"50%"}
        title="Update Employee Information"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="global-modal-custom emp_profile_modal"
      >
        <Form
          size="small"
          form={form}
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <h2 className="text-center text-xl mt-3 mb-3 txt-color">
            User Information
          </h2> */}
          <div className={`flex justify-center ${styles.flexContainer}`}>
            {loader && (
              <div className={`${styles.loaderIcon}`}>
                <LoaderIcon style={{ fontSize: "32px" }} />
              </div>
            )}
            {ShowFile === true ? (
              <img
                name="file"
                src={file.imagePreview}
                className={`w-[150px] h-[150px] object-cover rounded-[50%] flex mb-4 justify-center`}
                alt="img"
              />
            ) : (
              <img
                name="file"
                src={initialValues?.image ? initialValues?.image : companyLogo}
                className="w-[150px] h-[150px] object-cover rounded-[50%] flex mb-4 justify-center"
                alt="img"
              />
            )}
          </div>
          {/*REJECTED*/}
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Application Status"
            name="applicationStatus"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input First Name!",
              },
            ]}
          >
            <Select
              size={"middle"}
              defaultValue={initialValues.applicationStatus}
              style={{ width: "100%" }}
              onChange={handleChange}
              options={[
                { value: "APPROVED", label: "APPROVED" },
                { value: "REJECTED", label: "REJECTED" },
              ]}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="First Name"
            name="firstName"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input First Name!",
              },
            ]}
          >
            <Input placeholder="John" onChange={firstNameChange} />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input Last Name!",
              },
            ]}
          >
            <Input placeholder="Doe" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="User Name"
            name="userName"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input User Name!",
              },
            ]}
          >
            <Input disabled placeholder="john_doe" />
          </Form.Item>

          {/* 	<Form.Item
						style={{ marginBottom: "10px" }}
						label='Password'
						name='password'
						rules={[
							{
								required: true,
								message: "Please input your password !",
							},
						]}>
						<Input placeholder='Strong Password' />
					</Form.Item>
 */}

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Email"
            name="email"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input email!",
              },
            ]}
          >
            <Input disabled placeholder="johndoe2@example.com" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Phone"
            name="phone"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input phone!",
              },
            ]}
          >
            <Input placeholder="03001234567" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Emergency Contact"
            name="emergencycontact"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input emegency contact!",
              },
            ]}
          >
            <Input placeholder="03001234567" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Visa Status"
            name="visaStatus"
          >
            <Input placeholder="Status" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Visa Expiry"
            name="visaExpiry"
          >
            <DatePicker className="date-picker hr-staffs-date-picker" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Image"
            name="image"
          >
            <div className="mb-8 ">
              <div>
                <input
                  style={{ width: "100%" }}
                  type="file"
                  className="edit-custom-file-input"
                  id="file"
                  name="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </Form.Item>

          <h2 className="text-center text-xl mt-3 mb-3 txt-color">
            Address Information
          </h2>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Street"
            name="street"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input street!",
              },
            ]}
          >
            <Input placeholder="Carisbrook Street" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="City"
            name="city"
            rules={[{
              required: applicationStatus == 'REJECTED' ? false : true,
              message: "Please input city!"
            }]}
          >
            <Input placeholder="Manchester" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="County"
            name="state"
            rules={[{
              required: applicationStatus == 'REJECTED' ? false : true,
              message: "Please input County!"
            }]}
          >
            <Input placeholder="Greater Manchester" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Post Code"
            name="zipCode"
            rules={[{
              required: applicationStatus == 'REJECTED' ? false : true,
              message: "Please input Post Code!"
            }]}
          >
            <Input placeholder="M9 5UX" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Country"
            name="country"
            rules={[{
              required: applicationStatus == 'REJECTED' ? false : true,
              message: "Please input Country!"
            }]}
          >
            <Input placeholder="United Kingdom" />
          </Form.Item>

          <h2 className="text-center text-xl mt-3 mb-3 txt-color">
            {" "}
            Employee Information{" "}
          </h2>
          {/* <Form.Item
						style={{ marginBottom: "10px" }}
						label='Joining Date'
						name='joinDate'
						valuePropName='date'
						rules={[
							{
								required: true,
								message: "Please input joining date!",
							},
						]}>
						<DatePicker
							className='date-picker hr-staffs-date-picker'
							defaultValue={initialValues.joinDate}
						/>
					</Form.Item> */}
          {/* <Form.Item
						style={{ marginBottom: "10px" }}
						label='Leave Date'
						name='leaveDate'
						valuePropName='leaveDate'>
						<DatePicker
							className='date-picker hr-staffs-date-picker'
							defaultValue={initialValues.leaveDate}
						/>
					</Form.Item> */}
          <Form.Item
            name={"employmentStatusId"}
            style={{ marginBottom: "10px" }}
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input Employment Status!",
              },
            ]}
            label="Employee Status"
          >
            <Select
              placeholder="Select Status"
              allowClear
              size={"middle"}
              defaultValue={initialValues.employmentStatuslist}
              onChange={(value) => setEmploymentStatuslist(value)}
            >
              {employmentStatus &&
                employmentStatus.map((employmentStatus) => (
                  <Option key={employmentStatus.id} value={employmentStatus.id}>
                    {employmentStatus.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Employee ID"
            name="employeeId"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input Employee ID!",
              },
            ]}
          >
            <Input placeholder="NI No" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="NI No"
            name="nicno"
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Please input NI No!",
              },
            ]}
          >
            <Input placeholder="NI No" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Date of Birth"
            name="dob"
          >
            <DatePicker className="date-picker hr-staffs-date-picker" />
          </Form.Item>
          {/* <Form.Item
						style={{ marginBottom: "10px" }}
						label='Blood Group'
						name='bloodGroup'
						rules={[
							{
								required: true,
								message: "Please input Blood Group!",
							},
						]}>
						<Select
							placeholder='Select Blood Group'
							allowClear
							defaultValue={initialValues.bloodGroup}
							mode='single'
							size='middle'
							style={{
								width: "100%",
							}}>
							{bloodGroups.map((bloodGroup) => (
								<Option key={bloodGroup} value={bloodGroup}>
									{bloodGroup}
								</Option>
							))}
						</Select>
					</Form.Item> */}
          {/* TODO: Add a Upload Seciton for Image */}

          <Form.Item
            name={"departmentId"}
            style={{ marginBottom: "10px" }}
            label="Department"
            rules={[{
              required: applicationStatus == 'REJECTED' ? false : true,
              message: "Please input Department!"
            }]}
          >
            <Select
              onChange={(value) => setDepartmentId(value)}
              placeholder="Select Department"
              allowClear
              size={"middle"}
              defaultValue={initialValues.departmentId}
            >
              {department &&
                department.map((department) => (
                  <Option key={department.id} value={department.id}>
                    {department.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Pleases Select Type!",
              },
            ]}
            label="Role"
            name={"roleId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={handleRoleChange}
              defaultValue={initialValues.roleId}
              loading={!list}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select Role"
            >
              {list &&
                list.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              supervisorRoleId?.singleRole?.length > 0
                ? {
                  required: true,
                  message: "Pleases Select Type!",
                }
                : {
                  required: false,
                  message: "Pleases Select Type!",
                },
            ]}
            label="Line Manager "
            name={"reference_id"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={handlesupervisorId}
              defaultValue={
                user?.superviser == null
                  ? "-"
                  : (user?.superviser?.firstName == null && undefined
                    ? "-"
                    : user?.superviser?.firstName) +
                  " " +
                  (user?.superviser?.lastName == null && undefined
                    ? "-"
                    : user?.superviser?.lastName)
              }
              loading={!list}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select Role"
            >
              {supervisorRoleId?.singleRole &&
                supervisorRoleId?.singleRole.map((data, index) => (
                  <Option key={data.id} value={data.id}>
                    {data.firstName + " " + data.lastName},{" " + data.email}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {data.roleId === 5 || isNc ? (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Pleases Select Type!",
                },
              ]}
              label="Second Reference"
              name={"second_reference"}
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleReferenceRoleChange}
                defaultValue={initialValues.second_reference}
                loading={!list}
                size="middle"
                mode="single"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select Second Reference"
              >
                {list &&
                  list
                    .filter((role) => role.id === 4)
                    .map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}

          {/* <Form.Item
            rules={[
              {
                required: true,
                message: "Pleases Select Type!",
              },
            ]}
            label="Shift"
            name={"shiftId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setShiftId(value)}
              defaultValue={initialValues.shiftId}
              loading={!shift}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select shift"
            >
              {shift &&
                shift.map((shift) => (
                  <Option key={shift.id} value={shift.id}>
                    {shift.name}
                  </Option>
                ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Pleases Select Type!",
              },
            ]}
            label="Location"
            name={"locationId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setLocationId(value)}
              defaultValue={initialValues.locationId}
              loading={!location}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select location"
            >
              {location &&
                location.map((location) => (
                  <Option key={location.id} value={location.id}>
                    {location.locationName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: applicationStatus == 'REJECTED' ? false : true,
                message: "Pleases Select Type!",
              },
            ]}
            label="Leave Policy"
            name={"leavePolicyId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setLeavePolicyId(value)}
              defaultValue={initialValues.leavePolicyId}
              loading={!leavePolicy}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select leavePolicy"
            >
              {leavePolicy &&
                leavePolicy.map((leavePolicy) => (
                  <Option key={leavePolicy.id} value={leavePolicy.id}>
                    {leavePolicy.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Annual Leave"
            name="manualleave"
            rules={[
              {
                required: false,
                type: "regexp",
                pattern: new RegExp(/\d+/g),
              },
            ]}
          >
            <InputNumber
              placeholder="Update Annual Leaves"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Add DBS Number"
            name="dbschecktext"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Add DBS Number" style={{ width: "100%" }} />
          </Form.Item>

          {/* <Form.Item
            rules={[
              {
                required: true,
                message: "Pleases Select Type!",
              },
            ]}
            label="Weekly Holiday"
            name={"weeklyHolidayId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setWeeklyHolidayId(value)}
              defaultValue={initialValues.weeklyHolidayId}
              loading={!weeklyHoliday}
              size="middle"
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select weekly Holiday"
            >
              {weeklyHoliday &&
                weeklyHoliday.map((weeklyHoliday) => (
                  <Option key={weeklyHoliday.id} value={weeklyHoliday.id}>
                    {weeklyHoliday.name}
                  </Option>
                ))}
            </Select>
          </Form.Item> */}

          <Form.Item
            style={{ marginBottom: "10px", width: "100%" }}
            label="Contract Document:"
          >
            <div className="flex justify-between items-center gap-3">
              <Upload
                fileList={contractAttachment}
                onChange={(e) => setContractAttachment(e.fileList)}
                beforeUpload={() => false}
              >
                <Button
                  size="medium"
                  className={
                    window.innerWidth < 600
                      ? `w-[250px]`
                      : window.innerWidth < 992
                        ? `w-[300px]`
                        : window.innerWidth <= 1100
                          ? `w-[250px]`
                          : `w-[380px]`
                  }
                  block
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
              {data?.contractAttachment && (
                <div>
                  <a
                    href={data?.contractAttachment}
                    target="_blank"
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <i className="bi bi-eye-fill"></i>
                  </a>
                </div>
              )}
              {data?.contractAttachment && (
                <div>
                  <button
                    type="button"
                    onClick={() =>
                      handleDeleteDoc({ contractAttachment: null })
                    }
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <MdDelete size={20} className="text-[#FF0000]" />
                  </button>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px", width: "100%" }}
            label="First Aid Document:"
          >
            <div className="flex justify-between items-center gap-3">
              <Upload
                fileList={firstaidAttachment}
                onChange={(e) => setFirstAidAttachment(e.fileList)}
                beforeUpload={() => false}
              >
                <Button
                  size="medium"
                  className={
                    window.innerWidth < 600
                      ? `w-[250px]`
                      : window.innerWidth < 992
                        ? `w-[300px]`
                        : window.innerWidth <= 1100
                          ? `w-[250px]`
                          : `w-[380px]`
                  }
                  block
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
              {data?.firstaid && (
                <div>
                  <a
                    href={data?.firstaid}
                    target="_blank"
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <i className="bi bi-eye-fill"></i>
                  </a>
                </div>
              )}
              {data?.firstaid && (
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteDoc({ firstaid: null })}
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <MdDelete size={20} className="text-[#FF0000]" />
                  </button>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px", width: "100%" }}
            label="DBS Check Document:"
          >
            <div className="flex justify-between items-center gap-3">
              <Upload
                fileList={dbsAttachment}
                onChange={(e) => setDBSAttachment(e.fileList)}
                beforeUpload={() => false}
              >
                <Button
                  size="medium"
                  className={
                    window.innerWidth < 600
                      ? `w-[250px]`
                      : window.innerWidth < 992
                        ? `w-[300px]`
                        : window.innerWidth <= 1100
                          ? `w-[250px]`
                          : `w-[380px]`
                  }
                  block
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
              {data?.dbscheck && (
                <div>
                  <a
                    href={data?.dbscheck}
                    target="_blank"
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <i className="bi bi-eye-fill"></i>
                  </a>
                </div>
              )}
              {data?.dbscheck && (
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteDoc({ dbscheck: null })}
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <MdDelete size={20} className="text-[#FF0000]" />
                  </button>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px", width: "100%" }}
            label="Safeguard Document:"
          >
            <div className="flex justify-between items-center gap-3">
              <Upload
                fileList={safeguardAttachment}
                onChange={(e) => setSafeguardAttachment(e.fileList)}
                beforeUpload={() => false}
              >
                <Button
                  size="medium"
                  className={
                    window.innerWidth < 600
                      ? `w-[250px]`
                      : window.innerWidth < 992
                        ? `w-[300px]`
                        : window.innerWidth <= 1100
                          ? `w-[250px]`
                          : `w-[380px]`
                  }
                  block
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
              {data?.safeguarding && (
                <div>
                  <a
                    href={data?.safeguarding}
                    target="_blank"
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <i className="bi bi-eye-fill"></i>
                  </a>
                </div>
              )}
              {data?.safeguarding && (
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteDoc({ safeguarding: null })}
                    className="text-[#23B9F8] text-lg font-bold"
                  >
                    <MdDelete size={20} className="text-[#FF0000]" />
                  </button>
                </div>
              )}
            </div>
          </Form.Item>
          <h2 className="text-center text-xl mt-3 mb-6 txt-color">
            Previous Employer (if Any)
          </h2>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Company Name"
            name="companyname"
          >
            <Input placeholder="Company Name" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Tel No"
            name="emp_telno"
            rules={[
              {
                required: false,
                type: "regexp",
                pattern: new RegExp(/\d+/g),
              },
              {
                pattern: /^[0-9]+$/,
                message: "Please enter only numbers.",
              },
            ]}
          >
            <Input placeholder="123456789" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Joining Date"
            name="joining_date"
          >
            <DatePicker className="date-picker hr-staffs-date-picker" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="End Date"
            name="end_date"
          >
            <DatePicker className="date-picker hr-staffs-date-picker" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Employer Name"
            name="emp_name"
          >
            <Input placeholder="Employer Name" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Job Title"
            name="designation"
          >
            <Input placeholder="Enter Job Title!" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Address"
            name="address"
          >
            <Input placeholder="Enter Address" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Employer Email"
            name="emp_email"
          >
            <Input type="email" placeholder="example@gmail.com" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              md: { span: 12, offset: 6 },
              lg: { span: 12, offset: 6 },
              xl: { span: 12, offset: 6 },
            }}
            style={{ marginBottom: "2rem", marginTop: "10px" }}
          >
            <Button
              className="mt-5 bg-[#E42976] text-white border-0 hover:border-[transparent] w-full flex justify-center items-center gap-2"
              size="large"
              fullWidth
              shape="round"
              onClick={() => setSecRef(!secRef)}
            >
              Add Another Employer{" "}
              {secRef == false ? (
                <FiPlusCircle size={20} />
              ) : (
                <FiMinusCircle size={20} />
              )}
            </Button>
          </Form.Item>

          <div
            className="mb-4"
            style={secRef == true ? { display: "block" } : { display: "none" }}
          >
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Company Name"
              name="companyname1"
            >
              <Input placeholder="Company Name" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Tel No"
              style={{ marginBottom: "10px" }}
              name="emp_telno1"
              rules={[
                {
                  required: false,
                  type: "regexp",
                  pattern: new RegExp(/\d+/g),
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="Tel No" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="End Date"
              style={{ marginBottom: "10px" }}
              name="end_date1"
            >
              <DatePicker
                placeholder="End Date"
                className="date-picker hr-staffs-date-picker"
              />
            </Form.Item>
            <Form.Item
              label="Employer Name"
              style={{ marginBottom: "10px" }}
              name="emp_name1"
            >
              <Input placeholder="Employer Name" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Job Title"
              style={{ marginBottom: "10px" }}
              name="designation1"
            >
              <Input placeholder="Enter Job Title!" />
            </Form.Item>
            <Form.Item
              label="Address"
              style={{ marginBottom: "10px" }}
              name="address1"
            >
              <Input placeholder="Enter Address" />
            </Form.Item>
            <Form.Item
              label="Employer Email"
              style={{ marginBottom: "10px" }}
              name="emp_email1"
            >
              <Input type="email" placeholder="example@gmail.com" />
            </Form.Item>
            <Form.Item
              label="Joining Date"
              style={{ marginBottom: "10px" }}
              name="joining_date1"
            >
              <DatePicker
                placeholder="Joining Date"
                className="date-picker hr-staffs-date-picker"
              />
            </Form.Item>
          </div>
          <Divider />

          <Form.Item
            style={{ marginBottom: "10px", marginTop: "10px" }}
            wrapperCol={{
              xs: { offset: 0, span: 16 },
              sm: { offset: 0, span: 16 },
              md: { offset: 4, span: 16 },
              lg: { offset: 4, span: 16 },
              xl: { offset: 4, span: 16 },
            }}
          >
            <Button
              className="mt-5 !h-[40px] bg-[#E42976] text-white border-0"
              block
              // onClick={() => setLoader(true)}
              type="submit"
              shape="round"
              htmlType="submit"
              loading={loader}
            >
              Update Employee
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <DelModal
        open={delModal}
        onClose={setDelModal}
        ProfileModal={setIsModalOpen}
        profileOpen={isModalOpen}
        Ddata={docData}
        docuserId={id}
        content={"are you sure to delete this document?"}
        type={"docdel"}
      />
    </>
  );
};
export default ProfileEditPopup;
