// LeaveStatsBarChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChartLeave = ({ transformedBarData }) => {
  console.log("transformedBarData", transformedBarData);

  return (
    <ResponsiveContainer height={150}>
      <BarChart data={transformedBarData} margin={{ top: 20, right: 0, left: 0, bottom: 2 }}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="approved" fill="#00B8AC" />
        <Bar dataKey="rejected" fill="#EB131C" />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartLeave;
