import { Card } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import dayjs from "dayjs";
import BtnLoader from "../loader/BtnLoader";
import tw from "tailwind-styled-components";

import {
	clearPublicHoliday,
	deletePublicHoliday,
	loadSinglePublicHoliday,
} from "../../redux/rtk/features/publicHoliday/publicHolidaySlice";
import PublicHolidayEdit from "../UI/PopUp/PublicHolidayEditPopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

const DetailPublicHoliday = () => {
	const { id } = useParams();
	let navigate = useNavigate();
	const { publicHoliday, loading } = useSelector(
		(state) => state.publicHoliday
	);
	const [holidaymodal, setHolidayModal] = useState(false)
	//dispatch
	const dispatch = useDispatch();

	//Delete Supplier
	const onDelete = async () => {
		setHolidayModal(true)
	};

	useEffect(() => {
		dispatch(loadSinglePublicHoliday(id));

		return () => {
			dispatch(clearPublicHoliday());
		};
	}, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	if (!isLogged) {
		return <Navigate to={"/admin/auth/login"} replace={true} />;
	}

	return (
		<div>
			<PageTitle title='Back' />
			<UserPrivateComponent permission={"readSingle-publicHoliday"}>
				<Card className='mr-top' bordered={false}>
					{publicHoliday ? (
						<Fragment key={publicHoliday.id}>
							<div>
								{/* <div className='flex justify-between '>
									<h3 className={"text-xl"}>
										ID : {publicHoliday.id} | {publicHoliday.name}
									</h3>
								</div> */}
								<div className='flex justify-center mb-4 '>
									<Card
										className="ant-shadow rounded-[20px]"
										bordered={false}
										style={{ width: 700 }}
									>
										<div
											className={`p-[15px] bg-[#EFF1F9] text-black rounded-[10px] text-center flex justify-between items-center mb-6`}>
											<div></div>
											<h2 className='text-center text-lg font-semibold'>Details of Bank
												Holiday</h2>
											<div className='flex justify-end'>
												{loading ? (
													<BtnLoader/>
												) : (
													new Date(publicHoliday?.date) > new Date() && (
														<button className='ml-2' onClick={onDelete}>
															<MdDelete size={27} color="#FF0000"/>
														</button>
													)
												)}
											</div>
										</div>
										<div className='flex justify-center'>
											<ul className='list-inside list-none '>
												<ListItem>
													Name :{" "}
													<TextInside>
														{(publicHoliday?.name).toUpperCase()}{" "}
													</TextInside>
												</ListItem>
												<ListItem>
													Date :{" "}
													<TextInside>
														{dayjs(publicHoliday?.date).format("DD/MM/YYYY")}
													</TextInside>
												</ListItem>

												<ListItem>
													Created At :{" "}
													<TextInside>
														{dayjs(publicHoliday?.createdAt).format(
															"DD/MM/YYYY"
														)}
													</TextInside>
												</ListItem>

												<ListItem>
													Updated At :{" "}
													<TextInside>
														{dayjs(publicHoliday?.updatedAt).format(
															"DD/MM/YYYY"
														)}
													</TextInside>
												</ListItem>
											</ul>
										</div>
									</Card>
								</div>
							</div>
						</Fragment>
					) : (
						<Loader />
					)}
				</Card>
			</UserPrivateComponent>
			<DelModal
				open={holidaymodal}
				onClose={setHolidayModal}
				publicHolidayId={id}
				content={"Are you sure you want to delete?"}
				type={"publicHoliday"}
			/>
		</div>
	);
};

const ListItem = tw.li`
text-sm
text-gray-600
font-semibold
py-2
px-4
bg-gray-100
mb-1.5
rounded
w-96
flex
justify-start
`;

const TextInside = tw.p`
ml-2
text-sm
text-gray-900
`;

export default DetailPublicHoliday;
