import React, { useState } from 'react';
import {Card} from "antd";
import AddManualLocation from './ManualLocationInput';

function LocationSelectionPage() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  return (
    <div>
    <h3 className='mb-4' style={{fontSize:"24px",fontWeight:"600"}}>Locations</h3>
      <AddManualLocation onLocationChange={handleLocationChange} />
      {/* <GoogleMapsComponent onLocationChange={handleLocationChange} /> */}
    </div>
  );
}

export default LocationSelectionPage;
