import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyOtp } from '../../redux/rtk/features/user/userSlice'; // Import your resetPassword action
import { toast } from "react-toastify";
import { Form, Button, Input, Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useFormik } from 'formik';
import { useSelector, } from 'react-redux';
import { useNavigate } from "react-router-dom";
import BtnLoader from '../loader/BtnLoader';
import styles from "./Login.module.css";
import otp from "../../assets/images/otpv.png";
import logo from "../../assets/images/logo.png";

import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

const initialValues = {
  token: "",
  submit: null,
}
const validationSchema = Yup.object().shape({
  token: Yup.string().max(255).required("Token is required"),

});
const VerifyOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const { email: email } = useParams(); // Extract email parameter from URL
  //const [token, setToken] = useState('');


  const handleSubmit = async () => {
    if (values?.token && email) {
      const resp = await dispatch(verifyOtp({ resetPasswordToken: values.token, email: email})); 
      if (resp.payload.message === "success") {
       navigate(`/admin/auth/resetpassword/${email}`);
      }
    }
  };


  const { values, errors, touched, handleBlur, handleChange } = useFormik(
    {
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: handleSubmit, // Use the custom handleSubmit function

    });

    return (
    <Row gutter={16} className='card-row' >
     
     <Col xs={0}  sm={12} className={styles.AlignmentImage}>
        <div className={styles.imageContainer}>
            <img src={otp} alt="Company Logo" width={550} />
          </div>
        </Col>
      <Col xs={24} sm={12} className={styles.AlignmentCard}>
        <Card bordered={false} className={`${styles.card} mt-1 mb-3`}>
          <div className={styles.logoImg}>
            <img src={logo} alt="Company Logo" width={100} />
          </div>
          <h3 className='m-3 mb-5 text-center'> Enter OTP</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form style={{marginTop:"70px"}} className='p-4'>
              <Form.Item className="form-group mb-3">
                <div className={styles.Input}>
                  <Input className="form-control"
                    name="token"
                    placeholder="Token"
                    type="text"
                    value={values.token}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                {errors.token && touched.token ?
                  (<p className="mt-1" style={{ color: "red", fontSize: "13px" }}>{errors.token}</p>)
                  : null}
              </Form.Item>

              <Form.Item className={styles.submitBtnContainer}>
                {!loader ? (
                  <Button
                    type='submit'
                    className='bg-[#E42976] text-white border-0'
                    htmlType='submit'
                    onClick={handleSubmit}
                  >
                    Verify
                  </Button>
                ) : (
                  <BtnLoader />
                )}

              </Form.Item>
            </Form>
          </Formik>
        </Card>
      </Col>
    </Row>
  );
}


export default VerifyOtp