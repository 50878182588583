import { Navigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TimePicker,
  Typography,
  DatePicker,
  Tooltip
} from "antd";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import UpdateBtn from "../Buttons/UpdateBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadAllNewShifts } from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlineSearchOff } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import getPermissions from "../../utils/getPermissions";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [delshift, setDelShift] = useState(false);
  const [shiftId, setShiftId] = useState();
  const permissions = getPermissions();

  const hasPermission = (item) => {
    return permissions?.includes(item ? item : "");
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Shift For",
      dataIndex: "user",
      key: "user",
      render: (user) =>
        user == null ? "--" : user?.firstName + " " + user?.lastName,
    },
    {
      title: "Start Date",
      dataIndex: "shiftFrom",
      key: "shiftFrom",
      render: (shiftFrom) =>
        shiftFrom == null ? null : dayjs(shiftFrom).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "shiftTo",
      key: "shiftTo",
      render: (shiftTo) =>
        shiftTo == null ? null : dayjs(shiftTo).format("YYYY-MM-DD"),
    },
    {
      title: "Week Name",
      dataIndex: "weekNumber",
      key: "weekNumber",
      render: (weekNumber) =>
        weekNumber == null ? "--" : weekNumber + " " + "WEEK",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) =>
        location?.locationName == null ? "--" : location?.locationName,
    },
    {
      title: "Created At",
      dataIndex: "cratedAt",
      key: "cratedAt",
      render: (cratedAt) =>
          moment(cratedAt).format('YYYY-MM-DD')
          // dayjs(cratedAt).format("YYYY-MM-DD"),
    },

    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) =>
          // dayjs(updatedAt).format("YYYY-MM-DD"),
      moment(updatedAt).format('YYYY-MM-DD')

    },
    {
      title: "Created By",
      dataIndex: "assignedBy",
      key: "assignedBy",
      render: (assignedBy) =>
        assignedBy?.firstName == null
          ? "--"
          : assignedBy?.firstName + " " + assignedBy?.lastName,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id) => (
          <div className="flex items-center">
            <div>
              <ViewBtn path={`/view/shift/${id}`} />
            </div>
            <UpdateBtn path={`/update/shift/${id}`} />
            {/* <UserPrivateComponent permission={"delete-shifts"}> */}
            {hasPermission("delete-shifts") && (
                <Tooltip title="Delete">
                  <button
                      type="primary"
                      onClick={() => {
                        ShiftsDelete(id);
                      }}
                      className="text-lg text-[#FF0000] font-bold px-2 mr-2"
                  >
                    <MdDelete size={25} color="#FF0000" />
                  </button>
                </Tooltip>
            )}
            {/* </UserPrivateComponent> */}
          </div>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr?.map((i) => ({ ...i, key: i.id }));

  const handleSearch = () => {
    let data = "";
    if (fromDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "shiftFrom=" + moment(fromDate).format('YYYY-MM-DD');
    }
    if (toDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "shiftTo=" + moment(toDate).format('YYYY-MM-DD');
    }
    dispatch(loadAllNewShifts(`shifts/?${data}`));
  };
  const ClearFilter = () => {
    dispatch(loadAllNewShifts(`shifts`));
    setFromDate("");
    setToDate("");
  };

  const ShiftsDelete = (id) => {
    // setLoader(true);
    setShiftId(id);
    setDelShift(true);
  };

  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((data) => ({
        id: data?.id,
        start_date:
          data?.shiftFrom == null
            ? "-"
            : dayjs(data?.shiftFrom).format("DD-MM-YYYY"),
        End_date:
          data?.shiftTo == null
            ? "-"
            : dayjs(data?.shiftTo).format("DD-MM-YYYY"),
        week_Name:
          data?.weekNumber == null ? "-" : data.weekNumber + " " + "WEEK",
        Location: data?.location == null ? "-" : data?.location?.locationName,
        Created_At:
          data?.createdAt == null
            ? "-"
            : dayjs(data?.createdAt).format("DD-MM-YYYY"),
        updated_At:
          data?.updatedAt == null
            ? "-"
            : dayjs(data?.updatedAt).format("DD-MM-YYYY"),
        Created_By:
          data?.assignedBy == null
            ? "-"
            : data?.assignedBy?.firstName + " " + data?.assignedBy?.lastName,
      }))
      : "Not Data found";

  const handleDateChange = (date, dateString) => {
    setFromDate(date);
  };
  const handletoDateChange = (date, dateString) => {
    setToDate(date);
  };
  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        All Shifts
      </h5>
      <div className="flex justify-between items-center mx-3 mb-3 flex-wrap gap-4">
        <>
          {list && (
            <div className="flex items-center">
              <div>
                <ColVisibilityDropdown
                  options={columns}
                  columns={columns}
                  columnsToShowHandler={columnsToShowHandler}
                />
              </div>

              <div>
                {list && list.length > 0 ? (
                  <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                    <CSVLink
                      data={CSVlist}
                      className="btn btn-dark btn-sm mb-1 text-white text-[15px] font-[400]"
                      filename="shift"
                    >
                      CSV
                    </CSVLink>
                  </CsvLinkBtn>
                ) : (
                  <div className="pl-2">No data to export</div>
                )}
              </div>

              {/* <div>
              <CsvLinkBtn className="bg-[#0723a1] hover:bg-[#0723a1] hover:text-white !mr-0">
                <CSVLink
                  data={list}
                  className="btn btn-dark btn-sm mb-1 text-white"
                  filename="shift"
                >
                  Copy
                </CSVLink>
              </CsvLinkBtn>
            </div> */}
            </div>
          )}
          <div className="text-end">
            <Link
              to="/admin/create/shift"
              className=" bg-[#E42976] text-white  text-[15px] font-[400] text-white border-0 h-[32px] py-0 px-[25px] flex items-center text-center rounded-[5px]"
            >
              Add Shift
            </Link>
          </div>
        </>
      </div>
      <div className="flex mb-3">
        <Card className="mb-1 ant-shadow rounded-[20px] w-[100%] shift-filter-card mx-2">
          <Row className="justify-center xl:p-0 lg:p-0 md:p-0 sm:p-4 xsm:p-4 xxs:p-4 xxxs:p-4">
            <Col
              // xs={24} sm={12} md={8} lg={6}
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              md={{ span: 12, offset: 0 }}
            >
              <p className="pt-[6px] pb-[1px]">From Date:</p>
              <DatePicker
                value={fromDate ? moment(fromDate) : null}
                onChange={handleDateChange}
                format="DD-MM-YYYY"
                style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
              />
            </Col>
            <Col
              // xs={24} sm={12} md={8} lg={6}
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              lg={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              md={{ span: 12, offset: 0 }}
            >
              <p className="pt-[6px] pb-[1px]">To Date:</p>
              <DatePicker
                value={toDate ? moment(toDate) : null}
                onChange={handletoDateChange}
                format="DD-MM-YYYY"
                style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
              />
            </Col>

            <Col
              // xs={12} sm={12} md={6} lg={3}
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              md={{ span: 6, offset: 3 }}
            >
              <Form.Item>
                <Button
                  className="!w-[80%] text-[15px] font-[400] mt-[1.85rem] !px-[6px] !py-0 !h-[34px] flex items-center justify-center text-center ml-2  bg-[#E42976] text-white border-0"
                  type="button"
                  shape="round"
                  onClick={handleSearch}
                  size="large"
                >
                  <SearchOutlined style={{ fontSize: "20px" }} />
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col
              // xs={12} sm={12} md={6} lg={3}
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 6, offset: 3 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              md={{ span: 6, offset: 3 }}
            >
              <Form.Item>
                <Button
                  className="bg-[#FF0000] !w-[80%] text-[15px] font-[400] mt-[1.85rem] !px-[12px] !py-0 !h-[34px] text-white hover:text-white border-0 hover:border-0 flex items-center justify-center"
                  shape="round"
                  size="large"
                  onClick={ClearFilter}
                >
                  <MdOutlineSearchOff style={{ fontSize: "20px" }} />
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>
      <DelModal
        open={delshift}
        onClose={setDelShift}
        shiftId={shiftId}
        content={"Are you sure to delete this Shift?"}
        type={"shiftdel"}
      />
    </>
  );
}

const AllShifts = ({ drawer }) => {
  const dispatch = useDispatch();
  const All_shift = useSelector(
    (state) => state?.ShiftManagement?.AllShiftsList
  );
  const loading = useSelector((state) => state?.ShiftManagement?.loading);

  useEffect(() => {
    dispatch(loadAllNewShifts(`shifts`));
  }, []);

  const isLogged = Boolean(localStorage.getItem("isLogged"));
  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={All_shift} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AllShifts;
