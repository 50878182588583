import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BtnEditSvg from "../Button/btnEditSvg";
import { useDispatch } from "react-redux";
import {
  loadAllLeavePolicy,
  loadSingleLeavePolicy,
  updateLeavePolicy,
} from "../../../redux/rtk/features/leavePolicy/leavePolicySlice";
import { AiOutlineEdit } from "react-icons/ai";

const LeavePolicyEdit = ({ data, tid, size }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams("id");

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const onFinish = async (values) => {
    const FormData = {
      ...values,
      paidLeaveCount: parseInt(values.paidLeaveCount),
      unpaidLeaveCount: parseInt(values.unpaidLeaveCount),
    };
    let editId;
    if (id) {
      editId = id;
    } else {
      editId = tid;
    }
    setLoader(true);
    const resp = await dispatch(
      updateLeavePolicy({ editId, values: FormData })
    );

    if (resp.payload.message === "success") {
      setLoader(false);
      setIsModalOpen(false);
      if (id) {
        dispatch(loadSingleLeavePolicy(id));
      } else {
        dispatch(loadAllLeavePolicy());
      }
    } else {
      setLoader(false);
    }
  };

  const initialValues = {
    name: data?.name,
    paidLeaveCount: data?.paidLeaveCount,
    unpaidLeaveCount: data?.unpaidLeaveCount,
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
    setLoader(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setLoader(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setLoader(false);
  };
  return (
    <>
      <button onClick={showModal}>
        <AiOutlineEdit size={size ? size : 30} color="#23B9F8" />
      </button>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Leave Policy Edit
          </div>
        }
        className="global-modal-custom"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ marginBottom: "50px" }}
          eventKey="department-form"
          initialValues={{ ...initialValues }}
          name="basic"
          layout="vertical"
          labelCol={{
            offset: 6,
            span: 12,
          }}
          wrapperCol={{
            offset: 6,
            span: 12,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input Leave Policy name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Paid Leave"
              name="paidLeaveCount"
              rules={[
                {
                  required: true,
                  message: "Please input your Paid Leave!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label="Unpaid Leave"
              name="unpaidLeaveCount"
              rules={[
                {
                  required: true,
                  message: "Please input your Unpaid Leave!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              wrapperCol={{
                offset: 6,
                span: 12,
              }}
            >
              <Button
                onClick={() => setLoader(true)}
                type="submit"
                className=" bg-[#E42976] text-white border-0"
                size="medium"
                htmlType="submit"
                block
                loading={loader}
              >
                Update Leave Policy
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default LeavePolicyEdit;
