import { Card, Col, List, Row } from "antd";
import Loader from "../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  clearLeaveApplication,
  loadSingelLeaveApplication,
  LeaveApplicationStatus,
  leaveCountHistory,
} from "../../redux/rtk/features/leave/leaveSlice";
import tw from "tailwind-styled-components";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";
import dayjs from "dayjs";
import ReviewLeavePopup from "../UI/PopUp/ReviewLeavePopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import styles from "../leave/leave.module.css";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import React, { useState } from "react";
import { Modal, Table, Button } from "antd";
import { GrDownload } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  loadSingleSwap,
  updateSwapRequest,
} from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import SwapFrom from "../../assets/images/face-4.jpg";
import SwapWith from "../../assets/images/face-3.jpg";
import SwapRejectionReason from "../UI/PopUp/SwapRejectionReason";

const SwapShiftDetail = () => {
  const SingleSwap = useSelector((state) => state.ShiftManagement.SingleSwap);
  const { id } = useParams("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateLeave, setUpdateLeave] = useState(false);

  useEffect(() => {
    dispatch(loadSingleSwap(id));
  }, []);

 async function handleSwapStatus(status) {
   const values = {
     userId: SingleSwap?.userId,
     requststatus: status,
   };
console.log(values)

   const resp = await dispatch(updateSwapRequest({ id: id, values: values }));
   if (resp.payload.message === "success") {
     dispatch(loadSingleSwap(id));
     setUpdateLeave(false);
   } else {
   }
 }
  return (
    <div>
      <PageTitle title="Back" />

      <UserPrivateComponent permission={"readSingle-shifts"}>
        <div className={styles.container}>
          <Card className={styles.cardStyle}>
            <div className={`${styles.title} text-center `}>
              <h2>Swap Shift Request Details</h2>
            </div>
            <Row className="p-10">
              <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                <div className="grid">
                  <div className="flex gap-4 items-center pb-6">
                    <img
                      src={
                        SingleSwap?.scheduleFrom?.shifts?.user?.image == null
                          ? SwapFrom
                          : SingleSwap?.scheduleFrom?.shifts?.user?.image
                      }
                      className="w-[70px] h-[70px] object-cover rounded-[10px]"
                      alt="..."
                    />
                    <div className="text-start">
                      <p className="text-[14px]">From:</p>
                      <h4 className="text-xl font-[600] capitalize">
                        {SingleSwap?.scheduleFrom?.shifts?.user == null
                          ? "--"
                          : SingleSwap?.scheduleFrom?.shifts?.user?.firstName +
                            " " +
                            SingleSwap?.scheduleFrom?.shifts?.user?.lastName}
                      </h4>
                    </div>
                  </div>
                  <List className="list-inside list-none border-none">
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Date:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleFrom?.shiftDate == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleFrom?.shiftDate).format(
                              "	MMMM D, YYYY"
                            )}
                      </p>
                    </div>

                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Location:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleFrom?.room == null
                          ? "--"
                          : SingleSwap?.scheduleFrom?.room?.location?.locationName}
                      </p>
                    </div>

                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Room:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleFrom?.room == null
                          ? "--"
                          : SingleSwap?.scheduleFrom?.room?.roomName}
                      </p>
                    </div>

                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Start Time:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleFrom?.startTime == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleFrom?.startTime).format(
                              "h:mm A"
                            )}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">End Time:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleFrom?.endTime == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleFrom?.endTime).format(
                              "h:mm A"
                            )}
                      </p>
                    </div>
                  </List>
                </div>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={24}
                sm={24}
                xs={24}
                className="flex justify-center items-center"
              >
                <div className="bg-[#fff] rounded-[50px] shadow p-6">
                  <FaArrowRightArrowLeft size="25px" color="rgb(6, 34, 161)" />
                </div>
              </Col>
              <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                <div className="grid">
                  <div className="flex gap-4 items-center pb-6">
                    <img
                      src={
                        SingleSwap?.scheduleTo?.shifts?.user?.image == null
                          ? SwapWith
                          : SingleSwap?.scheduleTo?.shifts?.user?.image
                      }
                      className="w-[70px] h-[70px] object-cover rounded-[10px]"
                      alt="..."
                    />
                    <div className="text-start">
                      <p className="text-[14px]">With:</p>
                      <h4 className="text-xl font-[600] capitalize">
                        {SingleSwap?.scheduleTo?.shifts?.user == null
                          ? "--"
                          : SingleSwap?.scheduleTo?.shifts?.user?.firstName +
                            " " +
                            SingleSwap?.scheduleTo?.shifts?.user?.lastName}
                      </h4>
                    </div>
                  </div>
                  <List className="list-inside list-none border-none">
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Date:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleTo?.shiftDate == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleTo?.shiftDate).format(
                              "	MMMM D, YYYY"
                            )}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Location:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleTo?.room == null
                          ? "--"
                          : SingleSwap?.scheduleTo?.room?.location?.locationName}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Room:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleTo?.room == null
                          ? "--"
                          : SingleSwap?.scheduleTo?.room?.roomName}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">Start Time:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleTo?.startTime == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleTo?.startTime).format(
                              "h:mm A"
                            )}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-[15px] font-[600]">End Time:</p>
                      <p className="text-[14px] font-[500] text-gray-400">
                        {SingleSwap?.scheduleTo?.endTime == null
                          ? "--"
                          : dayjs(SingleSwap?.scheduleTo?.endTime).format(
                              "h:mm A"
                            )}
                      </p>
                    </div>
                  </List>
                </div>
              </Col>
            </Row>
            <div className="flex justify-center ">
              <div className="flex justify-center mt-5">
                <UserPrivateComponent permission={"update-request"}>
                  {SingleSwap?.requststatus === "PENDING" && (
                    <div className="flex justify-center items-center">
                      <SwapRejectionReason pageId={id} />
                    </div>
                  )}
                </UserPrivateComponent>
                <UserPrivateComponent permission={"update-request"}>
                  {SingleSwap?.requststatus === "PENDING" && (
                    <div
                      className={`${styles.Button} flex justify-center items-center`}
                    >
                      <Button
                        className="text-white"
                        type="primary"
                        htmlType="submit"
                        block
                        onClick={(e) => handleSwapStatus("APPROVED")}
                      >
                        Approved
                      </Button>
                    </div>
                  )}
                  {SingleSwap?.requststatus === "APPROVED" ? (
                    <div
                      className={`${styles.Button} flex justify-center items-center`}
                    >
                      <Button
                        className="text-white"
                        type="primary"
                        htmlType="submit"
                        block
                        onClick={(e) => setUpdateLeave(true)}
                      >
                        Update Status
                      </Button>
                    </div>
                  ) : (
                    SingleSwap?.requststatus === "REJECTED" && (
                      <div
                        className={`${styles.Button} flex justify-center items-center`}
                      >
                        <Button
                          className="text-white"
                          type="primary"
                          htmlType="submit"
                          block
                          onClick={(e) => setUpdateLeave(true)}
                        >
                          Update Status
                        </Button>
                      </div>
                    )
                  )}
                </UserPrivateComponent>
              </div>
            </div>
          </Card>
        </div>
        <Modal
          title={
            <div className={`${styles.title} text-center p-3`}>
              <h3>Update Swap Status</h3>
            </div>
          }
          visible={updateLeave}
          onCancel={(e) => setUpdateLeave(false)}
          footer={false}
        >
          <div className="flex items-center gap-4 justify-center">
            {SingleSwap?.requststatus === "APPROVED" ? (
              <div
                className={`${styles.Button} flex justify-center items-center`}
              >
                <Button
                  className="text-white !bg-[red] hover:border-0 border-0"
                  // type='primary'
                  htmlType="submit"
                  block
                  onClick={(e) => handleSwapStatus("REJECTED")}
                >
                  Rejected
                </Button>
              </div>
            ) : (
              SingleSwap?.requststatus === "REJECTED" && (
                <div
                  className={`${styles.Button} flex justify-center items-center`}
                >
                  <Button
                    className="text-white"
                    type="primary"
                    htmlType="submit"
                    block
                    onClick={(e) => handleSwapStatus("APPROVED")}
                  >
                    Approved
                  </Button>
                </div>
              )
            )}

            <div
              className={`${styles.Button} flex justify-center items-center`}
            >
              <Button
                className="text-black bg-[#e0e0e0] hover:border-0 border-0"
                type="secondary"
                htmlType="submit"
                block
                onClick={(e) => setUpdateLeave(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </UserPrivateComponent>
    </div>
  );

  // "reviewComment": null,
  // "attachment": null,
};

const ListItem = tw.li`
  text-sm
  text-gray-600
  font-semibold
  py-2
  px-2
  bg-gray-100
  mb-1.5
  rounded
  w-96
  flex
  justify-start
  text-left

`;

const TextInside = tw.p`
  ml-2
  text-sm
  text-gray-900
`;
export default SwapShiftDetail;
