
// Loader.js
import React from 'react';
import './loader.css'; // You'll need to define the loader's styling

const BtnLoader = () => {
  return <div className="loader"></div>;
};

export default BtnLoader;
