import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

const initialState = {
  list: [],
  deleteloc:[],
  location: null,
  error: "",
  loading: false,
};
// Get Locations
export const getLocations = async () => {
  const { data } = await axios.get(`location?query=all`);

  return data;
};

// Add Manual Location
export const manualLocation = createAsyncThunk(
  "user/location",
  async ({ locationName, latitude, longitude }, thunkAPI) => {
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `/location/addlocation`,
        data: {
          locationName: locationName,
          latitude: "" + latitude,
          longitude: "" + longitude,
        },
      });

      toast.success("Location added successfully");
      thunkAPI.dispatch(loadSingleLocation());
      return {
        data,
        message: "success",
      };
    } catch (error) {
      console.log(error.message);
      toast.error("Error in adding Location at LocationSlice");
      return {
        message: "error",
      };
    }
  }
);
// Get All Locations
export const loadAllLocation = createAsyncThunk(
  "location/loadAllLocation",
  async () => {
    try {
      const { data } = await axios.get(`location?status=true`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);
// Load Single Location
export const loadSingleLocation = createAsyncThunk(
  "location/loadSingleLocation",
  async (id) => {
    console.log("locationslice getting id: ", id);
    try {
      const data = await axios.get(`location/${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);
// DELETE Location
export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async (id) => {
    try {
      const resp = await axios({
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `location/${id}`,
      });
      if (resp.status === 200) {
        toast.success(resp.data.message);
      }
      return {
        data: resp.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);
// UPDATE_Location

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ id, values }) => {
    try {
      const { data } = await axios({
        method: "put",
        url: `location/${id}`,
        data: {
          ...values,
        },
      });
      toast.success("Location Updated");
      return {
        data,
        message: "success",
      };
    } catch (error) {
      toast.error("Error in updating location try again");
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    clearLocation: (state) => {
      state.location = null;
    },
    resetLocation: (state) => {
      state.location = null;
    },
  },
  extraReducers: (builder) => {
    // 1) ====== builders for manualLocation ======

    /* 	builder.addCase(manualLocation.pending, (state) => {
				state.loading = true;
			});
	
			builder.addCase(manualLocation.fulfilled, (state, action) => {
				state.loading = false;
				state.list = action.payload;
			}); */

    builder.addCase(manualLocation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(manualLocation.fulfilled, (state, action) => {
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(action.payload);
      state.list = list;
    });

    // 2) ====== builders for loadAllLocation ======

    builder.addCase(loadAllLocation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });

    builder.addCase(loadAllLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    // 3) ====== builders for loadSingleLocation ======

    builder.addCase(loadSingleLocation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSingleLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.location = action.payload.data;
    });

    builder.addCase(loadSingleLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for deleteLocation ======

    builder.addCase(deleteLocation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteloc = action.payload;
    });

    builder.addCase(deleteLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    // 3) ====== builders for updateLocation ======

    builder.addCase(updateLocation.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.loading = false;
      const list = [...state.list];
      const index = list.findIndex(
        (location) => location.id === parseInt(action.payload.data.id)
      );
      list[index] = action.payload.data;
      state.list = list;
    });

    builder.addCase(updateLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export const { clearLocation,resetLocation } = locationSlice.actions;
export default locationSlice.reducer;
