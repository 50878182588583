import { Button, Card, Popover, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { CSVLink } from "react-csv";
import ViewBtn from "../Buttons/ViewBtn";
import dayjs from "dayjs";
import DepartmentEditPopup from "../UI/PopUp/DepartmentEditPopup";
import {
  deleteLocation,
  loadSingleLocation,
} from "../../redux/rtk/features/location/locationSlice";
import LocationDelete from "./locationDelete";
import LocationEditPopup from "../UI/PopUp/LocationEditPopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

//PopUp

const CustomTable = ({ list }) => {
  const dispatch = useDispatch();
  const single_user_location = useSelector((state) => state.location.location);
  const loading = useSelector((state) => state.location.loading);
  const { id } = useParams();
  const [Row, setRow] = useState([]);

  const [columnsToShow, setColumnsToShow] = useState([]);

  useEffect(() => {
    if (single_user_location) {
      makeRow();
    } else {
      dispatch(loadSingleLocation(id));
    }
  }, [single_user_location]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <UserPrivateComponent permission={"readSingle-user"}>
          <ViewBtn path={`/admin/hr/staffs/${id}/`} />
        </UserPrivateComponent>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);
  const makeRow = () => {
    const locationdata = single_user_location.user;
    const data =
      Array.isArray(locationdata) && locationdata.length > 0
        ? locationdata.map((data, index) => ({
            id: data.id,
            Name: data?.firstName + " " + data?.lastName,
            userName: data?.userName,
            location:
              single_user_location.locationName == null
                ? "-"
                : single_user_location.locationName,
            longitude:
              single_user_location.longitude == null
                ? "-"
                : single_user_location.longitude,
            latitude:
              single_user_location.latitude == null
                ? "-"
                : single_user_location.latitude,
            createdAt:
              single_user_location.createdAt == null
                ? "-"
                : moment(single_user_location.createdAt).format("MMM Do YY"),
            updatedAt:
              single_user_location.updatedAt == null
                ? "-"
                : moment(single_user_location.updatedAt).format("MMM Do YY"),
            action: data,
          }))
        : locationdata;
    setRow(data);
  };

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  // const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <div>
      <div className="text-center my-2 mt-4 flex justify-between items-center">
        {list && (
          <div className="flex items-center">
            <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
              <CSVLink
                data={Row}
                filename="user_location"
                className="hover:!text-white"
              >
                Download CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}

        {list && (
          <div className="flex items-center">
            <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
          </div>
        )}
      </div>
      <div className="w-full overflow-auto">
        <Table
          loading={loading}
          columns={columnsToShow}
          dataSource={Row}
          // pagination={{ pageSize: 5 }}
          // scroll={{ x: 720 }}/
        />
      </div>
    </div>
  );
};

const DetailLocation = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  //dispatch
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location.location);

  //Delete Supplier
    // Delete Supplier PopUp
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    dispatch(loadSingleLocation(id));
  }, []);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h5 className="text-color-2 text-2xl font-[600]">Location List</h5>
        <PageTitle title=" Back" />
      </div>
      <UserPrivateComponent permission={"readSingle-location"}>
        <Card
          className="mr-top mt-5 ant-shadow rounded-[20px]"
          bordered={false}
        >
          {location ? (
            <Fragment key={location.id}>
              <div>
                <div className="flex justify-between ">
                  <h3 className={"text-xl"}>
                    ID : {location.id} | {location.locationName}
                  </h3>
                  <div className="flex justify-end">
                    <UserPrivateComponent permission={"update-location"}>
                      <LocationEditPopup data={location} />
                    </UserPrivateComponent>

                    <UserPrivateComponent permission={"delete-location"}>
                      <LocationDelete id={id} />
                    </UserPrivateComponent>
                  </div>
                </div>
                <CustomTable list={location.user} />
              </div>
            </Fragment>
          ) : (
            <Loader />
          )}
        </Card>
      </UserPrivateComponent>
    </div>
  );
};

export default DetailLocation;
