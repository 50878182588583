import { Navigate } from "react-router-dom";
import PageTitle from "../page-header/PageHeader";
import LocationSelectionPage from "./LocationSelectionPage";
//import GetAllLocation from "./GetAllLocation";
const Location = (props) => {
	const isLogged = Boolean(localStorage.getItem("isLogged"));

	if (!isLogged) {
		return <Navigate to={"/admin/auth/login"} replace={true} />;
	}
	return (
		<div>
			{/* <PageTitle title='Back' /> */}
			<LocationSelectionPage />
{/* 			<GetAllLocation/>
 */} 		</div>
	);
};

export default Location;
