import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./designtaion.css";

import { Table, Pagination, Card, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
	loadAllDesignation,
	loadAllDesignationByEmployee,
} from "../../redux/rtk/features/designation/designationSlice";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import ViewBtn from "../Buttons/ViewBtn";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import AddDesignation from "./addDesignation";


function CustomTable({ list, loading }) {
	const dispatch = useDispatch();
	const [columnsToShow, setColumnsToShow] = useState([]);
	const [currentPage, setCurrentPage] = useState(1); // Current page state
	const [addModal, setAddModal] = useState(false)
	const pageSize = 10;

	console.log("List:", list);

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			id: 2,
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { id }) => (
				<Link to={`/admin/designation/${id}`}>{name}</Link>
			),
		},

		{
			id: 3,
			title: "Action",
			key: "action",
			render: ({ id }) => (
				<UserPrivateComponent permission={"readSingle-designation"}>
					<ViewBtn path={`/admin/designation/${id}`} />
				</UserPrivateComponent>
			),
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};



	const handleChangePage = (page) => {
		setCurrentPage(page);
		// You can fetch data for the new page here if needed
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));
	console.log("Total:", addKeys(list).length);

	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = Math.min(startIndex + pageSize, list.length); // Ensure endIndex does not exceed the length of the list
	const currentData = list ? addKeys(list).slice(startIndex, endIndex) : [];
	return (
    <>
      <div className="text-center my-2 flex justify-between items-center mr-3">
        {list && (
          <div>
            <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
              <CSVLink
                data={list}
                className="btn btn-dark btn-sm mb-1 hover:!text-white text-[15px] font-[400]"
                filename="designation"
              >
                CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}
        <div className="text-center mr-3 mb-1 flex justify-end">
          <Button type="button" className="bg-[#E42976] text-white border-0 text-[15px] font-[400]" onClick={(e) => setAddModal(true)}>
            Add Designation
          </Button>
        </div>
        {/* {list && (
				<div className='ml-4'>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)} */}
      </div>
      <Card className="mr-3 rounded-[20px] ant-shadow" bordered={false}>
        <div className="card-body">
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columnsToShow}
            dataSource={currentData}
            pagination={false} // Disable the default pagination
        // pagination={{ pageSize: 10 }}

          />

          {/* Add Pagination component */}
          <Pagination
            className="mt-3 flex justify-end"
            current={currentPage}
            pageSize={pageSize}
            total={list.length}
            onChange={handleChangePage}
            showSizeChanger={false} // Hide the "Show X items per page" dropdown
          />
        </div>
      </Card>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              margin: "5px 25px",
              padding: "10px",
              backgroundColor: "#EEF1F8",
            }}
          >
            Add New designation
          </div>
        }
        className="global-modal-custom"
        visible={addModal}
        onOk={(e) => setAddModal(false)}
        onCancel={(e) => setAddModal(false)}
        footer={null}
        width={800}
        centered
      >
        <UserPrivateComponent permission={"create-designation"}>
          <AddDesignation onCancel={setAddModal} />
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const GetAllDesignation = (props) => {
	const dispatch = useDispatch();
	const { list, loading } = useSelector((state) => state.designations);

	useEffect(() => {
		dispatch(loadAllDesignation());
	}, []);


	// useEffect(() => {
	//   deleteHandler(list, deletedId);
	// }, [deletedId, list]);

	return (

		<CustomTable list={list} loading={loading} />

	);
};

export default GetAllDesignation;
