import {
  MailOutlined,
  PhoneOutlined,
  ThunderboltOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  IdcardOutlined,
  MedicineBoxOutlined,
  FileProtectOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { Col, Row, Alert, Image } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import tw from "tailwind-styled-components";
import {
  clearUser,
  deleteStaff,
  loadSingleStaff,
} from "../../redux/rtk/features/user/userSlice";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";

import EmployeeDesignation from "../UI/EmployeeDesignation";
import EmployeeTimeline from "../UI/EmployeeTimeline";
import DesignationEditPopup from "../UI/PopUp/DesignationEditPopup";
import EducaitonEditPopup from "../UI/PopUp/EducaitonEditPopup";
import ProfileEditPopup from "../UI/PopUp/ProfileEditPopup";
import SalaryEditPopup from "../UI/PopUp/SalaryEditPopup";
import EmployeeSalary from "../UI/EmployeeSalary";
import { MdDelete } from "react-icons/md";
import AwardHistoryEditPopup from "../UI/PopUp/AwardHistoryEditPopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { loadAllDesignation } from "../../redux/rtk/features/designation/designationSlice";
import companyLogo from "../../assets/images/comlogo.png"
import DelModal from "../UI/PopUp/DeleteWarningModal";
//PopUp

const DetailStaff = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [delModal, setDelModal] = useState(false)
  //dispatch
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.users.error);
  const designation = useSelector((state) => state.designations.list);
  //Delete Supplier
  const onDelete = () => {
    setDelModal(true)
    return
    try {
      dispatch(deleteStaff(id));
      setVisible(false);
      toast.warning(`User Name : ${user.userName} is removed `);
      return navigate("/admin/hr/staffs");
    } catch (error) {
      console.log(error.message);
    }
  };
  // Delete Supplier PopUp
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    dispatch(loadSingleStaff(id));
    return () => {
      dispatch(clearUser());
    };
  }, [id]);

  useEffect(() => {
    dispatch(loadAllDesignation());
  }, [dispatch]);
  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  function getExtension(url) {
    return url?.split(".")?.pop();
  }

  const check_firstAid = getExtension(user?.firstaid);
  const check_dbscheck = getExtension(user?.dbscheck);
  const check_safegarding = getExtension(user?.safeguarding);

  return (
    <div>
      <UserPrivateComponent permission={"readSingle-user"}>
        <PageTitle title=" Back  " />

        {user ? (
          <div className="mr-top">
            <section>
              <div className="flex justify-between ant-card rounded h-auto mr-8 xl:flex-nowrap lg:flex-wrap md:flex-wrap sm:flex-wrap	xsm:flex-wrap xxs:flex-wrap xxxs:flex-wrap">
                {/* Profile Card */}

                <div className="flex justify-start xl:flex-nowrap lg:flex-nowrap md:flex-nowrap sm:flex-nowrap xsm:flex-nowrap xxs:flex-wrap xxxs:flex-wrap">
                  <img
                    src={user?.image ? user?.image : companyLogo}
                    alt="profile"
                    className="rounded-full h-40 w-40 m-5 object-cover"
                  />
                  <div class="flex justify-center py-8 px-4 mt-4 ml-4">
                    <div class="flex flex-col items-around">
                      <h1 class="text-2xl font-bold txt-color-2 mb-1">
                        {(user?.firstName + " " + user?.lastName).toUpperCase()}
                      </h1>
                      <h2 class="text-base font-medium txt-color-secondary mb-1">
                        Designation :&nbsp;
                        <span className="txt-color-2">
                          {user?.designationHistory[0]?.designation?.name ||
                            "No Designation"}
                        </span>
                      </h2>
                      <h3 class="text-base font-medium txt-color-secondary">
                        Department :&nbsp;
                        <span className="txt-color-2">
                          {user?.department?.name || "No Department"}
                        </span>
                      </h3>
                      <h3 class="text-base font-medium txt-color-secondary">
                        Employee ID :&nbsp;
                        <span className="txt-color-2">
                          {user?.employeeId || "No Employee ID"}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                {/* Leave Status */}
                <div className="">
                  <div className="flex justify-center py-8 px-4 mt-4">
                    <div className="flex flex-col items-around">
                      <h1 className="text-2xl font-bold txt-color-2 mb-1">
                        Leave Status
                      </h1>

                      <h3 className="text-base font-medium txt-color-secondary">
                        Leaves :{" "}
                        <span className="text-base font-medium txt-color-2">
                          Paid -{" "}
                          {user?.annualallowedleave == null
                            ? "0"
                            : user?.annualallowedleave}{" "}
                          | Bank -{" "}
                          {user?.bankallowedleave == null
                            ? "0"
                            : user?.bankallowedleave}
                        </span>
                      </h3>
                      <h3 className="text-base font-medium txt-color-secondary">
                        Taken :{" "}
                        <span className="text-base font-medium txt-color-2">
                          Paid -{" "}
                          {user?.paidLeaveDays == null
                            ? "0"
                            : user?.paidLeaveDays}{" "}
                          | Bank -{" "}
                          {user?.bankallowedleave == null || user?.remaingbankallowedleave == null
                            ? "0"
                            : user?.bankallowedleave - user?.remaingbankallowedleave}
                        </span>
                      </h3>
                      <h3 className="text-base font-medium txt-color-secondary">
                        Balance :{" "}
                        <span className="text-base font-medium txt-color-2">
                          Paid -{" "}
                          {user?.remainingannualallowedleave == null
                            ? "0"
                            : user?.remainingannualallowedleave}{" "}
                          | Bank -{" "}
                          {user?.remaingbankallowedleave == null
                            ? "0"
                            : user?.remaingbankallowedleave}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                {/*Clock In and Out Time*/}

                <div className="flex justify-center py-8 px-4 mt-4">
                  <div className="flex flex-col items-around">
                    <h1 className="text-2xl font-bold txt-color-2 mb-1">
                      Clock In & Out
                    </h1>

                    <h3 className="text-base font-medium txt-color-secondary">
                      In Time :{" "}
                      <span className="text-base font-medium txt-color-2">
                        {user?.attendance[0]?.inTime
                          ? user?.attendance[0]?.inTime
                          :
                          "--"
                        }
                      </span>
                    </h3>

                    <h3 className="text-base font-medium txt-color-secondary">
                      Out Time :{" "}
                      <span className="text-base font-medium txt-color-2">
                        {user?.attendance[0]?.outTime
                          ? user?.attendance[0]?.outTime
                          :
                          "--"
                        }
                      </span>
                    </h3>
                  </div>
                </div>

                {/* Action Buttons */}

                <div className="">
                  <div className="flex justify-center py-8 px-4 mt-10 mr-4">
                    <UserPrivateComponent permission="update-user">
                      <button className="mr-2 mt-2">
                        {user && <ProfileEditPopup data={user} />}
                      </button>
                    </UserPrivateComponent>
                    <UserPrivateComponent permission="delete-user">
                      <button onClick={onDelete}>
                        <MdDelete size={30} className="text-[#FF0000]" />
                      </button>
                    </UserPrivateComponent>
                  </div>
                </div>
              </div>
            </section>
            <Row
              className="mt-5"
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
                xl: 32,
              }}
            >
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={11}
                xl={11}
                className="new-card rounded h-auto m-2"
              >
                <ProfileCardText className="text-start">
                  Employee Information
                </ProfileCardText>

                <Hr />

                <div className="m-5">
                  <ul className="space-y-4">
                    {/* <li className="flex items-center">
                      <TeamOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Identity Status:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.identitystatus == null
                          ? "-"
                          : user?.identitystatus}
                      </p>
                    </li> */}
                    <li className="flex items-center">
                      <IdcardOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">NI No:</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.nicno == null ? "-" : user?.nicno}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Date of Birth:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.dob ? dayjs(user?.dob).format("DD/MM/YYYY") : '-'}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <PhoneOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Emergency Contact:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.emergencycontact == null
                          ? "-"
                          : user?.emergencycontact}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <TeamOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Department:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.department?.name}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <TrophyOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Employment Status:
                      </span>
                      <p
                        className={`text-[${user?.employmentStatus?.colourValue}] ml-2`}
                      >
                        {user?.employmentStatus?.name}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <TrophyOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Visa Status:
                      </span>
                      <p
                        className={`text-[${user?.employmentStatus?.colourValue}] ml-2`}
                      >
                        {user?.visaStatus ? user?.visaStatus : '-'}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Visa Expiry :
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.visaExpiry ? dayjs(user?.visaExpiry).format("DD/MM/YYYY") : '-'}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Join Date :
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {dayjs(user?.joinDate).format("DD/MM/YYYY")}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Leave Date:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.leaveDate
                          ? dayjs(user?.leaveDate).format("DD/MM/YYYY")
                          : "PRESENT"}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <UserOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Role :</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.role?.name || "No Role"}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Leave Policy:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.leavePolicy?.name == null
                          ? "-"
                          : user?.leavePolicy?.name}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Vise Status:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.visaStatus == null || user?.visaStatus === undefined
                          ? "-"
                          : user?.visaStatus}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Visa Expiry:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.visaExpiry ? dayjs(user.visaExpiry).format("DD/MM/YYYY") : "-"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Line Manager :
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.superviser == null
                          ? "-"
                          : user?.superviser?.firstName + ' ' + user?.superviser?.lastName}
                      </p>
                    </li>

                    {/* <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Weekly Holiday:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.weeklyHoliday?.name}
                      </p>
                    </li> */}

                    {/* <li className="flex items-center">
                      <ClockCircleOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Shift :</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.shift?.name || "No Shift"} (
                        {dayjs(user?.shift?.startTime).format("LT")} -{" "}
                        {dayjs(user?.shift?.endTime).format("LT")})
                      </p>
                    </li> */}
                    <li className="flex items-start">
                      <ClockCircleOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span
                        className="txt-color-2 font-medium"
                        style={{ whiteSpace: "pre" }}
                      >
                        Location :
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.location?.locationName || "No Location"} (
                        {user?.location?.longitude || "No Longitude"} -{" "}
                        {user?.location?.latitude || "No Latitude"})
                      </p>
                    </li>
                    {/* <li className="flex items-center">
                      <MedicineBoxOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        First Aid:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.firstaidtext == null ? "-" : user?.firstaidtext}
                      </p>
                    </li> */}
                    {user?.dbschecktext && (
                      <li className="flex items-center">
                        <TeamOutlined
                          className="mr-3"
                          style={{ fontSize: "15px" }}
                        />
                        <span className="txt-color-2 font-medium">
                          DBS Number:
                        </span>
                        <p className="txt-color-secondary ml-2">
                          {user?.dbschecktext == null
                            ? "-"
                            : user?.dbschecktext}
                        </p>
                      </li>
                    )}

                    {/* <li className="flex items-center">
                      <FileProtectOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Safeguard:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.safeguardingtext == null
                          ? "-"
                          : user?.safeguardingtext}
                      </p>
                    </li> */}
                    {user?.contractAttachment && (
                      <li className="flex items-center">
                        <FileProtectOutlined
                          className="mr-3"
                          style={{ fontSize: "15px" }}
                        />
                        <span className="txt-color-2 font-medium">
                          Contract Document:
                        </span>
                        <p className="txt-color-secondary ml-2">
                          {user?.contractAttachment == null ? (
                            "-"
                          ) : (
                            <a
                              target="_blank"
                              href={
                                user?.contractAttachment == null
                                  ? "#"
                                  : user?.contractAttachment
                              }
                              className="Doc-download-btn"
                            >
                              Download Doc
                              <DownloadOutlined
                                style={{
                                  color: "inherit",
                                  fontSize: "22px",
                                  fontWeight: "500",
                                }}
                              />
                            </a>
                          )}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="new-card rounded h-auto m-2"
              >
                <ProfileCardText className="text-start">
                  Contact Information
                </ProfileCardText>

                <Hr />
                <div className="m-5">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <MailOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Email:</span>
                      <p
                        className="txt-color-secondary ml-2"
                        style={{ lineBreak: "anywhere" }}
                      >
                        {user?.email || "No Email"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <PhoneOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Phone:</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.phone || "No Phone"}
                      </p>
                    </li>

                    <li className="flex items-start">
                      <HomeOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Address:</span>
                      <div className="">
                        <li className="txt-color-secondary ml-2">
                          {" "}
                          Street : {user?.street || "No Address"}
                        </li>
                        <li className="txt-color-secondary ml-2">
                          {" "}
                          City : {user?.city || "No Address"}
                        </li>
                        <li className="txt-color-secondary ml-2">
                          {" "}
                          County : {user?.state || "No Address"}
                        </li>
                        <li className="txt-color-secondary ml-2">
                          {" "}
                          Post Code : {user?.zipCode || "No Address"}
                        </li>
                        <li className="txt-color-secondary ml-2">
                          {" "}
                          Country : {user?.country || "No Address"}
                        </li>
                      </div>
                    </li>

                    {/* <li className="flex items-center">
                      <ThunderboltOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Blood Group:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.bloodGroup || "No Blood Group"}
                      </p>
                    </li> */}
                  </ul>
                </div>
                <Hr />

                <ProfileCardText className="text-start">
                  Previous Employer Information
                </ProfileCardText>

                <Hr />
                <div className="m-5">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <MailOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Company Name:
                      </span>
                      <p className="txt-color-secondary  ml-2">
                        {user?.companyname || "No Company Name"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <UserOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Employer Name:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.emp_name || "No Name"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <UserOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Employer Email :
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.emp_email || "No Employer Email"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <PhoneOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Tel No:</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.emp_telno || "No Tel No"}
                      </p>
                    </li>
                    <li className="flex items-center">
                      <TrophyOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Job Title:
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.designation || "No Designation"}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">
                        Joining Date
                      </span>
                      <p className="txt-color-secondary ml-2">
                        {user?.joining_date == null
                          ? "Date not found"
                          : dayjs(user?.joining_date).format("DD/MM/YYYY")}
                      </p>
                    </li>

                    <li className="flex items-center">
                      <CalendarOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">End Date</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.end_date == null
                          ? "Date not found"
                          : dayjs(user?.end_date).format("DD/MM/YYYY")}
                      </p>
                    </li>

                    <li className="flex items-start">
                      <HomeOutlined
                        className="mr-3"
                        style={{ fontSize: "15px" }}
                      />
                      <span className="txt-color-2 font-medium">Address:</span>
                      <p className="txt-color-secondary ml-2">
                        {user?.address || "No address"}
                      </p>
                    </li>
                  </ul>
                </div>
                {user?.companyname1 ||
                  user?.emp_email1 ||
                  user?.emp_name1 ||
                  user?.designation1 ||
                  user?.joining_date1 ||
                  user?.end_date1 ||
                  user?.address1 ? (
                  <>
                    <Hr />
                    <ProfileCardText className="text-start">
                      Second Reference Employer Information
                    </ProfileCardText>
                    <Hr />
                    <div className="m-5">
                      <ul className="space-y-4">
                        <li className="flex items-center">
                          <MailOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Company Name:
                          </span>
                          <p className="txt-color-secondary  ml-2">
                            {user?.companyname1 || "No Company Name"}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <UserOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Employer Name:
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.emp_name1 || "No Name"}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <UserOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Employer Email :
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.emp_email1 || "No Employer Email"}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <PhoneOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Tel No:
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.emp_telno1 || "No Tel No"}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <TrophyOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Job Title:
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.designation1 || "No Designation"}
                          </p>
                        </li>

                        <li className="flex items-center">
                          <CalendarOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Joining Date
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.joining_date1 == null
                              ? "Date not found"
                              : dayjs(user?.joining_date1).format("DD/MM/YYYY")}
                          </p>
                        </li>

                        <li className="flex items-center">
                          <CalendarOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            End Date
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.end_date1 == null
                              ? "Date not found"
                              : dayjs(user?.end_date1).format("DD/MM/YYYY")}
                          </p>
                        </li>

                        <li className="flex items-start">
                          <HomeOutlined
                            className="mr-3"
                            style={{ fontSize: "15px" }}
                          />
                          <span className="txt-color-2 font-medium">
                            Address:
                          </span>
                          <p className="txt-color-secondary ml-2">
                            {user?.address1 || "No address"}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>

              <Col
                xs={24}
                sm={12}
                md={12}
                lg={24}
                xl={24}
                className="new-card rounded h-auto m-2"
              >
                <div className="flex justify-around items-center">
                  {user?.firstaid == null || user?.firstaid == "null" ? (
                    ""
                  ) : (
                    <div className="text-center">
                      <h3 className="text-[20px] mb-3">First Aid Doc:</h3>
                      {check_firstAid === "jpg" ||
                        check_firstAid === "jpeg" ||
                        check_firstAid === "png" ||
                        check_firstAid === "webp" ||
                        check_firstAid === "avif" ||
                        check_firstAid === "gif" ||
                        check_firstAid === "svg" ? (
                        <Image
                          style={{ borderRadius: "50%", objectFit: "cover" }}
                          width={100}
                          height={100}
                          src={user?.firstaid}
                        />
                      ) : user?.firstaid == null || user?.firstaid == "null" ? (
                        ""
                      ) : (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={user?.firstaid}
                          className="Doc-download-btn"
                        >
                          Download Doc
                          <DownloadOutlined
                            style={{
                              color: "inherit",
                              fontSize: "22px",
                              fontWeight: "500",
                            }}
                          />
                        </a>
                      )}
                    </div>
                  )}
                  {user?.dbscheck == null || user.dbscheck == "null" ? (
                    ""
                  ) : (
                    <div className="text-center">
                      <h3 className="text-[20px] mb-3">DBS Check Doc:</h3>
                      {check_dbscheck === "jpg" ||
                        check_dbscheck === "jpeg" ||
                        check_dbscheck === "png" ||
                        check_dbscheck === "webp" ||
                        check_dbscheck === "avif" ||
                        check_dbscheck === "gif" ||
                        check_dbscheck === "svg" ? (
                        <>
                          <Image
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            width={100}
                            height={100}
                            src={user?.dbscheck}
                          />
                        </>
                      ) : user?.dbscheck == "null" || user?.dbscheck == null ? (
                        ""
                      ) : (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={user?.dbscheck}
                          className="Doc-download-btn"
                        >
                          Download Doc
                          <DownloadOutlined
                            style={{
                              color: "inherit",
                              fontSize: "22px",
                              fontWeight: "500",
                            }}
                          />
                        </a>
                      )}
                    </div>
                  )}
                  {user?.safeguarding == null ||
                    user?.safeguarding == "null" ? (
                    ""
                  ) : (
                    <div className="text-center">
                      <h3 className="text-[20px] mb-3">Safeguard Doc:</h3>
                      {check_safegarding === "jpg" ||
                        check_safegarding === "jpeg" ||
                        check_safegarding === "png" ||
                        check_safegarding === "webp" ||
                        check_safegarding === "avif" ||
                        check_safegarding === "gif" ||
                        check_safegarding === "svg" ? (
                        <Image
                          style={{ borderRadius: "50%", objectFit: "cover" }}
                          width={100}
                          height={100}
                          src={user?.safeguarding}
                        />
                      ) : user?.safeguarding == null ||
                        user?.safeguarding == "null" ? (
                        ""
                      ) : (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={user?.safeguarding}
                          className="Doc-download-btn"
                        >
                          Download Doc
                          <DownloadOutlined
                            style={{
                              color: "inherit",
                              fontSize: "22px",
                              fontWeight: "500",
                            }}
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </Col>

              <Col
                xs={24}
                sm={12}
                md={12}
                lg={11}
                xl={11}
                className="new-card rounded h-auto m-2"
              >
                <div className="flex justify-between">
                  <ProfileCardText className="text-start">
                    Designation History
                  </ProfileCardText>

                  <UserPrivateComponent
                    permission={"update-designationHistory"}
                  >
                    {user?.designationHistory && (
                      <DesignationEditPopup data={user?.designationHistory} />
                    )}
                  </UserPrivateComponent>
                </div>
                <Hr />
                <div className="flex justify-start xl:ml-10 lg:ml-10 md:ml-10 sm:ml-0 xsm:ml-0 xxs:ml-0 xxxs:ml-0">
                  {user?.designationHistory.length !== 0 ? (
                    <EmployeeDesignation list={user?.designationHistory} />
                  ) : (
                    <div className="mb-10">
                      <p className="text-center mt-3 mb-3">
                        No Designation History Found
                      </p>
                      <Alert
                        message="Not found , Click on edit button to add new"
                        type="info"
                        showIcon
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col
                xs={24}
                sm={12}
                md={12}
                lg={11}
                xl={12}
                className="new-card rounded h-auto m-2 "
              >
                <div className="flex justify-between">
                  <ProfileCardText className="text-start">
                    Educaiton History
                  </ProfileCardText>
                  <UserPrivateComponent permission={"update-education"}>
                    {user?.educations && (
                      <EducaitonEditPopup data={user?.educations} />
                    )}
                  </UserPrivateComponent>
                </div>
                <Hr />
                <div className="flex justify-start xl:ml-10 lg:ml-10 md:ml-10 sm:ml-0 xsm:ml-0 xxs:ml-0 xxxs:ml-0">
                  {user?.educations.length !== 0 ? (
                    <EmployeeTimeline list={user?.educations} />
                  ) : (
                    <div className="mb-10">
                      <p className="text-center mt-3 mb-3">
                        No Education History Found
                      </p>
                      <Alert
                        message="Not found , Click on edit button to add new"
                        type="info"
                        showIcon
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col
                xs={24}
                sm={12}
                md={12}
                lg={11}
                xl={11}
                className="new-card rounded h-auto m-2 "
              >
                <div className="flex justify-between">
                  <ProfileCardText className="text-start">
                    Salary History
                  </ProfileCardText>
                  <UserPrivateComponent permission={"update-salaryHistory"}>
                    {user?.salaryHistory && (
                      <SalaryEditPopup data={user?.salaryHistory} />
                    )}
                  </UserPrivateComponent>
                </div>
                <Hr />
                <div className="flex justify-start xl:ml-10 lg:ml-10 md:ml-10 sm:ml-0 xsm:ml-0 xxs:ml-0 xxxs:ml-0">
                  {user?.salaryHistory.length !== 0 ? (
                    <EmployeeSalary list={user?.salaryHistory} />
                  ) : (
                    <div className="mb-10">
                      <p className="text-center mt-3 mb-3 ">
                        No Salary History Found
                      </p>
                      <Alert
                        message="Not found , Click on edit button to add new"
                        type="info"
                        showIcon
                      />
                    </div>
                  )}
                </div>
              </Col>

              {/* <Col
								xs={24}
								sm={12}
								md={12}
								lg={11}
								xl={12}
								className='new-card rounded h-auto m-2 '>
								<div className='flex justify-between'>
									<ProfileCardText className='text-start'>
										Award History
									</ProfileCardText>

									<UserPrivateComponent permission={"update-awardHistory"}>
										{user?.awardHistory && (
											<AwardHistoryEditPopup data={user?.awardHistory} />
										)}
									</UserPrivateComponent>
								</div>
								<Hr />
								<div className='flex justify-start ml-10'>
									{user?.awardHistory.length !== 0 ? (
										<EmployeeAward list={user?.awardHistory} />
									) : (
										<div className='mb-10'>
											<p className='text-center mt-3 mb-3 '>
												No Award History Found
											</p>
											<Alert
												message='Not found , Click on edit button to add new'
												type='info'
												showIcon
											/>
										</div>
									)}
								</div>
							</Col> */}
            </Row>
          </div>
        ) : (
          <div className="mt-10">
            <Loader />
            {error && <p className="text-center mt-3 mb-3">No Data Found</p>}
          </div>
        )}
      </UserPrivateComponent>
      <DelModal
        open={delModal}
        onClose={setDelModal}
        staffId={id}
        content={"Are you sure to delete this User?"}
        type={"staffdel"}
      />
    </div>
  );
};

const ProfileCardText = tw.div`
txt-color-2
text-xl
 text-center
  mt-5 `;

const Hr = tw.hr`
mt-3
mb-3
new-hr`;

const Information = tw.div`
m-5
`;

export default DetailStaff;
