import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  list: [],
  AllShiftsList: [],
  SwapList: [],
  SingleShift: [],
  SingleSwap: [],
  error: "",
  loading: false,
};

// ADD_shift
export const CreateShiftManagement = createAsyncThunk(
  "ShiftManagement/CreateShiftManagement",
  async (values) => {
    try {
      const response = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `shifts/`,
        data: {
          ...values,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);

// DELETE_shift
export const deleteShifts = createAsyncThunk(
  "ShiftManagement/deleteShifts",
  async (id) => {
    try {
      const resp = await axios({
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `shifts/delete/${id}`,
      });
      if (resp.status === 200) {
        toast.success(resp.data.message);
      }
      return {
        data: resp.data.id,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
    }
  }
);

// shift_DETAILS
export const loadSingleShifts = createAsyncThunk(
  "ShiftManagement/loadSingleShifts",
  async (id) => {
    try {
      console.log("yup")
      const data = await axios.get(`shifts/${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// shiftS
export const loadAllNewShifts = createAsyncThunk(
  "ShiftManagement/loadAllNewShifts",
  async (value) => {
    try {
      const { data } = await axios.get(`${value}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// swap_list
export const loadAllSwapRequest = createAsyncThunk(
  "ShiftManagement/loadAllSwapRequest",
  async () => {
    try {
      const { data } = await axios.get(`request/allrequest`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// load_single_swap
export const loadSingleSwap = createAsyncThunk(
  "ShiftManagement/loadSingleSwap",
  async (id) => {
    try {
      const data = await axios.get(`request/${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// UPDATE_shift
export const updateSingleShift = createAsyncThunk(
  "ShiftManagement/update",
  async ({ id, values }) => {
    try {
      const response = await axios({
        method: "put",

        url: `shifts/update/${id}`,
        data: {
          ...values,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      console.log(error, "ba");
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);

// UPDATE_Swap_req
export const updateSwapRequest = createAsyncThunk(
  "ShiftManagement/updateSwapRequest",
  async ({ id, values }) => {
    try {
      const response = await axios({
        method: "put",

        url: `request/swapreq/${id}`,
        data: {
          ...values,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return {
        data: response.data,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
      return {
        message: "error",
      };
    }
  }
);

// DELETE_Swap_request
export const DeleteSwapRequest = createAsyncThunk(
  "ShiftManagement/DeleteSwapRequest",
  async (id) => {
    try {
      const resp = await axios({
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `request/${id}`,
      });
      if (resp.status === 200) {
        toast.success(resp.data.message);
      }
      return {
        data: resp.data.id,
        message: "success",
      };
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("something went wrong!");
      }
    }
  }
);

const shiftSlice = createSlice({
  name: "shiftManagement",
  initialState,
  reducers: {
    clearShift: (state) => {
      state.SingleShift = [];
    },
  },
  extraReducers: (builder) => {
    // 1) ====== builders for loadAllShift ======

    builder.addCase(loadAllNewShifts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllNewShifts.fulfilled, (state, action) => {
      state.loading = false;
      state.AllShiftsList = action.payload;
    });

    builder.addCase(loadAllNewShifts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 1) ====== builders for loadAllSwapRequest ======

    builder.addCase(loadAllSwapRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllSwapRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.SwapList = action.payload;
    });

    builder.addCase(loadAllSwapRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 2) ====== builders for CreateShiftManagement ======

    builder.addCase(CreateShiftManagement.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(CreateShiftManagement.fulfilled, (state, action) => {
      state.loading = false;
      if (!Array.isArray(state.list)) {
        state.list = [];
      }
    });

    builder.addCase(CreateShiftManagement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for loadSingleShift ======

    builder.addCase(loadSingleShifts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSingleShifts.fulfilled, (state, action) => {
      state.loading = false;
      state.SingleShift = action.payload.data;
    });

    builder.addCase(loadSingleShifts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for loadSingleSwap ======

    builder.addCase(loadSingleSwap.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSingleSwap.fulfilled, (state, action) => {
      state.loading = false;
      state.SingleSwap = action.payload.data;
    });

    builder.addCase(loadSingleSwap.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for updateShift ======

    builder.addCase(updateSingleShift.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateSingleShift.fulfilled, (state, action) => {
      state.loading = false;
      // const list = [...state.list];
      // const index = list.findIndex(
      //   (shift) => shift.id === parseInt(action.payload.data.id)
      // );
      // list[index] = action.payload.data;
      state.list = action.payload.data;
    });

    builder.addCase(updateSingleShift.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for updateSwapRequest ======

    builder.addCase(updateSwapRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateSwapRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
    });

    builder.addCase(updateSwapRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for deleteShift ======

    builder.addCase(deleteShifts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteShifts.fulfilled, (state, action) => {
      state.loading = false;
      // const filterShift = state.list.filter(
      //   (shift) => shift.id !== parseInt(action.payload) && shift
      // );
      // state.list = filterShift;
    });

    builder.addCase(deleteShifts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for deleteSwapRequest ======

    builder.addCase(DeleteSwapRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(DeleteSwapRequest.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(DeleteSwapRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default shiftSlice.reducer;
export const { clearShift } = shiftSlice.actions;
