import {
	Button,
	Card,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Table,
	TimePicker,
	Typography,
} from "antd";

import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import {
	addShift,
	loadAllShift,
} from "../../redux/rtk/features/shift/shiftSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

function CustomTable({ list, drawer }) {
	const [columnsToShow, setColumnsToShow] = useState([]);
	const [loader, setLoader] = useState(false);
	const { Title } = Typography;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [shift, setShift] = useState(false)

	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			id: 2,
			title: "Name",
			dataIndex: "name",
			key: "name",
		},

		{
			id: 3,
			title: "Start Time",
			dataIndex: "startTime",
			key: "startTime",
			render: (startTime) => dayjs(startTime).format("hh:mm A"),
		},

		{
			id: 4,
			title: "End Time",
			dataIndex: "endTime",
			key: "endTime",
			render: (endTime) => dayjs(endTime).format("hh:mm A"),
		},
		{
			id: 5,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => <ViewBtn path={`/admin/shift/${id}/`} />,
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));


	const onFinish = async (values) => {
		const shiftData = {
			name: values.name,
			startTime: dayjs(values.startTime).format(),
			endTime: dayjs(values.endTime).format(),
		};

		setLoader(true);
		const resp = await dispatch(addShift(values));

		if (resp.payload.message === "success") {
			setLoader(false);
			form.resetFields();
			dispatch(loadAllShift());
			setShift(false)

		} else {
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		toast.warning("Failed at adding shift");
		setLoader(false);
	};
	return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        Shift
      </h5>
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <div className="text-end">
          <Button
            className="px-5"
            type="primary"
            onClick={() => setShift(true)}
          >
            Add Shift
          </Button>
        </div>
        {list && (
          <div className="flex justify-between items-center mb-4">
            <div className="mt-3">
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            {/* <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                <CSVLink
                  data={list}
                  className="btn btn-dark btn-sm mb-1"
                  filename="shift"
                >
                  Download CSV
                </CSVLink>
              </CsvLinkBtn>
            </div> */}
          </div>
        )}

        {/* {list && (
					<div style={{ marginBottom: "30px" }}>
						<ColVisibilityDropdown
							options={columns}
							columns={columns}
							columnsToShowHandler={columnsToShowHandler}
						/>
					</div>
				)} */}

        <Table
          scroll={{ x: true }}
          loading={!list}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>

      <Modal
        centered
        open={shift}
        onOk={() => setShift(false)}
        onCancel={() => setShift(false)}
        footer={null}
        className="bank-holiday-modal"
      >
        <UserPrivateComponent permission={"create-shift"}>
          <Row
            gutter={16}
            className="mr-top"
            justify={drawer ? "center" : "space-between"}
          >
            <Col
              xs={{ span: 12, offset: 6 }}
              sm={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              md={{ span: 12, offset: 6 }}
              className="rounded card-custom"
            >
              <Title level={4} className="m-2 mt-5 mb-5 text-center">
                Add shifts
              </Title>
              <Form
                form={form}
                style={{ marginBottom: "40px" }}
                eventKey="shift-form"
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your shift!",
                      },
                    ]}
                  >
                    <Input
                      className="border-none ant-shadow"
                      placeholder="Shift Name"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label="Start Time"
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input your shift!",
                      },
                    ]}
                  >
                    <TimePicker className="border-none ant-shadow" />
                  </Form.Item>

                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    label="End Time"
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input your shift!",
                      },
                    ]}
                  >
                    <TimePicker className="border-none ant-shadow" />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: "10px" }}>
                    <Button
                      onClick={() => setLoader(true)}
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loader}
                    >
                      Add New Shift
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </UserPrivateComponent>
      </Modal>
    </>
  );
}

const AddShift = ({ drawer }) => {
	const shift = useSelector((state) => state.shift.list);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllShift());
	}, []);

	return (
		<Fragment bordered={false}>
			<UserPrivateComponent permission={"readAll-shift"}>
				{drawer || <CustomTable list={shift} />}
			</UserPrivateComponent>
		</Fragment>
	);
};

export default AddShift;
