import { Button, DatePicker, Form, Input, Space, Modal } from "antd";
import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Handler } from "leaflet";

const EmployeeEducationForm = ({ key, restField, remove, name }) => {
	const [openModal, setOpenModal] = useState(false);

	const HandleOK = ()=>{
		remove(name)
		setOpenModal(false)
	}

	return (
		<div>
			<Space
				key={key}
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: 8,
				}}
				align='baseline'>
				<Form.Item
					{...restField}
					name={[name, "degree"]}
					rules={[
						{
							required: true,
							message: "Missing  degree",
						},
					]}>
					<Input placeholder='Degree' />
				</Form.Item>
				<Form.Item
					{...restField}
					name={[name, "institution"]}
					rules={[
						{
							required: true,
							message: "Missing institution",
						},
					]}>
					<Input placeholder='Institution' />
				</Form.Item>
				<Form.Item
					{...restField}
					name={[name, "result"]}
					rules={[{ required: true, message: "Missing result" }]}>
					<Input placeholder='Result' />
				</Form.Item>

				<Form.Item
					{...restField}
					name={[name, "studyStartDate"]}
					rules={[{ required: true, message: "Missing studyStartDate" }]}>
					<DatePicker placeholder='studyStartDate' />
				</Form.Item>

				<Form.Item
					{...restField}
					name={[name, "studyEndDate"]}
					rules={[{ required: true, message: "Missing studyEndDate" }]}>
					<DatePicker placeholder='studyEndDate' />
				</Form.Item>

				<Form.Item
					{...restField}
					name={[name, "fieldOfStudy"]}
					rules={[{ required: true, message: "Missing fieldOfStudy" }]}>
					<Input placeholder='Field Of Study; Computer' />
				</Form.Item>

				<MinusCircleOutlined
					className='txt-color'
					style={{ fontSize: "150%" }}
					onClick={()=>setOpenModal(true)}
				// onClick={() => remove(name)}
				/>

			</Space>
			<Modal footer={null}  open={openModal} onCancel={()=>setOpenModal(false)}>
				<h5 className="text-base font-500">Are you sure to remove these details?</h5>
				<div className="flex items-center gap-2 justify-end mt-6">
				<Button size="large" block shape="round" onClick={()=>setOpenModal(false)}>No</Button>
				<Button size="large" block shape="round" onClick={HandleOK}>Yes</Button>
				</div>

			</Modal>
		</div>

	);
};

export default EmployeeEducationForm;
