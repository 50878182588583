import { Navigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Table, Tag } from "antd";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadAllSwapRequest, DeleteSwapRequest } from "../../redux/rtk/features/ShiftManagement/shiftManagementSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { MdDelete } from "react-icons/md";
import DelModal from "../UI/PopUp/DeleteWarningModal";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [ReqId, setReqId] = useState('');
  const [reqdel, setReqDel] = useState(false)
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Requested By",
      key: "scheduleFrom",
      render: ({ indx, scheduleFrom }) =>
        scheduleFrom.shifts.user == null ? "--" : scheduleFrom?.shifts?.user?.firstName + " " + scheduleFrom?.shifts?.user?.lastName

    },
    {
      title: "Requested On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt == null ? "--" : dayjs(createdAt).format("YYYY-MM-DD"),
    },
    // {
    //   title: "Date From",
    //   dataIndex: "startdate",
    //   key: "startdate",
    // },
    // {
    //   title: "Date To",
    //   dataIndex: "enddate",
    //   key: "enddate",
    //   //   render: (startTime) => dayjs(startTime).format("hh:mm A"),
    // },

    // {
    //   title: "Duration",
    //   dataIndex: "Duration",
    //   key: "Duration",
    // },
    {
      title: "Status",
      dataIndex: "requststatus",
      key: "requststatus",
      render: (status) =>
        status == null ? (
          "-"
        ) : status === "APPROVED" ? (
          <Tag color="green">{status?.toUpperCase()}</Tag>
        ) : status === "REJECTED" ? (
          <Tag color="red">{status?.toUpperCase()}</Tag>
        ) : status === "PENDING" ? (
          <Tag color="yellow">{status?.toUpperCase()}</Tag>
        ) : (
          "--"
        ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <>
          <div className="flex items-center">
            <ViewBtn path={`/single/shift/${id}/`} />
            <button
              onClick={() => DeleteSwapReq(id)}
              className="px-2 mr-2"
            >
              <MdDelete size={25} color="#FF0000" />
            </button>
          </div>
        </>
      ),
    },
  ];

  const DeleteSwapReq = async (req) => {
    setReqId(req)
    setReqDel(true)
  };

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <>
      <h5 className="department-list-title text-color-2 text-[24px] font-[600] mb-6">
        All Swap Request
      </h5>
      {list && (
        <div className="flex justify-between items-center mx-3 mb-3">
          <div className="flex items-center">
            <div>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            <div>
              {list && list.length > 0 ? (
                <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] hover:text-white">
                  <CSVLink
                    data={list}
                    className="btn btn-dark btn-sm mb-1 text-white text-[15px] font-[400]"
                    filename="Swape-shift"
                  >
                    CSV
                  </CSVLink>
                </CsvLinkBtn>
              ) : (
                <div className="pl-2"> No data to export</div>
              )}
            </div>
          </div>
        </div>
      )}
      <Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
        <Table
          scroll={{ x: true }}
          loading={loading}
          columns={columnsToShow}
          dataSource={list ? addKeys(list) : []}
        />
      </Card>
      <DelModal
        open={reqdel}
        onClose={setReqDel}
        reqId={ReqId}
        content={"Are you sure to delete this Swap Request?"}
        type={"SwapReq"}
      />
    </>
  );
}

const AllSwapRequest = ({ drawer }) => {
  const swape_list = useSelector((state) => state.ShiftManagement.SwapList);
  const loading = useSelector((state) => state.ShiftManagement.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllSwapRequest());
  }, []);

  const isLogged = Boolean(localStorage.getItem("isLogged"));
  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-shift"}>
        {drawer || <CustomTable list={swape_list} loading={loading} />}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AllSwapRequest;
