import React, { useState, useEffect, Fragment, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Segmented,
  Table,
} from "antd";
import { useDispatch } from "react-redux";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import GoogleMapsComponent from "./GoogleMapsComponent";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Loader from "../loader/loader";
import {
  manualLocation,
  loadAllLocation,
} from "../../redux/rtk/features/location/locationSlice";
import { toast } from "react-toastify";
import styles from "./styles.module.css";
import PageTitle from "../page-header/PageHeader";
import { useNavigate } from "react-router-dom";
function AddLocation() {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Title } = Typography;
const navigation = useNavigate();
  // const onFinish = async (values) => {

  //   console.log(values,'values')
  //   setLoader(true);
  //   const resp = await dispatch(manualLocation(values));

  //   if (resp.payload.message === "success") {
  //     setLoader(false);
  //     form.resetFields();
  //     dispatch(loadAllLocation());
  //   } else {
  //     setLoader(false);
  //   }
  // };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding location");
    setLoader(false);
  };

  const [locationName, setLocationName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  //const list = useSelector((state) => state.users.list);

  const handleLocationChange = (event) => {
    setLocationName(event.target.value);
  };
  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };
  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  };

  const handleSubmit = async () => {
    if (locationName == "") {
      return toast.error("please add location Name");
    }
    if (latitude == "") {
      return toast.error("please add latitude");
    }
    if (longitude == "") {
      return toast.error("please add longitude");
    }
    if (locationName && latitude && longitude) {
      setLoader(true);
      const resultAction = await dispatch(
        manualLocation({ locationName, latitude, longitude })
      );
      if (resultAction.payload.message === "success") {
        setLoader(false);
        form.resetFields();
        dispatch(loadAllLocation({ status: "true" }));
        navigation('/admin/location')
      } else {
        setLoader(false);
      }
      if (manualLocation.fulfilled.match(resultAction)) {
        dispatch(loadAllLocation({ status: "true" }));
        // Action was fulfilled
      } else if (manualLocation.rejected.match(resultAction)) {
        // Action was rejected
      }
      // onLocationChange(locationName, latitude, longitude);
    }
  };

  const [userLocation, setUserLocation] = useState("");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDFJ551qMSKGnnUV1RZmpv6Mu_XciFAZek",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setUserLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const center = useMemo(
    () => ({ lat: userLocation?.latitude, lng: userLocation?.longitude }),
    [userLocation]
  );

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  return (
    <>
      <PageTitle title="Back" />
      <UserPrivateComponent permission={"create-location"}>
        <Row className="mt-3 px-2" justify={"center"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className={`${styles.BorderStyle} -ml-2 mb-4`}
          >
            <Card
              className="ant-shadow rounded-[20px]"
              bordered={false}
              height={300}
            >
              <Title level={4} className="m-2 mt-5 mb-5 text-center">
                Add Location Details
              </Title>
              <Form
                // className="p-4"
                form={form}
                // onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={16}>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    lg={{ span: 7, offset: 1 }}
                    xl={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                  >
                    <Form.Item name="locationName">
                      <Input
                        className={`${styles.InputStyle} form-control`}
                        label="Location Name"
                        type="text"
                        value={locationName}
                        onChange={handleLocationChange}
                        placeholder="Enter location"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    lg={{ span: 7, offset: 1 }}
                    xl={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                  >
                    <Input
                      className={`${styles.InputStyle} form-control mb-5`}
                      label="Latitude"
                      name="latitude"
                      type="text"
                      value={latitude}
                      onChange={handleLatitudeChange}
                      placeholder="Enter latitude"
                    />
                  </Col>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    lg={{ span: 7, offset: 1 }}
                    xl={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                  >
                    <Input
                      className={`${styles.InputStyle} form-control mb-5`}
                      label="Longitude"
                      type="text"
                      name="longitude"
                      value={longitude}
                      onChange={handleLongitudeChange}
                      placeholder="Enter longitude"
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col className={styles.Button}>
                    <Button type="button" className="bg-[#E42976] text-white border-0" onClick={handleSubmit}>
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="column-design border-0 rounded-[20px] card-custom"
          >
            <h3
              className={`${styles.TitleStyle} !text-white mb-0 text-center p-3`}
            >
              Set Your Location
            </h3>
            {!isLoaded ? (
              <h1>Loading....</h1>
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                mapContainerClassName="map-container"
                zoom={20}
                center={center}
                onClick={(e) => {
                  setLatitude(e.latLng.lat());
                  setLongitude(e.latLng.lng());
                }}
              >
                <Marker
                  position={{
                    lat: userLocation?.latitude,
                    lng: userLocation?.longitude,
                  }}
                />
              </GoogleMap>
            )}
          </Col>
        </Row>
      </UserPrivateComponent>
    </>
  );
}

export default AddLocation;
