import { Link, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Form, Input, Button, Row, Col, Card } from 'antd';
// material-ui
import styles from "./Login.module.css";
import restimg from "../../assets/images/resetp.png";
import logo from "../../assets/images/logo.png";
import { FaUserAlt } from "react-icons/fa";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useTheme } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BtnLoader from '../loader/BtnLoader';
import { forgotPassword } from "../../redux/rtk/features/user/userSlice"; // Import your thunk
// project imports
//import AuthForgot from "./authForgot";

// assets

// ================================|| AUTH3 - FORGOT PASSWORD ||================================ //

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  //const { email } = useParams();
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    if (email) {
      const resp = await dispatch(forgotPassword({ email }));
      if (resp.payload.message === "success") {
        navigate(`/admin/auth/otp/${email}`);
      } else {
        // setLoader(false);
      }
    }
  };

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const para = "You forgot your password?";

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  //const { Title } = Typography;

  /* const onFinish = async (values) => {
    const resp = await dispatch(forgotPassword(values));
    if (resp.payload.message === "success") {
      setLoader(false);
      window.location.href = "user/forgot";
    } else {
      setLoader(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoader(false);
    toast.error("Email Not Existed Please try again");
  }; */

  /*   const { loader_status } = useSelector((state) => state.loader);
   */ const [checked, setChecked] = useState(false);

  return (
    <>
      {checked ? (
        ""
      ) : (
        <Row gutter={16} className="card-row">
          <Col xs={0} sm={12} className={styles.AlignmentImage}>
            <div className={styles.imageContainer}>
              <img src={restimg} alt="Company Logo" width={550} />
            </div>
          </Col>

          <Col xs={24} sm={12} className={styles.AlignmentCard}>
            <Card bordered={false} className={`${styles.card} mt-1 mb-3`}>
              <div className={styles.logoImg}>
                <img src={logo} alt="Company Logo" width={100} />
              </div>
              <h3 className="m-3 mb-4 text-center">Forgot Password?</h3>
              <p className="mt-3">
                We'll send you OTP at your registered email account.
              </p>

              <Form style={{ marginTop: "20px" }} className="p-4">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                  ]}
                >
                  <div className={styles.iconInput}>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="email"
                    />
                    <FaUserAlt className={styles.icon} color="#E42976" />
                  </div>
                </Form.Item>
                <Form.Item className={styles.submitBtnContainer}>
                  {!loader ? (
                    <Button
                    className='bg-[#E42976] text-white border-0'
                      type="submit"
                      htmlType="submit"
                      onClick={handleForgotPassword}
                    >
                      Send OTP
                    </Button>
                  ) : (
                    <BtnLoader />
                  )}
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "2rem",
                  }}
                >
                  <Link to={"/admin/auth/login"}>
                    <strong style={{ display: "flex", alignItems: "center" }}>
                      <BsFillArrowLeftCircleFill
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Back
                    </strong>
                  </Link>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ForgotPassword;
