import { Line } from "@ant-design/plots";
import { Card, Col, DatePicker, Row, Statistic } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewDashboardCard from "../../Card/Dashboard/NewDashboardCard";
import Loader from "../../loader/loader";
import { loadDashboardData } from "../../../redux/rtk/features/dashboard/dashboardSlice";
import { loadAllAttendance } from "../../../redux/rtk/features/attendance/attendanceSlice";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
// import AttendancePopup from "../..//UI/PopUp/AttendancePopup";
import { ContactsOutlined, FundOutlined } from "@ant-design/icons";
import LeaveLineChart from "../../leave/LineChart";
import { fetchTodayLeaveData, fetchYearsLeaveData } from "../../../redux/rtk/features/leave/leaveSlice";
import styles from '../../Card/Dashboard/style.module.css';
import LeaveStatsBarChart from "../../leave/Charts";
import EmployeeLineChart from "./EmployeeLineChart";
import { AiOutlineUser } from "react-icons/ai";
import BarChartLeave from "../BarChartLeave";
import DepartmentsTable from "../DepartmentsTable";
import LocationTable from "./LocationTable";
import PendingAccountsPieChart from "./PendingAccountsPieChart";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
dayjs.extend(utc);
//Date fucntinalities
let startdate = dayjs(new Date()).startOf("month").format("YYYY-MM-DD");
let enddate = dayjs(new Date()).endOf("month").format("YYYY-MM-DD");

const DemoLine = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.dashboard.list?.workHoursByDate);
	const { Attendancefilter } = useSelector((state) => state.attendance);
	const [EmployeeData, setEmployeeData] = useState([]);
	const [UserData, setUserData] = useState([]);

	const cardInformation = useSelector((state) => state.dashboard.list);
	const leaveDaily = useSelector((state) => state.leave.dailyData)
	const leaveYearly = useSelector((state) => state.leave.yearlyData)


	useEffect(() => {
		setEmployeeData([
			{
				name: '',
				'ThisMonth': Attendancefilter.currentMonthUserCount,
				'LastMonth': Attendancefilter.previousMonthUserCount,
			},

		]);
	}, [Attendancefilter]);

	useEffect(() => {
		setUserData([
			{
				name: 'Approved',
				'value': Attendancefilter.approvedUserCount,
			},
			{
				name: 'Pending',
				'value': Attendancefilter.pendingUserCount,
			},
			{
				name: 'Rejected',
				'value': Attendancefilter.rejectedUserCount
			},

		]);
	}, [Attendancefilter]);

	useEffect(() => {
		dispatch(loadAllStaff());


	}, [dispatch])


	useEffect(() => {
		dispatch(fetchTodayLeaveData());
		dispatch(fetchYearsLeaveData())


	}, [dispatch])

	const transformedDataLine = Object.keys(leaveDaily?.weekCounts || {}).map((day) => {
		return {
			name: day,
			Accepted: leaveDaily.weekCounts[day].approved || 0,
			Rejected: leaveDaily.weekCounts[day].rejected || 0,
			Pending: leaveDaily.weekCounts[day].pending || 0,
		};
	});
	const TotalLeaveData = Object.keys(leaveDaily || {}).map((day) => {
		return {
			total: leaveDaily.totalLeaves || 0,
			approved: leaveDaily.totalApproved || 0,
			rejected: leaveDaily.totalPending || 0,
			pending: leaveDaily.totalRejected || 0,
		};
	});
	const transformedBarData = Object.keys(leaveYearly?.yearCounts || {}).map((y) => {
		return {
			name: leaveYearly.yearCounts[y].month || 0,
			approved: leaveYearly.yearCounts[y].approved || 0,
			rejected: leaveYearly.yearCounts[y].rejected || 0,
		};
	});


	useEffect(() => {
		dispatch(loadDashboardData({ startdate, enddate }));
		dispatch(loadAllAttendance(`query=all&count=1000`))
	}, []);


	useEffect(() => {
		dispatch(fetchTodayLeaveData());
	}, [dispatch]);

	const { RangePicker } = DatePicker;

	const onCalendarChange = (dates) => {
		startdate = (dates?.[0]).format("YYYY-MM-DD");
		enddate = (dates?.[1]).format("YYYY-MM-DD");

		dispatch(loadDashboardData({ startdate, enddate }));
	};

	const config = {
		data,
		xField: "date",
		yField: "time",
		seriesField: "type",
		yAxis: {
			label: {
				formatter: (v) => `${v / 1000} Hours`,
			},
		},
		legend: {
			position: "top",
		},
		smooth: true,
		animation: {
			appear: {
				animation: "path-in",
				duration: 5000,
			},
		},
	};
	

return (
  <Fragment>
    <UserPrivateComponent permission={"readAll-dashboard"}>
      <Row gutter={[16, 16]} className="mb-3">
        {/* <Col sm={24} md={24} lg={12} span={24} className='mb-auto'>
						<RangePicker
							onCalendarChange={onCalendarChange}
							defaultValue={[dayjs().startOf("month"), dayjs().endOf("month")]}
							className='range-picker'
							style={{ maxWidth: "25rem" }}
						/>
					</Col> */}
        {/* <Col sm={12} md={12} lg={12} span={24}>
						<div className='text-end mr-4'>
							<AttendancePopup />
						</div>
					</Col> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <NewDashboardCard
            information={cardInformation}
            data={Attendancefilter}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="site-statistic-demo-card">
            <Card className={`${styles.cardBody} `}>
              <div className={`${styles.TitleStyles} mx-3`}>
                <h2>Leave Insights (Daily)</h2>
                <div>
                  <LeaveLineChart dataLine={transformedDataLine} />
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-3">
          <Card className={`${styles.cardBody}`}>
            <div className={`${styles.TitleStyles} `}>
              <h2>Leave Status</h2>
            </div>
            <BarChartLeave transformedBarData={transformedBarData} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-2">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card className={`${styles.cardBody}`}>
                <div className={`${styles.TitleStyles}`}>
                  <h2>New Employees</h2>
                </div>
                <div className="my-5">
                  <EmployeeLineChart EmployeeData={EmployeeData} />
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card className={`${styles.cardBodyC}`}>
                <div className={`${styles.TitleStyles} `}>
                  <h2>Today's Leave Requests</h2>
                </div>
                <div className={`${styles.DivLeave} `}>
                  <div className="flex justify-between mt-3">
                    <AiOutlineUser className={`${styles.iconL} mr-2`} />
                    <h2>
                      {leaveDaily
                        ? Math.round(leaveDaily.percentageChange)
                        : "0"}
                      %
                    </h2>
                  </div>
                </div>
                <div className={`${styles.Div} flex justify-between`}>
                  <h2 style={{ fontSize: "38px" }} className="m-3 ml-1">
                    {leaveDaily ? leaveDaily.totalLeaves : "0"}
                  </h2>
                  <div className="m-3">
                    <div className="flex justify-between">
                      <h4>Approved</h4>
                      <span className="ml-2">
                        {leaveDaily ? leaveDaily.totalApproved : "0"}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <h4> Pending</h4>
                      <span className="ml-2">{leaveDaily ? leaveDaily.totalPending : "0"}</span>
                    </div>

                    <div className="flex justify-between">
                      <h4> Rejected</h4>
                      <span className="ml-2">{leaveDaily ? leaveDaily.totalRejected : "0"}</span>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="mb-3">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card className={`${styles.cardBodyC}`}>
                <div className={`${styles.TitleStyles} `}>
                  <h2>Departments</h2>
                </div>
                <DepartmentsTable />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card className={`${styles.cardBodyC}`}>
                <div className={`${styles.TitleStyles} `}>
                  <h2>Locations</h2>
                </div>
                <LocationTable />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="mb-3">
          <Card className={`${styles.cardBody}`}>
            <div className={`${styles.TitleStyles} `}>
              <h2>Pending Accounts</h2>
            </div>
            <Row gutter={[16, 16]} className="mt-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <PendingAccountsPieChart UserData={UserData} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={`${styles.Div} flex justify-between ml-5 mt-5`}>
                  <div className="m-2">
                    <div className="flex justify-between">
                      <h4> Approved </h4>
                      <span className="ml-2">
                        {Attendancefilter
                          ? Attendancefilter.approvedUserCount
                          : "-"}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <h4>Pending</h4>
                      <span className="ml-2">
                        {Attendancefilter
                          ? Attendancefilter.pendingUserCount
                          : "-"}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <h4> Rejected</h4>
                      <span className="ml-2">
                        {Attendancefilter
                          ? Attendancefilter.rejectedUserCount
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* 	<Row gutter={[20, 20]} className="mx-[17px]">
					<Col sm={24} md={6} lg={6} span={24}>
						<Row gutter={[20, 20]}>
							<Col xs={24} sm={24} span={24}>
								<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>
									<Statistic
										title={<p className='text-xl font-[700] text-black'>Leave Requests</p>}
										loading={!cardInformation}
										value={cardInformation?.totalUsers}
										valueStyle={{
											color: "#000",
											fontSize: "2.5rem",
											fontWeight: "700"
										}}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={24} span={24}>
								<Card className='ant-shadow txt-color-2 rounded-[15px]' bordered={false}>
									<Statistic
										title={<p className='text-xl font-[700] text-black'>Invoices Overdue</p>}
										loading={!cardInformation}
										value={cardInformation?.totalUsers}
										valueStyle={{
											color: "#000",
											fontSize: "2.5rem",
											fontWeight: "700"
										}}
									/>
								</Card>
							</Col>

						</Row>
					</Col>
					<Col sm={24} md={18} lg={18} span={24}>
						<div>
							<Card title='WORK HOURS' className="ant-shadow rounded-[15px] custom-card-head" bordered={false}>
								{data ? <Line {...config} /> : <Loader />}
							</Card>
						</div>
					</Col>
				</Row> */}
    </UserPrivateComponent>
  </Fragment>
);
};

export default DemoLine;
