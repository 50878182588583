import "bootstrap-icons/font/bootstrap-icons.css";
import moment from "moment";
import React, { useState, useEffect, Fragment, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Segmented,
  Table,
} from "antd";
import { useDispatch } from "react-redux";
import {
  manualLocation,
  loadAllLocation,
} from "../../redux/rtk/features/location/locationSlice"; // Import your addUser action
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LocationDelete from "./locationDelete";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import ViewBtn from "../Buttons/ViewBtn";
import styles from "./styles.module.css";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import GetAllLocation from "./GetAllLocation";
import Loader from "../loader/loader";

import { Link } from "react-router-dom";
//Location Table

function LocationTable({ list,loading }) {
  const location = useSelector((state) => state.location.list);
  const dispatch = useDispatch();
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [row, setrow] = useState([]);

  useEffect(() => {
    if (location) {
      makeRow();
    } else {
      dispatch(loadAllLocation({ status: "true" }));
    }
  }, [location]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },

    // TODO: fix this column to show the correct data

    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Created At",
      dataIndex: "createdat",
      key: "createdat",
    },

    {
      title: "Updated At",
      dataIndex: "updatedat",
      key: "updatedat",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/location/${id}/`} />,
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const makeRow = () => {
    const locationdata = location;
    const data =
      Array.isArray(locationdata) && locationdata.length > 0
        ? locationdata.map((data, index) => ({
            // key: data.id,
            id: data.id,
            location: data.locationName == null ? "-" : data.locationName,
            longitude: data.longitude == null ? "-" : data.longitude,
            latitude: data.latitude == null ? "-" : data.latitude,
            createdat:
              data.createdAt == null
                ? "-"
                : moment(data.createdAt).format("DD-MM-YYYY"),
            updatedat:
              data.updatedAt == null
                ? "-"
                : moment(data.updatedAt).format("DD-MM-YYYY"),
            action: data,
          }))
        : locationdata;
    setrow(data);
  };

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  return (
    <>
      <div className="flex my-2 justify-between items-center mr-3 flex-wrap">
        {/* <div className='w-50'>
          <h4 className='text-2xl mb-2'>Location List</h4>
        </div> */}
        {list && (
          <div className="flex justify-end mr-4 items-center">
            <div>
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
            <div>
              <CsvLinkBtn className="bg-[#19C881] text-white hover:bg-[#19C881] hover:!text-white">
                <CSVLink
                  data={row}
                  className="btn btn-dark btn-sm hover:!text-white text-[15px] font-[400]"
                  style={{ marginTop: "5px" }}
                  filename="Locations-List"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          </div>
        )}
        <div className="flex items-center">
          <Link to={"/admin/add-location"}>
            <Button className="text-[15px] font-[400] bg-[#E42976] text-white border-0" type="button">Add Location</Button>
          </Link>
        </div>
      </div>
      <Card className="mt-0 rounded-[20px] ant-shadow mr-3" bordered={false}>
        {location ? ( // Check if location data is available
          <Table
            loading={loading}
            scroll={{ x: true }}
            columns={columnsToShow}
            dataSource={row}
          />
        ) : (
          <div>
            <Loader />
          </div> // Show loading indicator if location data is not available
        )}
      </Card>
    </>
  );
}
//loading={!location} // This loading prop might not be necessary now

/*       <Table
        scroll={{ x: true }}
        loading={!location}
        columns={columnsToShow}
        dataSource={row}
      />
  );
} */

const AddManualLocation = ({ props, drawer, onLocationChange }) => {
  // const location = useSelector((state) => state.users.list);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location.list);
  const loading = useSelector((state) => state.location.loading);

  useEffect(() => {
    dispatch(loadAllLocation({ status: "true" }));
  }, []);

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"readAll-location"}>
        {drawer || <LocationTable list={location} loading={loading}/>}
      </UserPrivateComponent>
    </Fragment>
  );
};

export default AddManualLocation;
