import React, { useEffect, useState } from "react";
import ViewBtn from "../Buttons/ViewBtn";
import dayjs from "dayjs";
import { Card, DatePicker, Segmented, Table, Tag, Button, Form, Input, Row, Col, Pagination } from "antd";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import PageTitle from "../page-header/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAttendanceByUserId,
  clearAttendanceList,
} from "../../redux/rtk/features/attendance/attendanceSlice";
import { useParams } from "react-router-dom";
import Loader from "../loader/loader";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlineSearchOff } from 'react-icons/md';
import moment from "moment";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;
  const { id } = useParams("id");

  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [employeeId, setEmployeeId] = useState();

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: ({ firstName, lastName }) => firstName + " " + lastName,
    },

    {
      id: 3,
      title: "inTime",
      dataIndex: "inTime",
      key: "inTime",
      render: (inTime) =>
        inTime == null ? (
          <Tag color="red">{"NONE"}</Tag>
        ) : (
          inTime
        ),
    },

    {
      id: 4,
      title: "Out Time",
      dataIndex: "outTime",
      key: "outTime",
      render: (outTime) =>
        outTime === null ? (
          <Tag color="red">{"NONE"}</Tag>
        ) : (
          outTime
        ),
    },
    {
      id: 4,
      title: "In Status",
      dataIndex: "inTimeStatus",
      key: "inTimeStatus",
      render: (inTimeStatus) => {
        // use Tag component from antd to show status in different colors like green, red, yellow etc based on the status value
        if (inTimeStatus === "Late") {
          return <Tag color="red">{inTimeStatus.toUpperCase()}</Tag>;
        } else if (inTimeStatus === "Early") {
          return <Tag color="blue">{inTimeStatus.toUpperCase()}</Tag>;
        } else if (inTimeStatus === "On Time") {
          return <Tag color="green">{inTimeStatus.toUpperCase()}</Tag>;
        }
         else if (inTimeStatus === "OnTime") {
          return <Tag color="green">{inTimeStatus.toUpperCase()}</Tag>;
        }
        else {
          return <Tag style={{ color: "orange" }}>NONE</Tag>;
        }
      },
    },
    {
      id: 5,
      title: "Out Status",
      dataIndex: "outTimeStatus",
      key: "outTimeStatus",
      render: (outTimeStatus) => {
        console.log(outTimeStatus, 'outTimeStatus')
        // use Tag component from antd to show status in different colors like green, red, yellow etc based on the status value
        if (outTimeStatus === "Late") {
          return <Tag color="red">{outTimeStatus.toUpperCase()}</Tag>;
        } else if (outTimeStatus === "Early") {
          return <Tag color="blue">{outTimeStatus.toUpperCase()}</Tag>;
        }
         else if (outTimeStatus === "OnTime") {
          return <Tag color="green">{outTimeStatus.toUpperCase()}</Tag>;
        }
        else {
          return <Tag style={{ color: "orange" }}>NONE</Tag>;
        }
      },
    },
    {
      id: 7,
      title: "Punch By",
      dataIndex: "punchBy",
      key: "punchBy",
      render: (punchBy) => (
        <span>
          {punchBy[0]?.firstName + " " + punchBy[0]?.lastName || "Not Checked"}
        </span>
      ),
    },
    {
      id: 6,
      title: "Total Hour",
      dataIndex: "totalHour",
      key: "totalHour",
      render: (totalHour) => totalHour || "Not Checked",
    },

    // {
    // 	id: 8,
    // 	title: "Action",
    // 	dataIndex: "id",
    // 	key: "id",
    // 	render: (id) => (
    // 		<AttendBtn
    // 			path={`/admin/attendance/${id}`}
    // 			text='View'
    // 			icon={<BtnViewSvg />}
    // 		/>
    // 	),
    // },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  //const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));
  const addKeys = (arr) =>
    Array.isArray(arr) ? arr.map((i) => ({ ...i, key: i.id })) : [];
  // Calculate the current data to display based on pagination
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = list ? addKeys(list).slice(startIndex, endIndex) : [];

  // Handle page change
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = () => {
    let data = "";
    if (fromDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "createdAtFrom=" + moment(fromDate).format('YYYY-MM-DD');
    }

    if (toDate != "") {
      if (data.length > 1) {
        data = data + "&";
      }
      data = data + "createdAtTo=" + moment(toDate).format('YYYY-MM-DD');
    }
    dispatch(loadAttendanceByUserId({ id: id, data: data }));
  };
  const ClearFilter = () => {
    dispatch(loadAttendanceByUserId({ id: id }));
  };

  const CSVlist =
    Array.isArray(list) && list.length > 0
      ? list?.map((i) => ({
        id: i?.id,
        Name:
          i?.user.firstName == null
            ? "-"
            : i?.user?.firstName + " " + i?.user?.lastName,
        InTime: i?.intime == null ? "-" : dayjs(i?.intime).format("h:mm A"),
        OutTime:
          i?.outtime == null ? "-" : dayjs(i?.outtime).format("h:mm A"),
        InStatus: i?.inTimeStatus == null ? "-" : i?.inTimeStatus,
        OutStatus: i?.outTimeStatus == null ? "-" : i?.outTimeStatus,
        PunchBy:
          i?.punchBy[0]?.firstName == null
            ? "-"
            : i?.punchBy[0]?.firstName + " " + i?.punchBy[0]?.lastName,
        TotalHour: i?.totalHour == null ? "-" : i?.totalHour,
      }))
      : "";

  const handleDateChange = (date, dateString) => {
    setFromDate(date);
  };
  const handletoDateChange = (date, dateString) => {
    setToDate(date);
  }
  return (
    <>
      <h3 className="department-list-title text-color-2 text-[24px] font-[600] mb-4">
          Attendance History
      </h3>
      <div className="flex">
        <div className="mb-1 ant-shadow rounded-[20px] w-[100%]">
          <Form
            layout="vertical"
            onFinish={handleSearch}
            autoComplete="off"
            style={{ marginTop: "15px" }}
            className="xl:p-0 lg:p-0 md:p-4 sm:p-4 xsm:p-4 xxs:p-4 xxxs:p-4"
          >
            <Row gutter={[16, 16]} justify={'center'}>
              <Col
                // xs={24} sm={12} md={8} lg={6}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                lg={{ span: 7, offset: 0 }}
                xl={{ span: 7, offset: 0 }}
                md={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  label="From:"
                  name="fromDate"
                  rules={[
                    {
                      required: true,
                      message: "Start date required!",
                    },
                  ]}
                >
                  <DatePicker
                    value={fromDate ? moment(fromDate) : null}
                    onChange={handleDateChange}
                    format="DD-MM-YYYY"
                    style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={24} sm={12} md={8} lg={6}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                lg={{ span: 7, offset: 1 }}
                xl={{ span: 7, offset: 1 }}
                md={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  label="To:"
                  name="toDate"
                  rules={[
                    {
                      required: true,
                      message: "End date required!",
                    },
                  ]}
                >
                  <DatePicker
                    value={toDate ? moment(toDate) : null}
                    onChange={handletoDateChange}
                    format="DD-MM-YYYY"
                    style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12} md={6} lg={6}>
								<Form.Item
									label="Employee ID:"
									name="employeeId"
									rules={[
										{
											required: true,
											message: "Employee ID required.",
										},
									]}
								>
									<Input
										style={{ boxShadow: "0px 0px 5px gray", borderRadius: "20px" }}
										type="text"
										value={employeeId}
										onChange={(e) => setEmployeeId(e.target.value)}
										placeholder="06"
									/>
								</Form.Item>
							</Col> */}
              <Col
                // xs={12} sm={12} md={6} lg={3}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 6, offset: 3 }}
                lg={{ span: 3, offset: 1 }}
                xl={{ span: 3, offset: 1 }}
                md={{ span: 6, offset: 3 }}
              >
                <Form.Item>
                  <Button
                    className="mt-[1.85rem] w-full justify-center text-[15px] !px-[12px] !py-0 !h-[34px] flex items-center  bg-[#E42976] text-white border-0"
                    type="submit"
                    shape="round"
                    htmlType="submit"
                    size="large"
                  >
                    <SearchOutlined style={{ fontSize: "20px" }} />
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col
                // xs={12} sm={12} md={6} lg={3}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 6, offset: 3 }}
                lg={{ span: 3, offset: 0 }}
                xl={{ span: 3, offset: 0 }}
                md={{ span: 6, offset: 3 }}
              >
                <Form.Item>
                  <Button
                    className="bg-[#FF0000] w-full justify-center text-[15px] mt-[1.85rem] !px-[12px] !py-0 !h-[34px] text-white hover:text-white border-0 hover:border-0 flex items-center"
                    shape="round"
                    size="large"
                    onClick={ClearFilter}
                  >
                    <MdOutlineSearchOff style={{ fontSize: "20px" }} />
                    Clear
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <Card className="mt-3 ant-shadow rounded-[15px]" bordered={false}>
        <div className="text-center my-2 flex justify-between">
          {list && list.length > 0 ? (
            <div>
              <CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
                <CSVLink
                  data={CSVlist}
                  className="btn btn-dark btn-sm mb-1 text-white text-[15px] font-[400]"
                  filename="attendance_user"
                >
                  CSV
                </CSVLink>
              </CsvLinkBtn>
            </div>
          ) : (
            <div>No data to export</div>
          )}

          {list && (
            <div className="flex items-center">
              <ColVisibilityDropdown
                options={columns}
                columns={columns}
                columnsToShowHandler={columnsToShowHandler}
              />
            </div>
          )}
        </div>

        {/* 	<Table
				scroll={{ x: true }}
				loading={loading}
				columns={columnsToShow}
				dataSource={list ? addKeys(list) : []}
			/> */}
        <Table
          scroll={{ x: true }}
          loading={loading}
          columns={columnsToShow}
          dataSource={currentData}
          pagination={false} // Disable the default pagination
        />

        {/* Add Pagination component */}
        <Pagination
          className="mt-3 flex justify-end"
          current={currentPage}
          pageSize={pageSize}
          total={list ? addKeys(list).length : 0}
          onChange={handleChangePage}
          showSizeChanger={false} // Hide the "Show X items per page" dropdown
        />
      </Card>
    </>
  );
}

const UserAttendance = () => {
  const { list = [], loading } = useSelector((state) => state.attendance);
  const { id } = useParams("id");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!list?.attendanceData) {
      dispatch(loadAttendanceByUserId({ id: id }));
    }

    return () => {
      dispatch(clearAttendanceList());
    };
  }, [id]);

  return (
    <UserPrivateComponent permission="readSingle-attendance">
      <div>
        {/* <PageTitle title='Back' /> */}
        {!loading ? (
          <CustomTable list={list?.attendanceData} loading={loading} />
        ) : (
          <Loader />
        )}
      </div>
    </UserPrivateComponent>
  );
};


export default UserAttendance;
