import { Button, Card, Popover, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import BtnDeleteSvg from "../UI/Button/btnDeleteSvg";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import { CSVLink } from "react-csv";
import ViewBtn from "../Buttons/ViewBtn";
import dayjs from "dayjs";
import DepartmentEditPopup from "../UI/PopUp/DepartmentEditPopup";
import {
	deleteShift,
	loadSingleShift,
} from "../../redux/rtk/features/shift/shiftSlice";
import ShifDelete from "./shiftDelete";
import ShiftEditPopup from "../UI/PopUp/ShiftEditPopup";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

//PopUp

const CustomTable = ({ list }) => {
	const [columnsToShow, setColumnsToShow] = useState([]);
	const { id } = useParams();
	let navigate = useNavigate();

	//dispatch
	const dispatch = useDispatch();
	const shift = useSelector((state) => state.shift.shift);
	//Delete Supplier
	console.log("getting shift:", shift);
	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
		},

		{
			id: 2,
			title: " Name",
			key: "firstName",
			render: ({ firstName, lastName }) => firstName + " " + lastName,
		},

		{
			id: 6,
			title: "User Name",
			dataIndex: "userName",
			key: "userName",
		},
		{
			id: 7,
			title: "Start Time",
			dataIndex: "startTime",
			key: "startTime",
			render: (startTime) => dayjs(startTime).format("hh:mm A"),
		},
		{
			id: 8,
			title: "End Time",
			dataIndex: "endTime",
			key: "endTime",
			render: (endTime) => dayjs(endTime).format("hh:mm A"),
		},
		{
			id: 4,
			title: "Action",
			dataIndex: "id",
			key: "action",
			render: (id) => (
				<UserPrivateComponent permission={"readSingle-user"}>
					<ViewBtn path={`/admin/hr/staffs/${id}/`} />
				</UserPrivateComponent>
			),
		},
	];

	useEffect(() => {
		setColumnsToShow(columns);
	}, []);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	return (
		<>
			<h5 className='department-list-title text-color-2 text-[24px] font-[600] mb-6'>
				Employee List
			</h5>
			<Card bordered={false} className="ant-shadow rounded-[20px] p-0 mx-2">
				<div className='flex justify-between '>
					<h3 className={"text-xl"}>
										{/* ID : {shift.id} |  */}
									Shift Name:	{shift.name}
									</h3>
					<div className='flex justify-end'>
						<UserPrivateComponent permission={"update-shift"}>
							<ShiftEditPopup data={shift} />
						</UserPrivateComponent>

						<UserPrivateComponent permission={"delete-shift"}>
							<ShifDelete id={id} />
						</UserPrivateComponent>
					</div>
				</div>
				{list && (
					<div className="flex justify-between">
						<div className="mt-3">
							<ColVisibilityDropdown
								options={columns}
								columns={columns}
								columnsToShowHandler={columnsToShowHandler}
							/>
						</div>
						<div>
							<CsvLinkBtn className="bg-[#19C881] hover:bg-[#19C881] text-white">
								<CSVLink data={list} filename='user_department'>
									Download CSV
								</CSVLink>
							</CsvLinkBtn>
						</div>
					</div>

				)}

				<Table
					loading={!list}
					columns={columnsToShow}
					dataSource={addKeys(list)}
					pagination={{ pageSize: 5 }}
					scroll={{ x: 720 }}
				/>
			</Card>
		</>

	);
};

const DetailShift = () => {
	const { id } = useParams();
	let navigate = useNavigate();

	//dispatch
	const dispatch = useDispatch();
	const shift = useSelector((state) => state.shift.shift);
	//Delete Supplier
	console.log("getting shift:", shift);
	// Delete Supplier PopUp
	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (newVisible) => {
		setVisible(newVisible);
	};

	useEffect(() => {
		dispatch(loadSingleShift(id));
	}, []);

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	if (!isLogged) {
		return <Navigate to={"/admin/auth/login"} replace={true} />;
	}

	return (
		<div>
			{/* <PageTitle title=' Back  ' /> */}

			<UserPrivateComponent permission={"readSingle-shift"}>
				{shift ? (
					<Fragment key={shift.id}>
						<div>

							<CustomTable list={shift.user} />
						</div>
					</Fragment>
				) : (
					<Loader />
				)}

			</UserPrivateComponent>
		</div>
	);
};

export default DetailShift;
